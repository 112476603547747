import React from 'react'
import styled from 'styled-components';
import { getDateByTime } from '../../../../utils/Times';
import ChannelIcon from './ChannelIcon';

const Separator = styled.div`
  margin: 0 12px;
  color: var(--gray-color);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  height: 35px;
  padding-top: 1rem;
  background-color: var(--theme-bg-color);
  z-index: 15;

  div {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    background-color: var(--theme-bg-color);
    width: 120px;
    margin: auto;
    font-size: 12px;
  }

  hr {
    margin-top: 0;
  }

  svg, img {
    width: 16px;
    margin-right: 0.5rem;
  }
  
`;

const ChatSeparator = ({ date, channel }) => {
  return (
    <Separator>
      <hr />
      <div className='d-flex justify-content-center align-items-center'>
        <ChannelIcon channel={channel} width="16" />
        <span>{getDateByTime(date)}</span>
      </div>
    </Separator>
  )
}

export default ChatSeparator;