import React from "react";
import { HighlightedRow, Row } from "../../../components/tables/styles";
import AssessorStatusIndicator from "./AssessorStatusIndicator";
import { getMinutesBetweenDates } from '../../../utils/Times';
const getResolutionTime = (date) => {
  const [days, hours, minutes] = getMinutesBetweenDates(new Date(), new Date(date));
  let daysStr = (days > 0) ? (`${days} ` + ((days===1) ? 'dia': 'dias')) : "";
  let hrsStr = (hours > 0) ? (`${hours} ` + ((hours===1) ? 'hora': 'horas')) : "";
  let minsStr = (minutes > 0) ? (`${minutes} ` + ((minutes===1) ? 'min': 'mins')) : "";
  return `${daysStr} ${hrsStr} ${minsStr}`;
}
const AssessorRow = ({ assessor }) => {


  
  if(assessor.rowColor){
     return (
       <HighlightedRow rowColor={assessor.rowColor} key={assessor.email}>
         <td>
           <strong>{`${assessor?.name} ${assessor?.lastname}`}</strong>
         </td>
         <td>{assessor.email}</td>
         <td>{assessor?.openConv ?? 0}</td>
         <td>
           {assessor.status ? (
             <AssessorStatusIndicator status={assessor.status} />
           ) : (
             <AssessorStatusIndicator status="offline" />
           )}
         </td>
         <td>
           <strong>
             {assessor?.lines &&
               assessor.lines.map((message, i) => {
                 return (
                   <strong key={i}>
                     <div key={i}>
                       <span className={"c-pill c-pill--light"}>
                         {message.name} - {message.quantity}
                       </span>
                     </div>
                   </strong>
                 );
               })}
           </strong>
         </td>
         <td>
           {assessor?.status == "offline" ? (
             assessor?.lastConnection ? (
               <span>Hace: {getResolutionTime(assessor?.lastConnection)}</span>
             ) : (
               "Nunca"
             )
           ) : null}
         </td>
       </HighlightedRow>
     );
  }
  
  return (
    <Row key={assessor.email}>
      <td>
        <strong>{`${assessor?.name} ${assessor?.lastname}`}</strong>
      </td>
      <td>{assessor.email}</td>
      <td>{assessor?.openConv ?? 0}</td>
      <td>
        {assessor.status ? (
          <AssessorStatusIndicator status={assessor.status} />
        ) : (
          <AssessorStatusIndicator status="offline" />
        )}
        { assessor.statusCall ==='onCall' && (<AssessorStatusIndicator status={assessor.statusCall} /> ) }
      </td>
      <td>
        <strong>
          {assessor?.lines &&
            assessor.lines.map((message, i) => {
              return (
                <strong key={i}>
                  <div key={i}>
                    <span className={"c-pill c-pill--light"}>
                      {message.name} - {message.quantity}
                    </span>
                  </div>
                </strong>
              );
            })}
        </strong>
      </td>
      <td>
        {assessor?.status == "offline" ? (
          assessor?.lastConnection ? (
            <span>Hace: {getResolutionTime(assessor?.lastConnection)}</span>
          ) : (
            "Nunca"
          )
        ) : null}
      </td>
    </Row>
  );
};

export default AssessorRow;

