import React from 'react'
import { useColor } from '../../../hooks/useColor';

const WhatsAppIcon = ({ full, color, ...props }) => {
  const colorCode = useColor(color);

  return !full ?
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.7748 17.9777C21.4032 17.7919 19.5765 16.8932 19.2359 16.7692C18.8952 16.6456 18.6476 16.5834 18.3997 16.9553C18.1524 17.3269 17.4401 18.1639 17.2234 18.4118C17.0067 18.6594 16.79 18.6907 16.4184 18.5046C16.0469 18.3188 14.8498 17.9267 13.4305 16.6609C12.3258 15.6764 11.5802 14.4599 11.3635 14.0879C11.1468 13.716 11.3403 13.5149 11.5264 13.3298C11.6934 13.1635 11.898 12.8961 12.0838 12.679C12.2696 12.4623 12.3314 12.3071 12.455 12.0595C12.5793 11.8115 12.5172 11.5945 12.4241 11.4087C12.3314 11.2226 11.5882 9.3945 11.2788 8.65064C10.977 7.92657 10.6711 8.02449 10.4425 8.01303C10.2262 8.00227 9.97824 8.00018 9.73064 8.00018C9.48303 8.00018 9.08053 8.0929 8.73985 8.46483C8.39918 8.83676 7.43932 9.73517 7.43932 11.5636C7.43932 13.3916 8.77077 15.1579 8.95656 15.4058C9.14235 15.6538 11.5764 19.4057 15.3037 21.0147C16.1903 21.3974 16.8824 21.6258 17.4221 21.797C18.3121 22.0797 19.1223 22.0398 19.7623 21.9443C20.476 21.8377 21.9606 21.0455 22.2704 20.1777C22.5801 19.3102 22.5801 18.5667 22.4871 18.4118C22.394 18.2569 22.1464 18.1639 21.7748 17.9777ZM14.9953 27.2316H14.9901C12.7724 27.2309 10.5974 26.6353 8.69958 25.5094L8.24812 25.2413L3.57033 26.4683L4.81913 21.9089L4.525 21.4415C3.288 19.4742 2.63443 17.2005 2.63547 14.8658C2.63825 8.05332 8.18283 2.51084 15.0002 2.51084C18.3014 2.51223 21.405 3.79922 23.7383 6.13429C26.0716 8.47006 27.3559 11.5743 27.3552 14.8759C27.352 21.6891 21.8075 27.2316 14.9953 27.2316ZM25.5142 4.36007C22.7069 1.54993 18.973 0.00175095 14.9953 1.33514e-05C6.7986 1.33514e-05 0.127809 6.66841 0.124683 14.8651C0.123641 17.485 0.808455 20.0423 2.10969 22.2968L0 30L7.88279 27.9327C10.055 29.1173 12.5005 29.7413 14.989 29.7423H14.9953C14.995 29.7423 14.996 29.7423 14.9953 29.7423C23.1906 29.7423 29.8621 23.0733 29.8656 14.8769C29.867 10.9048 28.322 7.16952 25.5142 4.36007Z" fill={colorCode} />
    </svg>
    :
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26.38 4.36006C23.5726 1.54991 19.8387 0.00173637 15.861 0C7.66432 0 0.993531 6.66839 0.990406 14.8651C0.989364 17.4849 1.67418 20.0423 2.97541 22.2968L0.865723 30L8.74851 27.9327C10.9207 29.1173 13.3662 29.7413 15.8548 29.7423H15.861C24.0564 29.7423 30.7279 23.0733 30.7313 14.8769C30.7327 10.9048 29.1877 7.16951 26.38 4.36006Z" fill={colorCode} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.6407 17.9777C22.2691 17.792 20.4424 16.8932 20.1017 16.7692C19.761 16.6456 19.5135 16.5834 19.2655 16.9554C19.0183 17.3269 18.306 18.1639 18.0893 18.4118C17.8726 18.6594 17.6559 18.6907 17.2843 18.5046C16.9127 18.3188 15.7156 17.9267 14.2963 16.6609C13.1917 15.6764 12.4461 14.4599 12.2294 14.0879C12.0127 13.716 12.2061 13.5149 12.3922 13.3298C12.5593 13.1635 12.7638 12.8961 12.9496 12.679C13.1354 12.4623 13.1972 12.3071 13.3208 12.0595C13.4452 11.8115 13.383 11.5945 13.29 11.4087C13.1972 11.2226 12.4541 9.39451 12.1446 8.65065C11.8429 7.92658 11.5369 8.0245 11.3084 8.01304C11.0921 8.00227 10.8441 8.00019 10.5965 8.00019C10.3489 8.00019 9.94638 8.09291 9.60571 8.46484C9.26503 8.83677 8.30518 9.73518 8.30518 11.5636C8.30518 13.3916 9.63662 15.1579 9.82242 15.4058C10.0082 15.6538 12.4423 19.4057 16.1696 21.0147C17.0561 21.3974 17.7483 21.6258 18.2879 21.7971C19.178 22.0797 19.9882 22.0398 20.6282 21.9443C21.3418 21.8377 22.8265 21.0456 23.1362 20.1777C23.446 19.3102 23.446 18.5667 23.3529 18.4118C23.2598 18.257 23.0122 18.1639 22.6407 17.9777Z" fill="white" />
    </svg>
}

export default WhatsAppIcon;