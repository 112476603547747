import styled, { createGlobalStyle } from 'styled-components';
/* import { Theme1 } from "./themes/Theme1"; */

export const GlobalStyle = createGlobalStyle`
:root {
  --background-gray: #f8f9fc;
  --primary-color: #f9781e;
  --header-conversation-color: #3d3d3d;
  --theme-bg-color: #fff;
  --gray-color: #c9cdd2;
  --button-bg-color: rgba(237, 137, 54, 0.12);
  --theme-color: #fa7820;
  --dark-color: #3d3d3d;
  --border-color: #C9CDD2;
  --seda-color: #FEE1CD;
}
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
  }

    // Style SCROLL - No aplica firefox
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px white;
    border-radius: 10px;
    background: #ffffff80;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(124 124 124);
  }
`;

export const PageWrapper = styled.div`
  width: calc(100vw - 240px);
  padding-top: 5.5rem;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fc;
`;

export const PageWrapperMenu = styled.div`
  width: 240px;
  min-height: 100vh;
  height: 100%;
  position: sticky;
  top: 0;
  left: 0;
`;

export const PageStyles = styled.div`
  width: 100vw;
  display: flex;
`;
export const ContentWrapper = styled.div`
  min-height: calc(100vh - 176px);
`;
