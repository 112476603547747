import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { useEffect, useState, useCallback } from 'react';
import TableContent from '../../components/tables/TableContent';
import useGet from '../../hooks/useGet';
import FormTemplate from './components/FormTemplate';
import VisibleRow from './components/VisibleRow';
import { PhoneLinesContext } from '../../Context/PhoneLines';
import Utils from '../../utils';
import PhoneLines from './components/PhoneLines';
import TemplateSearch from './components/TemplateSearch';
import Tag from '../Conversations/components/filters/Tag';
import CloseIcon from '../../components/icons/CloseIcon';

const Templates = () => {
  //const { data: dataTable } = useGet({ route: "/templates"});
  const [dataTable, setDataTable] = useState();
  const {data:phoneLines, isLoading:PhoneLinesLoading} = useGet({route:'/phone-lines/get'});
  const [filters, setFilters] = useState([])
  const [templatesData, setTemplatesData] = useState()
  const [phoneLine, setPhoneLine] = useState('');
  useEffect(()=>{
    getData();
  }, [phoneLine]);
  const handleChange = (event)=>{
    setPhoneLine(event.target.value);
  }
  const getData = useCallback(() => {
    if(localStorage.getItem('multiline')==='true'){
      if(phoneLine){
        Utils.Petition.get('/templates/line/'+phoneLine, (response)=>{
          setTemplatesData(response.data.reverse());
          setDataTable(response.data.reverse());
        })
      }
    }else{
      Utils.Petition.get('/templates', (response)=>{
        setTemplatesData(response.data.reverse());
        setDataTable(response.data.reverse());
      })
    }
  }, [phoneLine]);

  useEffect(() => {
    getData();
  }, [phoneLine, getData]);

  const handleDeleteFilter = (filterType) => {
    let copyOfFilters = JSON.parse(JSON.stringify(filters))
    copyOfFilters =  copyOfFilters.filter(
      (filter) => filter["type"].trim() !== filterType.trim()
    );
    setFilters(copyOfFilters)
    if (copyOfFilters.length === 0) setDataTable(templatesData);
  }

  const handleSearch = (textSearch)=>{
    if(textSearch){
       let textToFind = textSearch.toLowerCase();
       const newDataTable = templatesData.filter((data) => {
         return (
           (data.name && data.name.toLowerCase().includes(textToFind)) ||
           (data.code && data.code.toLowerCase().includes(textToFind))
         );
       });

      
       let copyOfFilters = [...filters]
       let filterData = {
         type: "text",
         fields: ["name", "code"],
         value: textToFind,
       };
       if(copyOfFilters.find((filter)=>filter.type === filterData.type)){
          copyOfFilters = copyOfFilters.map((filter)=>{
            if (filter.type === filterData.type)
              filter.value = filterData.value;
             return filter;
          })
       }else{
        copyOfFilters.push(filterData);
       }
       setFilters(copyOfFilters)
       setDataTable(newDataTable)
    }else{
      handleDeleteFilter("text")
    }
   
  }

  
  return (
    <MDBContainer>
      <PhoneLinesContext.Provider
        value={{
          phoneLines,
          phoneLine,
          phoneLinesAreLoading: PhoneLinesLoading,
          getData,
        }}
      >
        {localStorage.getItem("multiline") === "true" && (
          <MDBRow>
            <MDBCol size="4">
              <MDBCard className="mb-3">
                <MDBCardHeader>Linea de teléfono</MDBCardHeader>
                <MDBCardBody>
                  <PhoneLines
                    name="phone-line"
                    id="phone-line"
                    onChange={handleChange}
                    value={phoneLine}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        )}

        {(localStorage.getItem("multiline") !== "true" || phoneLine) && (
          <>
            <MDBCard className="mb-3">
              <MDBCardHeader>Crear nueva</MDBCardHeader>
              <MDBCardBody>
                <FormTemplate />
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-3">
              <MDBCardHeader>Estado de las plantillas</MDBCardHeader>
              <MDBCardBody>
                <TemplateSearch handleSearch={handleSearch} />
                {filters.length > 0 ? (
                  filters.map((filter, i) => (
                    <Tag key={i}>
                      {filter.value}{" "}
                      <CloseIcon
                        color="orange"
                        width="12"
                        height="12"
                        onClick={() =>
                          handleDeleteFilter(filter.type)
                        }
                      ></CloseIcon>
                    </Tag>
                  ))
                ) : (
                  <></>
                )}
                <MDBTable align="middle" responsive={true}>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Estado</th>
                      <th scope="col">Detalle</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    <TableContent
                      data={dataTable}
                      row={VisibleRow}
                      colsCount={3}
                    />
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
          </>
        )}
      </PhoneLinesContext.Provider>
    </MDBContainer>
  );
}

export default Templates;