import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ConversationsIcon from "../../components/icons/pages/ConversationsIcon";
import GroupICon from "../../assets/Images/groupIcon.svg";
import CallIcon from "../../components/icons/logos/CallIcon";
import Chat from "./components/chats/Chat";
import EmptyChat from "./components/chats/EmptyChat";
import Filters from "./components/filters/Filters";
import { MessagesContainer, NavBar, TableContainer } from "./styles";
import {
  formatGroupedConversations,
  formatMessages
} from "../../utils/Format";
import ContentLoading from "../../components/Loading/ContentLoading";
import ChatLoading from "../../components/Loading/ChatLoading";
import PaginationTable from "../../components/tables/PaginationTable";
import ContactRow from "./components/contacts/ContactRow";
import usePagination from "../../hooks/usePagination";
import { useCallback } from "react";
import Utils from "../../utils";
import Select from "react-select";
import { AlertForm, AlertSync, ICON } from "../../utils/Alert";
import io from "socket.io-client";
import { API_URL } from "../../config";
import { useEventQueue } from "./components/EventQueue";

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;

export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;

export const CustomMessage = styled.p`
  color: gray;
  font-size:85%;
`

const SHOW_ROWS_QUANTITY = 10;
const BATCH_CONVERSATIONS_QUERY = 100;
const sockets = {
  open:false,
  bot:false,
  cola:false,
  close:false,
  calls:false,
  conversation:''
};
const Conversations = () => {
  const [stateModal, setStateModal] = useState({
    modal: false,
  });


  const toggle = () => {
    setStateModal({
      modal: !stateModal.modal,
    });
  };

 let [conversationsData, setConversationsData] = useState(null);
 let [errorConversations, setErrorConversations] = useState(false);
 let [loading, setLoading] = useState(true);
 let [socket, setSocket] = useState(null);
 let [updateText, setUpdateText] = useState('');
 const [allFilteredConversations, setAllFilteredConversations] = useState(conversationsData);

  const [callsLength, setCallsLength] = useState({
    totalCalls:0, totalTime:0, filteredCalls:0, filteredTime:0
  });
  const [currentConversationSelected, setCurrentConversationSelected] = useState(null);

  const [filters, setFilters] = useState([]);
  const [dataRedirect, setDataRedirect] = useState({});
  const [groupProperty, setGroupProperty] = useState({
    value: "",
    label: "Escoger grupo.",
  });
  const [grupos, setGrupos] = useState([]);

  const [subGroupProperty, setSubGroupProperty] = useState({
    value: "",
    label: "Escoger subgrupo.",
  });
  const [subGrupos, setSubGrupos] = useState([]);
  const [_subGrupos, _setSubGrupos] = useState([]);

  const [usersGroupProperty, setUsersGroupProperty] = useState({
    value: "",
    label: "Escoger Agente.",
  });
  const [usersGrupos, setUsersGrupos] = useState([]);
  const [_usersGrupos, _setUsersGrupos] = useState([]);
  const [updateScroll, setUpdateScroll] = useState(false);

  const conversationsRef = useRef(conversationsData);
  const [positionOfQuery, setPositionOfQuery] = useState(0);

  const [currentViewConversations, setCurrentViewConversations] = useState("");
  const [totalConversations, setTotalConversations] = useState(0);
  const [phoneLines, setPhoneLines] = useState(null);

  useEffect(() => {
      conversationsRef.current = conversationsData;
    }, [conversationsData]);
  
  useEffect(()=>{
    Utils.Petition.get("/phone-lines/get", (response) => {
      setPhoneLines(response.data);
      handleChangeView('open');
    });
    enableSocket();

    return ()=>{
      disabledSocket();
    };
    
  }, []);

  const setSocketConversations = (company)=>{
    if(currentViewConversations){
      clearSockets(company,currentViewConversations);
      sockets[currentViewConversations]=true;
      socket.on(`conversation-${company}-${currentViewConversations}`, (_) => {
        if(!updateText){
          setUpdateText('La conversación seleccionada se visualizará en tiempo real, pero se han detectado cambios en otras conversaciones y debes hacer clic en actualizar para visualizarlos');
        }
      });
    }
      
  }
  const enableSocket = ()=>{
    if(!socket){
      setSocket(
        io(API_URL.replace("/api/v1", ""), {
          path: "/api/v1/socket.io",
        })
      );
    }

    
  }
  const disabledSocket = ()=>{
    if(socket!==null){
      const company = localStorage.getItem("company");
      clearSockets(company, currentViewConversations);

    }
  }
  const { addEvent } = useEventQueue(async (event) => {
    let ccs = JSON.parse(JSON.stringify(event.currentConversationSelected));
    let found = false;
    for(let i= ccs.conversation.length-1; i>=0; i--){
      if(ccs.conversation[i].id===event.key){
        found = true;
        event.data=event.data.conversations[event.data.conversations.length - 1]
        console.log(event.data)
        event.data.messages = formatMessages(event.data.messages);
        ccs.conversation[i] = event.data;
        ccs.conversation[i].id = event.key;
        break;
      }
    }
    if(found===true){
      setCurrentConversationSelected(ccs);
    }else{
      console.log('Nuevo id');
    }
  });

  useEffect(() => {
    Utils.Petition.get("/groups/get", (result) => {
      let groups = [];
      let subgroups = {};
      let usersGroup = [];
      if (result.data) {
        for (const g in result.data) {
          if (Object.hasOwnProperty.call(result.data, g)) {
            const element = result.data[g];
            const data_users = result.data.data_users;
            console.log("g",g)
            console.log("data_users",data_users)
            if(g!="data_users"){
              groups.push({ value: g, label: g });
              subgroups[g] = [];
              for (const s in element) {
                
                subgroups[g].push({ value: s, label: s });
                console.log("subgroups",subgroups)
              
                if (!usersGroup[g]) usersGroup[g] = [];
                if (!usersGroup[g][s]) usersGroup[g][s] = [];
                for (const u in element[s].users) {
                  const userG = element[s].users[u];
                  let label_user=data_users[userG]?data_users[userG]?.name+" "+data_users[userG]?.lastname+" - "+userG:userG
                  console.log("userG",data_users[userG],userG)
                  usersGroup[g][s].push({ value: userG, label: label_user });
                }
              
              }
            }
          }
        }
        
        setUsersGrupos(usersGroup);
        setGrupos(groups);
        _setSubGrupos(subgroups);
        _setUsersGrupos(usersGroup);
      }
    });
  }, []);

  const redirectConversation = () => {
    // setIsLoading(true);
    dataRedirect.group = groupProperty?.value;
    dataRedirect.subgroup = subGroupProperty?.value;
    dataRedirect.newAsesor = usersGroupProperty?.value;
    if (
      subGroupProperty?.value != "" &&
      groupProperty?.value != "" &&
      usersGroupProperty?.value != ""
    ) {
      Utils.Petition.post(
        "/conversations/redirect-conversation",
        dataRedirect,
        () => {
          AlertForm({
            title: "Conversación redirigida.",
            icon: ICON.SUCCESS,
            text: `Se redirigio la conversación al agente ${usersGroupProperty.value}.`,
            timer: 4000,
          });
          cleanRedirect();
          toggle();
        },
        (error) => {
          console.log(error);
          if(error.response?.data==='No existe el contacto'){
            AlertForm({
              title: "No ha sido posible redirigir la conversación",
              icon: ICON.ERROR,
              text: "El contacto aún no existe en Hubspot.",
              timer: 4000,
            });
            toggle();
          }else{
            AlertForm({
              title: "No ha sido posible redirigir la conversación",
              icon: ICON.ERROR,
              text: "Ha ocurrido un error al redirigir la conversación, intente nuevamente.",
              timer: 4000,
            });
          }

          console.error(error);
        }
      );
    } else {
      toggle();
      AlertForm({
        title: "No ha sido posible redirigir la conversación",
        icon: ICON.ERROR,
        text: "Ha ocurrido un error al redirigir la conversación, intente nuevamente.",
        timer: 4000,
      });
    }
  };
  const findTotalCallValues = (conversations)=>{
    let times = [];
    let totalCalls = 0;
    for(let i in conversations){
      
      if(conversations[i].callRegistration){
        let keys = Object.keys(conversations[i].callRegistration);
        totalCalls +=keys.length;
        for(let keyCallRegistration in keys){
          if(conversations[i].callRegistration[keys[keyCallRegistration]].duration && conversations[i].callRegistration[keys[keyCallRegistration]].duration !== 'N/A'){
            times.push(conversations[i].callRegistration[keys[keyCallRegistration]].duration && conversations[i].callRegistration[keys[keyCallRegistration]].duration);
          }
        }
      }

    }
    return {total: totalCalls, time: sumHours(times)};
  }
  const sumHours = (times) => {
    // Initialize variables for total hours, minutes, and seconds.
    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;
  
    // Iterate through the array of times and sum each component.
    for (let i = 0; i < times.length; i++) {
      const parts = times[i].split(':');
      totalHours += parseInt(parts[0]);
      totalMinutes += parseInt(parts[1]);
      totalSeconds += parseInt(parts[2]);
    }
  
    // Adjust minutes and seconds if they exceed 60.
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;
    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds %= 60;
  
    // Calculate the total minutes.
    const totalMinutesCount = totalHours * 60 + totalMinutes;
  
    return totalMinutesCount;
  }

  const cleanRedirect = () => {
    setGroupProperty({ value: "", label: "Escoger grupo." });
    setSubGroupProperty({ value: "", label: "Escoger sub grupo." });
    setUsersGroupProperty({ value: "", label: "Escoger Agente." });
    setSubGrupos([]);
    setUsersGrupos([]);
  };
  const handleChangeGroup = (data) => {
    setSubGroupProperty({ value: "", label: "Escoger sub grupo." });
    setUsersGroupProperty({ value: "", label: "Escoger Agente." });
    setSubGrupos(_subGrupos[data.value] !== "" ? _subGrupos[data.value] : []);
  };
  const handleChangeSubGroup = (data) => {
    setUsersGroupProperty({ value: "", label: "Escoger Agente." });
    console.log("users",_usersGrupos)
    setUsersGrupos(
      _usersGrupos[groupProperty.value][data.value] !== ""
        ? _usersGrupos[groupProperty.value][data.value]
        : []
    );
  };
  const setClickElements = useCallback(
    (conv) => 
      Array.isArray(conv) &&
      conv?.map((conversation) => ({
        conversation,
        setCurrentConversation: setCurrentConversationSelected,
        active:(conversation.line === currentConversationSelected?.line && conversation.identificatorCode === currentConversationSelected?.identificatorCode),
        setUpdateScroll,
        currentConversationSelected,
      })),
    [currentConversationSelected]
  );

  useEffect(()=>{
    const company = localStorage.getItem("company");
    if(currentConversationSelected){
      Utils.Petition.get(`/conversations/realtime-update/old/${sockets.conversation ? sockets.conversation : 'false'}/conversation/${currentConversationSelected.key}`)
      socket.off(`conversation-${company}-${sockets.conversation}`);
      socket.on(`conversation-${company}-${currentConversationSelected.key}`, ({key, data, conversationGroupId}) => {
        if(conversationGroupId ===currentConversationSelected.key){
          addEvent({
            key, data, conversationGroupId, currentConversationSelected
          })
        }
      
        //conversations:[response.data]
      });
      sockets.conversation = currentConversationSelected.key;
      
    }else{
      if(sockets.conversation){
        Utils.Petition.get(`/conversations/remove-realtime-update/${sockets.conversation}`);
        if(socket){
          socket.off(`conversation-${company}-${sockets.conversation}`);
        }
        sockets.conversation = '';
      }
    }
  }, [currentConversationSelected]);



  useEffect(() => {
    const handleOffline = () => {
      AlertSync(
        "Se ha perdido la conexión a internet.",
        "Podría no tener los mensajes sincronizados correctamente.",
        "Sincronizar ahora",
        () => {
                    
          setFilters({});        
          
          Utils.Petition.get(
            getUrl(currentViewConversations),
            (result) => {
              let conversations = formatGroupedConversations(result.data, phoneLines);
              setConversationsData(conversations);
              setAllFilteredConversations(conversations);
            }
          );

        }
      );
    };

    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const {
    paginationPages,
    setCurrentPage: setConversationCurrentPage,
    showedData: showedConversations,
  } = usePagination({
    data: allFilteredConversations,
    showRows: SHOW_ROWS_QUANTITY,
    transformShowedData: setClickElements,
  });
  

  useEffect(() => {
    handleChangeView(currentViewConversations);
  }, [ filters ]);

  useEffect(() => {
    getConversation();
  }, [currentViewConversations]);

  const handleChangeView = (value) => {
    setLoading(true);
    setConversationsData(null);
    setCurrentConversationSelected(null);
    if(value===currentViewConversations){
      getConversation();
    }else{
      setCurrentViewConversations(value);
    }
    setUpdateText('');
  };

  const getConversation = ()=>{
    if(currentViewConversations){
      setAllFilteredConversations(null);
      Utils.Petition.get(
        getUrl(currentViewConversations),
        (result) => {
          debugger
          const company = localStorage.getItem("company");
          setTotalConversations(result.data.totalConversations)
          let conversations = formatGroupedConversations(result.data, phoneLines);
          setConversationsData(conversations);
          setAllFilteredConversations(conversations);
          setPositionOfQuery(BATCH_CONVERSATIONS_QUERY - 1);
          setLoading(false);
          setSocketConversations(company);

          let filteredCalls = findTotalCallValues(conversations);

      
          setCallsLength({
            filteredCalls: filteredCalls.total,
            filteredTime:  filteredCalls.time,
            totalCalls: result.data.totalCalls,
            totalTime: result.data.totalCallsTime, 
          });
        }
      );
    }
  }

  useEffect(() => {
    setCurrentConversationSelected(null);
  }, [filters]);
  const downloadMoreConversations = ()=>{
    setLoading(true);
    Utils.Petition.get(
      getUrl(currentViewConversations, true),
      (result) => {
        let conversations = formatGroupedConversations(result.data, phoneLines);
        let joinConversations = [...conversationsData, ...conversations];
        setConversationsData(joinConversations);
        setAllFilteredConversations(joinConversations);
        setPositionOfQuery(positionOfQuery + result.data.numOfConversationsOfQuery)
        
        let filteredCalls = findTotalCallValues(joinConversations);
        setCallsLength({
          filteredCalls: filteredCalls.total,
          filteredTime:  filteredCalls.time,
          totalCalls: result.data.totalCalls,
          totalTime: result.data.totalCallsTime, 
        });

        setLoading(false);
        disabledSocket();
      })
  }
  const clearSockets = (company, view)=>{
    if(socket){
      if(sockets.open && view !== 'open'){
        socket.off(`conversation-${company}-open`);
        sockets.open = false;
      }
      if(sockets.bot && view !== 'bot'){
        socket.off(`conversation-${company}-bot`);
        sockets.bot = false;
      }
      if(sockets.nonstart && view !== 'nonstart'){
        socket.off(`conversation-${company}-nonstart`);
        sockets.nonstart = false;
      }
      if(sockets.cola && view !== 'cola'){
        socket.off(`conversation-${company}-cola`);
        sockets.cola = false;
      }
      if(sockets.close  && view !== 'close'){
        socket.off(`conversation-${company}-close`);
        sockets.close = false;
      }
      if(sockets.calls && view !== 'calls'){
        socket.off(`conversation-${company}-calls`);
        sockets.calls = false;
      }
    }
    if(sockets.conversation.socket){
      socket.off(`conversation-${company}-${sockets.conversation.name}`);
      sockets.conversation = '';
      
    }
  }

  const getUrl = (states, isPositionQuery) => `/conversations/get-all?states=${states}&grouping=true&position=${isPositionQuery?positionOfQuery:'0'}&quantity=${BATCH_CONVERSATIONS_QUERY}&filters=${encodeURI(JSON.stringify(filters))}`
  
  return (
    <>
      <MDBContainer className="mb-3">
        <MDBModal isOpen={stateModal.modal} toggle={toggle}>
          <MDBModalHeader toggle={toggle}>
            Redireccionamiento de conversacion
          </MDBModalHeader>
          <MDBModalBody>
            <div className="row bg-white">
              <div className="col-12 p-2">
                <Select
                  options={grupos}
                  value={groupProperty}
                  onChange={(data) => {
                    setGroupProperty(data);
                    handleChangeGroup(data);
                  }}
                />
              </div>
            </div>
            <div className="row bg-white">
              <div className="col-12 p-2">
                <Select
                  options={subGrupos}
                  value={subGroupProperty}
                  onChange={(data) => {
                    setSubGroupProperty(data);
                    handleChangeSubGroup(data);
                  }}
                />
              </div>
            </div>
            <div className="row bg-white">
              <div className="col-12 p-2">
                <Select
                  options={usersGrupos}
                  value={usersGroupProperty}
                  onChange={(data) => {setUsersGroupProperty(data);console.log("userdata",data)}}
                />
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={toggle}>
              Cancelar
            </MDBBtn>
            {subGroupProperty?.value != "" &&
              groupProperty?.value != "" &&
              usersGroupProperty?.value != "" && (
                <MDBBtn
                  color="primary"
                  className="btn-deep-orange btn Ripple-parent"
                  onClick={redirectConversation}
                >
                  Redireccionar
                </MDBBtn>
              )}
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <section className="mx-4">
        <div className="d-flex">
          <ConversationsIcon />
          <Title>Interacciones</Title>
        </div>
        <NavBar className="nav nav-tabs border-0">
          <li className="nav-item">
            <a
              onClick={() => handleChangeView("open")}
              className={`nav-link text-decoration-none ${
                currentViewConversations === "open" ? "active" : ""
              }`}
              aria-current="page"
              href="#"
            >
              Abiertas
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => handleChangeView("bot")}
              className={`nav-link text-decoration-none ${
                currentViewConversations === "bot" ? "active" : ""
              }`}
              aria-current="page"
              href="#"
            >
              En bot
            </a>
          </li>
          <li className="nav-item">
          <a
              onClick={() => handleChangeView("cola")}
              className={`nav-link text-decoration-none ${
                currentViewConversations === "cola" ? "active" : ""
              }`}
              aria-current="page"
              href="#"
            >
              Encoladas
            </a>
          </li>
          <li className="nav-item">
          <a
              onClick={() => handleChangeView("nonstart")}
              className={`nav-link text-decoration-none ${
                currentViewConversations === "nonstart" ? "active" : ""
              }`}
              aria-current="page"
              href="#"
            >
              Sin iniciar
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => handleChangeView("close")}
              className={`nav-link text-decoration-none ${
                currentViewConversations === "close" ? "active" : ""
              }`}
              aria-current="page"
              href="#"
            >
              Cerradas
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              onClick={() => handleChangeView("calls")}
              className={`nav-link text-decoration-none ${
                currentViewConversations === "calls" ? "active" : ""
              }`}
              aria-current="page"
              href="#"
            >
              Sólo llamadas
            </a>
          </li> */}
        </NavBar>

        {errorConversations && (
          <div>Ups, Ha ocurrido un error.</div>
        )}

        
        {!errorConversations && (
          <>
            { !loading && (
              <Description>
                <img src={GroupICon} width="18" height="18" /> Mostrando{" "}
                { allFilteredConversations.length } de {totalConversations} interacciones
                  
                {" "}    
                  
              </Description>
            )}
            { ( loading /*|| !allFilteredCloseConversations */) && (
              <ContentLoading aproxWidth={"200px"} />
            )}

            <Filters
              setFilters={setFilters}
              showStateFilter={
                currentViewConversations === "close" ? false : true
              }
              groupsAndSubgroups={_subGrupos}
              currentView={currentViewConversations}
            />

            { updateText !== '' && <div className="alert alert-warning">{updateText}</div> }

            <button onClick={()=>{handleChangeView(currentViewConversations)}} className="btn btn-orange d-inline  btn-sm">Actualizar</button>
            <button value="" onClick={downloadMoreConversations} className="btn btn-orange d-inline btn-sm">Cargar más </button>
            <TableContainer className="row bg-white">
              <div key={0} className="col-8 p-0">
                <PaginationTable
                  headers={[
                    "Contacto",
                    "Línea",
                    "Agente",
                    "Estado",
                    "Propiedades",
                    "Grupo",
                    "Subgrupo",
                    "Fecha inicio",
                    "Últ. Interacción",
                    "Últ. Actualización",
                  ]}
                  withoutRight={true}
                  content={
                    //currentViewConversations === "open" ? 
                    showedConversations
                    //  : showedCloseConversations
                  }
                  pages={
                    //currentViewConversations === "open" ? 
                    paginationPages
                    //  : closePaginationPages
                  }
                  setPages={
                    //currentViewConversations === "open" ? 
                    setConversationCurrentPage
                    //  : setCloseConversationCurrentPage
                  }
                  row={ContactRow}
                />
              </div>
              <div key={1} className="col-4 p-0">
                <MessagesContainer>
                  {!currentConversationSelected && !loading && (
                    <EmptyChat />
                  )}
                  {!showedConversations && loading && <ChatLoading />}
                  {currentConversationSelected && showedConversations && (
                    <Chat
                      conversation={ currentConversationSelected }
                      setSubGroupProperty={setSubGroupProperty}
                      setGroupProperty={setGroupProperty}
                      setUsersGroupProperty={setUsersGroupProperty}
                      subGroupProperty={subGroupProperty}
                      groupProperty={groupProperty}
                      usersGroupProperty={usersGroupProperty}
                      toggle={toggle}
                      setDataRedirect={setDataRedirect}
                      updateScroll={updateScroll}
                      setUpdateScroll={setUpdateScroll}
                      phoneLines={phoneLines}
                    />
                  )}
                </MessagesContainer>
              </div>
            </TableContainer>
          </>
        )}
      </section>
    </>
  );
};

export default Conversations;
