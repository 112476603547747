import React, { useEffect, useState } from "react"
import DateFilter from "../Conversations/components/filters/DateFilter"
import ConversationsIcon from "../../components/icons/pages/ConversationsIcon";
import { Description, Title } from "../Conversations";
import { MDBAlert, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import PaginationTable from "../../components/tables/PaginationTable";
import usePagination from "../../hooks/usePagination";
import RecordRow from "./components/RecordRow";
import Utils from "../../utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import Loading from "../../components/Loading/Loading";
import ExcelExport from "../../components/ExcelExport";
  
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

// Registrar los módulos de escala necesarios


const DashboardSMS = ()=>{

    const [since, setSince] = useState(`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-01T00:00`);
    const [until, setUntil] = useState(`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${(new Date().getDate()).toString().padStart(2, '0')}T${(new Date().getHours()).toString().padStart(2, '0')}:${(new Date().getMinutes()).toString().padStart(2, '0')}`);
    const [milliseconds, setMillisecond] = useState(1)
    const [currentSMSSelect, setCurrentSMSSelect] = useState(null);
    const [SMSActualPlan, setSMSActualPlan] = useState(null);
    const [firstLoading, setFirstLoading] = useState(false);
    const months =['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre', 'Diciembre'];
    const [chartData, setChartData] = useState(null);
    const [SMSDetail, setSMSDetail] = useState(null);
    useEffect(()=>{
        Utils.Petition.get('/sms/plan', (response)=>{
            let data = {
                ok:0,
                error:0,
                plan:response.data.plan 
            }
            for(let i in response.data.consumed){
                switch(response.data.consumed[i]._id){
                    case 'ok': 
                        data.ok=response.data.consumed[i].count;
                    break;
                    case 'error': 
                        data.error=response.data.consumed[i].count;
                    break;
                }
            }
            setSMSActualPlan(data);
        })
    }, []);
    
    useEffect(()=>{
        if(!firstLoading && Number.isInteger(since) && Number.isInteger(until) && milliseconds>1){
            setFirstLoading(true);
            updateFilterByDate();
        }
    }, [since, until, milliseconds]);



    const [SMSDataTable, setSMSDataTable] = useState(null);

    useEffect(()=>{
        if(SMSDataTable){
            const records =  SMSDataTable?.map((sms) => {
                let s = {
                    workflow:sms._id,
                    error:0,
                    ok:0,
                }
                for(let i in sms.workflows){
                    s[sms.workflows[i].status] = sms.workflows[i].count;
                }
                return ({
                    sms:s,
                    setCurrentSMSSelect,
                    active: currentSMSSelect?.workflow === s.workflow,
                    currentSMSSelect,
                })
            })
            setShowedSMSRecords(records);
        }else{
            setShowedSMSRecords(null);
        }
    }, [SMSDataTable, currentSMSSelect]);
    useEffect(()=>{
        if(currentSMSSelect){
            setChartData(null);
            setSMSDetail(null);
            Utils.Petition.post(`/sms/detail/start/${since}/end/${until}`, currentSMSSelect, (response)=>{
                const data = {
                    labels: response.data.map(entry => `${entry._id.year}-${entry._id.month}-${entry._id.day} T${entry._id.hour}:00`),
                    datasets: [
                      {
                        label: 'Errores',
                        borderColor: 'red',
                        data: response.data.map(entry => entry.total_error)
                      },
                      {
                        label: 'Envíos Correctos',
                        borderColor: 'green',
                        data: response.data.map(entry => entry.total_ok)
                      }
                    ]
                };
                setChartData(data);
            });

            Utils.Petition.post(`/sms/last-messages/start/${since}/end/${until}/limit/10`, currentSMSSelect, (response)=>{
                const messages = response.data.map((item)=> ({
                    phone:item.to.replace('sms:',''),
                    text:item.body
                }));
                setSMSDetail(messages);
            });

        }
    },[currentSMSSelect]);

    const [showedSMSRecords, setShowedSMSRecords] = useState(SMSDataTable);
    const { paginationPages, setCurrentPage, showedData } = usePagination({ data: showedSMSRecords, showRows: 10 });
    
    const handleDatesFilter = (dates) => {
        setSince(dates.since);
        setUntil(dates.until);
        setMillisecond(dates.until - dates.since);
    };

    const updateFilterByDate = ()=>{
        if(milliseconds > 0 && milliseconds <= 2678500000){
            setSMSDataTable(null);
            setCurrentSMSSelect(null);
            Utils.Petition.get(`/sms/start/${since}/end/${until}`, (response)=>{
                setSMSDataTable(response.data);
            })
        }
    }
    const getAllData = (callback)=>{
        Utils.Petition.get(`/sms/detail/start/${since}/end/${until}`, (response)=>{
            callback(response.data);
        })
    }
    const formatDataToDownload = (data) => {
        return data.map((item)=>({
            date: item.createdAt.toLocaleString(),
            workflow: item.from,
            to: item.to,
            text: item.body,
            status: item.status,
        }))
    }
    
    return (
        <MDBContainer className="mb-3">
            <section className="mx-4">
                <div className="d-flex">
                    <div>
                        <div className="d-flex">
                            <ConversationsIcon />
                            <Title>Envios de SMS</Title>
                        </div>
                        <Description>Estado del plan ({months[new Date().getMonth()]}): {SMSActualPlan?.ok} de {SMSActualPlan?.plan} mensajes</Description>
                    </div>
                </div>
                <div className="mb-3">
                    <DateFilter
                        setDateFilter={handleDatesFilter}
                        since={since}
                        until={until}
                    />
                    <button className="btn btn-sm btn-orange" onClick={updateFilterByDate}>Filtrar</button>
                    <ExcelExport asyncData={true} handleData={getAllData} formatData={formatDataToDownload} fileName="Detalle SMS" />
                </div>
                { 
                        (milliseconds <= 0 || milliseconds > 2678500000) && 
                        <MDBAlert color="danger">
                            La fecha de inicio debe ser menor que la fecha de fin y no puede superar un mes
                        </MDBAlert>
                    }
                <div className="mb-3">
                    <MDBRow>
                        <MDBCol size="6">
                        {
                            <PaginationTable
                                headers={["Workflow", "Envios correctos", "Envios con error"]}
                                withoutRight={false}
                                content={showedData}
                                pages={paginationPages}
                                setPages={setCurrentPage}
                                row={RecordRow}
                            />
                        }
                        </MDBCol>
                        <MDBCol size="6">
                            { !currentSMSSelect && <MDBAlert color="info">Presiona un elemento de la tabla para conocer el detalle del consumo</MDBAlert> }
                            
                            {
                                currentSMSSelect && 
                                <MDBCard className="mb-3">
                                    <MDBCardHeader>Detalle por fecha</MDBCardHeader>
                                    <MDBCardBody>
                                        {
                                            !chartData && 
                                            <div>
                                                <Loading />
                                            </div>
                                        }
                                        {
                                            chartData && 
                                            <div>
                                                <Line data={chartData} />
                                            </div>
                                        }
                                    </MDBCardBody>
                                </MDBCard>
                            }
                            {
                                currentSMSSelect && 
                                <MDBCard className="mb-3">
                                    <MDBCardHeader>Últimos mensajes</MDBCardHeader>
                                    <MDBCardBody>
                                        {
                                            !SMSDetail && 
                                            <div>
                                                <Loading />
                                            </div>
                                        }
                                        {
                                            SMSDetail && 
                                            <div>
                                                {
                                                    SMSDetail.map((item)=>(
                                                        <div className="mb-3 "><small><b>{item.phone}</b> - {item.text}</small></div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </MDBCardBody>
                                </MDBCard>
                            }
                        </MDBCol>
                    </MDBRow>
                </div>
            </section>
                
        </MDBContainer>
    )
}
export default DashboardSMS;