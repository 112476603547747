import React from 'react';
import OptionLoading from '../Loading/OptionLoading';

const SelectGroup = ({ name, mode, field, defaultValue, handleChange, isLoading, groupsKeys }) => {
  return (
    <select
      className={'form-control ' + (mode === "update" && 'form-control-sm')}
      name={name}
      id={name}
      onChange={handleChange}
      value={field}
      required
    >
      <option value={''}>Selecciona un grupo</option>
      <OptionLoading isLoading={isLoading} />

      {
        groupsKeys.map((group, i) => (
          <option value={group} key={i}>{group}</option>
        ))
      }
    </select>
  )
}

export default SelectGroup;