import { MDBContainer } from "mdbreact";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PeopleIcon from "../../components/icons/pages/PeopleIcon";
import PaginationTable from "../../components/tables/PaginationTable";
import usePagination from "../../hooks/usePagination";
import useGet from "../../hooks/useGet";
import LogsRow from "./components/LogsRow";
import Utils from "../../utils";
import Conventions from "../../components/ConventionBox/Conventions";
import DateFilter from "../Conversations/components/filters/DateFilter";
import ExcelExport from "../../components/ExcelExport";
import { getDateByTime } from "../../utils/Times";
import { STATUS_LOGS } from "./components/LogsStatusIndicator";


export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;

export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;

const SHOW_ROWS_QUANTITY = 20;

const LogsActivity = () => {

  let {
    data: Logs,
    error: errorLogs,
    isLoading,
    setData: setLogs,
  } = useGet({ route: "/logs/get" });

  const [allFilteredLogs, setAllFilteredLogs] = useState(Logs);
  const [sort, setSort] = useState("status");
  const [orderSort, setOrderSort] = useState("desc");
  const since = useRef();
  const until = useRef();

  useEffect(() => {
    setAllFilteredLogs(Logs);
  }, [ Logs]);

  const {
    paginationPages,
    setCurrentPage,
    showedData: showedLogs,
  } = usePagination({
    data: allFilteredLogs,
    showRows: SHOW_ROWS_QUANTITY,
   /*  transformShowedData: setClickElements, */
  });

  const handleDatesFilter = (dates) => {
    console.log({dates})
    const getLogs = async () => {
      Utils.Petition.get(
        `/logs/get?start=${dates.since}&end=${dates.until}`,
        (response) => {
          let res = response.data;
          setLogs(res);
        },
        (err) => console.warn(err)
      );
    };
    getLogs()

  };

  const formatLogsRecord = (data)=>{
    return data.map(obj => {
      return {
        Fecha: new Date(obj.createdAt).toLocaleString("es"), 
        Usuario: `${obj.user.firstname} ${obj.user.lastname}`, 
        Registro: obj.register,
        Tipo: STATUS_LOGS.find((s) => s.value === obj.type)?.name,
        App: obj.app
      }
    });
  }

  return (
    <MDBContainer>
      <section className="mx-4">
        <div className="d-flex">
          <PeopleIcon color={"orange"} width="48px" height="40px" />
          <Title>Logs de actividades</Title>
        </div>
        <Description>Aquí puedes ver las acciones de cada usuario</Description>
        <div className="row form-group">
          {/* <div className="col-4">
            <label className="form-inline">
              Buscar:
              <input
                type="search"
                className="form-control m-1"
                placeholder="usuario@ejemplo.com"
                id="search"
                onKeyUp={searchLogs}
              />
            </label>
          </div> */}
          <div className="d-flex align-items-end my-2">
            <DateFilter
              setDateFilter={handleDatesFilter}
              since={since}
              until={until}
            />
            <div>
              <ExcelExport excelData={Logs} formatData={formatLogsRecord} fileName={"Logs"}/>
            </div>
          </div>
        </div>
        <div>
          <PaginationTable
            headers={[
              "Fecha",
              "Usuario",
              "Registro",
              "Tipo",
              "App"
            ]}
            withoutRight={true}
            content={showedLogs}
            pages={paginationPages}
            setPages={setCurrentPage}
            row={LogsRow}
          />
        </div>
      </section>
    </MDBContainer>
  );
};

export default LogsActivity;

