import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Loading from '../Loading/Loading';

ChartJS.register(ArcElement, Tooltip, Legend);

const GraficaDoughnut = ({ dataItems, title,subtitle, isLoading,labelPosition="right" }) => {
  const [labelsData, setLabelsData] = useState([]);
  const [amountData, setAmountData] = useState([]);
  const [color, setColor] = useState([]);
  useEffect(() => {
    let keys = [];
    let amount = [];
    let colors = [];

    for (const key in dataItems) {
      if (Object.hasOwnProperty.call(dataItems, key)) {
        const value = dataItems[key].value;
        const label = dataItems[key].key;
        if(!label.includes("@nmksoftware")){
          var r = Math.floor(Math.random() * 255);
          var g = Math.floor(Math.random() * 255);
          var b = Math.floor(Math.random() * 255);
          colors.push(`rgb(${r},${g},${b})`);
          keys.push(label);
          amount.push(value);
        }
      }
    }
    setLabelsData(keys);
    setAmountData(amount);
    setColor(colors);
  }, [dataItems]);
  const legendPlugin = {
    id: 'legendPlugin',
    beforeDraw: (chart) => {
      const { data } = chart;
      if (!data._labelsUpdated) {
        const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
        data.labels = data.labels.map((label, i) => {
          const value = data.datasets[0].data[i];
          const percentage = ((value / total) * 100).toFixed(1);
          return `${label} ${percentage}%`;
        });
        data._labelsUpdated = true;  // Marca las etiquetas como actualizadas
      }
    },
  };
  const options = {
    layout: {
      padding: {
        top: 0,
        bottom: 25,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        
        position: labelPosition,
        labels: {
          font: {
            size: 11
          },
          generateLabels: (chart) => {
            const { data } = chart;
            const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
            return data.labels.map((label, i) => {
              const value = data.datasets[0].data[i];
              const percentage = ((value / total) * 100).toFixed(0);
              return {
                text: `${label} ${value} (${percentage}%)`,
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: data.datasets[0].borderColor[i],
                lineWidth: data.datasets[0].borderWidth,
                hidden: chart.getDatasetMeta(0).data[i].hidden,
                index: i,
              };
            });
          },
        },
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 20,
        },
        color: 'black',
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      subtitle: {
        position: "bottom",
        font: {
          size: 12
        },
        display: subtitle?true:false,
        text: subtitle,
        padding: {
          top: 10
        },
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: true,
        titleColor: '#f9781e',
        backgroundColor: '#090710',
        titleAlign: 'center',
        titleMarginBottom: 12,
        padding: 12,
        caretSize: 10,
      },
    },
  };
  const data = {
    labels: labelsData,
    datasets: [
      {
        label: '# of Votes',
        data: amountData,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 2,
        hoverOffset: 30,
        weight: 0.9,
      },
    ],
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && <Doughnut data={data} options={options} plugins={[legendPlugin]}/>}
    </>
  );
};

export default GraficaDoughnut;
