import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes } from './Routes';
import { GlobalStyle } from './GlobalStyle';
import { UserProvider } from './Context/User';

export const App = () => (
  <React.Fragment>
    <UserProvider>
      <GlobalStyle />
      <Routes />
    </UserProvider>
  </React.Fragment>
);
