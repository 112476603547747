import React, { useState } from 'react';
import Utils from '../../../utils';
const BlackListRow = ({_id, phone}) => {
    const [deleted, setDeleted] = useState(false);
    const handleDelete = ()=> {
        Utils.Petition.delete('/black-list/'+_id);
        setDeleted(true);
    }
    return (
        !deleted?
        <tr>
            <td>{phone}</td>
            <td>
                <button className='btn btn-sm px-2 btn-danger' onClick={()=>handleDelete()}>
                    Eliminar
                </button>
            </td>
        </tr>
        :null
    )
}
export default BlackListRow;