import React from 'react'

const ArrowUpRightIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 8.50003V1M11 1H3.50001M11 1L1 11" stroke="#C9CDD2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowUpRightIcon;