import React, { useState, useEffect } from "react";
import { debounce } from 'lodash';

function TextFilter({
  setTextFilter,
  placeholder = "Buscar...",
  buttonText = "Buscar",
  filterAutomatically = false,
}) {
  const [valueToFind, setValueToFind] = useState("");

  const debouncedFilter = debounce(value => {
    setTextFilter(value);
  }, 300);

  useEffect(() => {
    if (filterAutomatically) {
      debouncedFilter(valueToFind);
    }
  }, [valueToFind]);

  const handleButtonClick = (e) => {
    if (!filterAutomatically) {
      setTextFilter(valueToFind);
    }
  };

  return (
    <>
      <input
        type="text"
        className="form-control w-25 m-1"
        placeholder={placeholder}
        onChange={(e) => {
          setValueToFind(e.target.value);
        }}
      />
      {!filterAutomatically && (
        <button onClick={handleButtonClick} className="btn btn-sm btn-orange ">
          {buttonText}
        </button>
      )}
    </>
  );
}

export default TextFilter;

