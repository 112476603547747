import React from "react";
import OptionLoading from "../Loading/OptionLoading";

const CheckboxGroup = ({
  name,
  handleChange,
  isLoading,
  subgroupsKeysByGroupKey,
  group,
  field,
}) => {
  return (
    <div>
      <OptionLoading isLoading={isLoading} />
      {subgroupsKeysByGroupKey[group]?.map((subgroup, i) => (
        <div key={i} className="form-check">
          <input
            type="checkbox"
            id={`${name}_${subgroup}`}
            name={name}
            value={subgroup}
            checked={field.includes(subgroup)}
            onChange={handleChange}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor={`${name}_${subgroup}`}>
            {subgroup}
          </label>
        </div>
      ))}
    </div>
  );
};

const SelectSubgroup = ({
  name,
  mode,
  field,
  group,
  defaultValue,
  handleChange,
  isLoading,
  subgroupsKeysByGroupKey,
  multiple = false,
}) => {
  if (multiple) {
    return (
      <CheckboxGroup
        name={name}
        handleChange={handleChange}
        isLoading={isLoading}
        subgroupsKeysByGroupKey={subgroupsKeysByGroupKey}
        group={group}
        field={field}
      />
    );
  }

  return (
    <select
      className={"form-control " + (mode === "update" && "form-control-sm")}
      name={name}
      id={name}
      onChange={handleChange}
      value={field}
      disabled={group === ""}
      required
      multiple={multiple}
    >
      <option value={""}>Selecciona un subgrupo</option>
      <OptionLoading isLoading={isLoading} />
      {subgroupsKeysByGroupKey[group]?.map((subgroup, i) => (
        <option value={subgroup} key={i}>
          {subgroup}
        </option>
      ))}
    </select>
  );
};

export default SelectSubgroup;

