import { MDBContainer } from 'mdbreact';
import React from 'react';
import FormTemplate from './components/FormTemplate';

const MessagesAut = () => {

  return (
    <MDBContainer>
      <FormTemplate />
    </MDBContainer>
  )
}

export default MessagesAut;