import React, { useState } from 'react';
import { Description, Title } from '../Conversations';
import PaginationTable from '../../components/tables/PaginationTable';
import usePagination from '../../hooks/usePagination';
import useGet from '../../hooks/useGet';
import CampaignRow from './components/CampaignRow';
import ConversationsIcon from '../../components/icons/pages/ConversationsIcon';
import CampaignForm from './components/CampaignForm';
import { CampaignsContext } from '../../Context/Campaigns';
import Filter from '../Conversations/components/filters/Filter';
import { useEffect } from 'react';
import GroupsContextProvider from '../../Context/Groups';

const Campaigns = () => {
  const { data: campaigns, error, setData: setCampaigns } = useGet({ route: "/campaigns/get-campaigns" });
  const { data: bots, isLoading: BotsLoading } = useGet({ route: "/botkit/get" });

  const [showedCampaigns, setShowedCampaigns] = useState(campaigns);
  const { paginationPages, setCurrentPage, showedData } = usePagination({ data: showedCampaigns, showRows: 10 });

  const [stateFilter, setStateFilter] = useState(true);

  useEffect(() => {
    setShowedCampaigns(campaigns?.filter(campaign => campaign.is_open === stateFilter));
  }, [stateFilter, campaigns]);

  const updateCampaign = (campaign) => {
    let campaignsCopy = campaigns;
    const index = campaigns.findIndex(filteredCampaign => filteredCampaign._id === campaign._id);

    if (index !== -1) {
      campaignsCopy[index] = { ...campaignsCopy[index], ...campaign };
    }

    setCampaigns([...campaignsCopy]);
  }

  const addCampaign = (campaign) => {
    setCampaigns([campaign, ...campaigns]);
  }

  if (error) {
    return (
      <div className='container'>
        <section className='mx-4 mb-4'>
          Ha ocurrido un error.
        </section>
      </div>
    )
  }

  return (
    <GroupsContextProvider>
      <CampaignsContext.Provider value={{
        updateCampaign,
        bots: bots,
        botsAreLoading: BotsLoading,
      }}>
        <section className='mx-4 mb-4'>
          <div className="d-flex">
            <ConversationsIcon />
            <Title>Campañas - mensajes masivos</Title>
          </div>
          <Description>Disponibles en workflows de HubSpot</Description>

          <div className="p-2">
            <Filter
              label={"Estado"}
              field="is_open"
              options={[
                {
                  label: "Abiertas",
                  value: true,
                  selected: stateFilter === true
                },
                {
                  label: "Cerradas",
                  value: false,
                  selected: stateFilter === false
                }
              ]}
              setSelected={(_, value) => setStateFilter(value)}
              exclude={true}
            />
          </div>

          <div className="row">
            <div className="col-8">
              <PaginationTable
                headers={["Nombre", {
                  value: "Redirección hacia",
                  colSpan: 3
                }, "Fecha de vencimiento", "Acciones"]}
                withoutRight={false}
                content={showedData}
                pages={paginationPages}
                setPages={setCurrentPage}
                row={CampaignRow}
              />
            </div>

            <div className="col-4 m-0 p-0">
              <CampaignForm addCampaign={addCampaign} />
            </div>
          </div>
        </section>
      </CampaignsContext.Provider>
    </GroupsContextProvider>

  )
}

export default Campaigns;