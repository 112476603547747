import React from 'react'
import { useColor } from '../../../hooks/useColor'

const MessengerIcon = ({ full, color, ...props }) => {

  const colorCode = useColor(color);

  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="path-1-inside-1_136_8379" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.689 -1.52588e-05C7.23898 -1.52588e-05 0.688965 6.18971 0.688965 14.55C0.688965 18.923 2.48132 22.7018 5.39986 25.3118C5.64486 25.5312 5.7925 25.8383 5.8028 26.1674L5.88427 28.8354C5.91044 29.6865 6.78956 30.2404 7.56839 29.8965L10.5457 28.5821C10.7981 28.4709 11.081 28.4503 11.3469 28.5236C12.7152 28.8997 14.1713 29.1001 15.689 29.1001C24.139 29.1001 30.689 22.9103 30.689 14.55C30.689 6.18971 24.139 -1.52588e-05 15.689 -1.52588e-05Z" />
      </mask>
      <path d="M5.39986 25.3118L7.06638 23.4483L7.06769 23.4494L5.39986 25.3118ZM5.8028 26.1674L8.30157 26.0892L8.30163 26.0911L5.8028 26.1674ZM5.88427 28.8354L3.38545 28.9122L3.38543 28.9117L5.88427 28.8354ZM7.56839 29.8965L8.57806 32.1836L8.57804 32.1836L7.56839 29.8965ZM10.5457 28.5821L9.53608 26.2951L9.53783 26.2943L10.5457 28.5821ZM11.3469 28.5236L10.6842 30.9341L10.683 30.9338L11.3469 28.5236ZM15.689 2.49998C8.52852 2.49998 3.18896 7.65986 3.18896 14.55H-1.81104C-1.81104 4.71956 5.94944 -2.50002 15.689 -2.50002V2.49998ZM3.18896 14.55C3.18896 18.2115 4.67821 21.3126 7.06638 23.4483L3.73333 27.1753C0.284431 24.091 -1.81104 19.6344 -1.81104 14.55H3.18896ZM7.06769 23.4494C7.80455 24.1093 8.26919 25.0538 8.30157 26.0892L3.30402 26.2456C3.31582 26.6228 3.48516 26.9531 3.73202 27.1741L7.06769 23.4494ZM8.30163 26.0911L8.3831 28.759L3.38543 28.9117L3.30396 26.2437L8.30163 26.0911ZM8.38309 28.7585C8.35476 27.8375 7.4027 27.2369 6.55874 27.6095L8.57804 32.1836C6.17642 33.2438 3.46613 31.5356 3.38545 28.9122L8.38309 28.7585ZM6.55872 27.6095L9.53608 26.2951L11.5554 30.8691L8.57806 32.1836L6.55872 27.6095ZM9.53783 26.2943C10.3175 25.9508 11.191 25.8875 12.0108 26.1133L10.683 30.9338C10.9711 31.0132 11.2787 30.9911 11.5537 30.8699L9.53783 26.2943ZM12.0097 26.113C13.1588 26.429 14.392 26.6001 15.689 26.6001V31.6001C13.9507 31.6001 12.2715 31.3705 10.6842 30.9341L12.0097 26.113ZM15.689 26.6001C22.8495 26.6001 28.189 21.4402 28.189 14.55H33.189C33.189 24.3805 25.4286 31.6001 15.689 31.6001V26.6001ZM28.189 14.55C28.189 7.65986 22.8495 2.49998 15.689 2.49998V-2.50002C25.4286 -2.50002 33.189 4.71956 33.189 14.55H28.189Z" fill={colorCode} mask="url(#path-1-inside-1_136_8379)" />
      <path d="M11.1442 11.85L11.0878 11.8145L11.1442 11.85L6.73778 18.8406C6.73778 18.8406 6.73777 18.8406 6.73777 18.8406C6.35473 19.4487 7.10218 20.1339 7.67489 19.6996C7.6749 19.6996 7.67491 19.6996 7.67491 19.6996L12.4078 16.1076C12.7515 15.8463 13.227 15.845 13.5723 16.1041C13.5723 16.1041 13.5723 16.1041 13.5723 16.1041L17.0767 18.7326C18.0973 19.4978 19.5537 19.2292 20.2338 18.1501L24.64 11.1595L24.6964 11.1951L24.64 11.1595C25.0234 10.5514 24.2758 9.86596 23.7032 10.3005L18.9702 13.8926C18.6265 14.1535 18.1511 14.1551 17.8058 13.8961C17.8058 13.8961 17.8058 13.8961 17.8058 13.896L14.3011 11.2675C13.2808 10.5024 11.8244 10.7709 11.1442 11.85Z" fill={colorCode} stroke={colorCode} strokeWidth="0.133333" />
    </svg>

  )
}

export default MessengerIcon