/* import { useRolesList } from "Hooks/useRolesList"; */
import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [dataUser, setDataUser] = useState({
    company: '',
  });

  return <UserContext.Provider value={{ dataUser, setDataUser }}>{children}</UserContext.Provider>;
}
