import React, {useState, useEffect} from 'react';
import Petition from '../../utils/Petition';
function Notification(){
    const [statusPlan, setStatusPlan] = useState(null);
    const replacementTokens = (message, tokens)=>{
        for(let j in tokens){
            const reg = new RegExp('@'+j, 'g');
            message = message.replace(reg, tokens[j]);
        }
        return message;
    }
    const getNotificationState = (data, notificationKey, planKey)=>{
        
        const messageStructure = {
            high:'Le informamos que del plan contratado de @plan, llevas un consumo aproximado de @percent%.',
            end:'Le informamos que del plan contratado de @plan, llevas un consumo aproximado de @percent%.',
            plans: {
                wa: '@limit Sesiones de WhatsApp',
                fb: '@limit Sesiones de Facebook Messenger',
                sms: '@limit Mensajes de texto',
                minutes: '@limit Minutos de llamadas'
            },
        }
        if(data.notificationStatus[notificationKey]){
            for(let i in data.notificationStatus[notificationKey]){
                if(data.notificationStatus[notificationKey][i].status==true && data.notificationStatus[notificationKey][i].percent>85){
                    let tokens = {
                        percent: data.notificationStatus[notificationKey][i].percent,
                        limit: data.configPlan[planKey],
                    }
                    tokens.plan = replacementTokens(messageStructure.plans[notificationKey], tokens);
                    if(i==0) return { message: replacementTokens(messageStructure.end, tokens), type:'danger'};
                    return { message: replacementTokens(messageStructure.high, tokens), type:'warning'};
                }
            }
        }

        return false;
    }
    useEffect(()=>{
        Petition.get('/connectionBusiness/planStatus',(result)=>{
            let data = result.data;
            setStatusPlan({
                wa: getNotificationState(data, 'wa', 'wa-limit-messages'),
                fb: getNotificationState(data, 'fb', 'fb-limit-messages'),
                sms: getNotificationState(data, 'sms', 'sms-limit-messages'),
                minutes: getNotificationState(data, 'minutes', 'minutes-limit'),
            });
        },(_)=>{
            console.log('No se tiene configuraciones de plan activas');
        })
    }, []);

    return (
        statusPlan ?
        <div className='container'>
            {
                Object.keys(statusPlan).map((key) => (
                    statusPlan[key].type?
                    <div key={key} className={"alert alert-"+statusPlan[key].type} role={statusPlan[key].type}>
                        {statusPlan[key].message}
                        <br />
                        Le invitamos a subir el plan actual dando <a target="_blank" href="https://www.talkspro.co/cambiar-plan">clic aquí.</a>
                    </div>
                    :null
                ))
            }
        </div>
        : null
    )
}
export default Notification;