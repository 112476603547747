import React, { useState, useEffect } from 'react';
import Utils from '../../../utils';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import TableContent from '../../../components/tables/TableContent';
import VisibleRow from './VisibleRow';
import { AlertForm, ICON } from '../../../utils/Alert';
import Loading from '../../../components/Loading/Loading';
import { ContainerInput, ReasignContext, OptionsReasign } from '../styles';
import Select from 'react-select';


const FormTemplate = () => {
  const days=["lunes","martes","miercoles","jueves","viernes","sabado","domingo"];
  const [enable_limit_conversations, setEnable_limit_conversations] = useState(false);
  const [enable_hour_encole, setEnable_hour_encole] = useState(false);
  const [limit_conversations, setLimit_conversations] = useState(1);
  const [mens_automatic, setMens_automatic] = useState(false);
  const [mens_automatic_time, setMens_automatic_time] = useState("");
  const [mens_automatic_text, setMens_automatic_text] = useState("");
  const [close_automatic_conv, setClose_automatic_conv] = useState(false);
  const [enable_automathic_message, setEnable_automathic_message] = useState(false);
  const [contactMessage, setContactMessage] = useState("");
  const [agentMessage, setAgentMessage] = useState("");
  const [hourMessage, setHourMessage] = useState("");
  const [enable_control_time, setEnable_control_time] = useState("");
  const [enable_control_time_input_value, setEnable_control_time_input_value] = useState("");
  const [enable_control_message, setEnable_control_message] = useState("");
  const [reasign_conversation, setReasign_conversation] = useState("");

  const [mens_automatic_array, setMens_automatic_array] = useState([]);
  const [mens_automatic_edit_mode, setMens_automatic_edit_mode] = useState(false)

  const [assign_conversation_only_online_agents, setAssign_conversation_only_online_agents] = useState(false);
  const [close_message, setClose_message] = useState([]);
  const [link_close, setLink_close] = useState([]);

  const [dataGroup, setDataGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [subGroups, setSubGroups] = useState([]);

  const [group, setGroup] = useState('')
  const [subGroup, setSubGroup] = useState('');
  const [timeZone, setTimezone] = useState("America/Bogota");

  const [isLoading, setIsLoading] = useState(true);
  const [timeZones, setTimeZones] = useState([]);

  const [userProperties, setUserProperties] = useState([]);
  const [optionsUserProperties, setOptionsUserProperties] = useState([]);
  
  const reasign_options = [
    { name: "No Reasignar", value: "No" },
    { name: "Reasignar sólo cuando el agente atienda la conversación", value: "OnlyWhenConversationStarts" },
    {name:"Reasignar siempre", value:"Always"},
  ];
  

  useEffect(() => {
    Utils.Petition.publicGet('https://worldtimeapi.org/api/timezone', (response)=>{
      setTimeZones(response);
    }, null, null, true);
    
    Utils.Petition.get('/hubspot/getContacts', (response)=>{
   
      let tempOptions = [];
      for(let i in response.data){
        tempOptions.push({ value: response.data[i].name, label: `${response.data[i].label} (${response.data[i].name})`, description: response.data[i].label, type:response.data[i].fieldType});
      }
      setOptionsUserProperties(tempOptions);
      getConfig(null, tempOptions);
    })
   
  }, []);
  const millisecondsToMinutes = (time)=>{
    return parseFloat(((time/1000)/60).toFixed(2));
  }
  const minutesToMilliseconds = (time)=>{
    return time*60*1000;
  }
  const updateRowData = async(index, newData)=>{
    
    setMens_automatic_edit_mode(!mens_automatic_edit_mode);
    let copyOfAutomaticMessages = mens_automatic_array;
   
    copyOfAutomaticMessages[index] = {
      text: newData.newText,
      time: newData.newTime,
    };
    setMens_automatic_array(copyOfAutomaticMessages);
    
    
  }
  const removeRowAutomaticArray = (i)=>{
    let tempArray = JSON.parse(JSON.stringify(mens_automatic_array));
    tempArray.splice(i,1);
    setMens_automatic_array(tempArray);
    localStorage.setItem('updated', 'true')
  }
  const getConfig =  function(event, _optionsUserProperties){
    setIsLoading(true);
    if(event){
      setSubGroup(event.target.value);
    }
    let grupo=document.getElementById("groups").value
    let subgrupo=document.getElementById("subgroups").value
    Utils.Petition.post('/automation/get', 
    {
      group:grupo,
      subgroup:subgrupo
    }
    ,async (data) => {
       //Limpio todas las horas y fechas
      const dDates = document.getElementsByClassName('date-hour');
      for(let i=0; i<dDates.length; i++){
        dDates[i].value='';
      }
      const dCheckboxHour = document.getElementsByClassName('checkbox-hour');
      for(let i=0; i<dCheckboxHour.length; i++){
        dCheckboxHour[i].checked=false;
      }
      let groups = getGroups(data.group)
      setDataGroup(data.group)
      setGroups(groups)
      if(data?.data){
        if(data.data?.schedules){
          for (const key in data.data.schedules) {
            if (Object.hasOwnProperty.call(data.data.schedules, key)) {
              if(data.data.schedules[key].enable){
                let id=days[key]
                document.getElementById(id).checked = true
                document.getElementById("inicio_"+id).value = data.data.schedules[key].start
                document.getElementById("fin_"+id).value = data.data.schedules[key].end
              }
            }
          }
        }
        
        if(data.data?.gtm){
          setTimezone(data.data.gtm);
        }
       
        setEnable_limit_conversations(data.data?.enableLimitConversations)
        setLimit_conversations(data.data?.limitConversations)
        setContactMessage(data.data.contactMessage)
        setEnable_automathic_message(data.data.automaticMessageContact)
        let automaticMessages = JSON.parse(JSON.stringify(data.data.automaticMessages));
        for(let i in automaticMessages){
          automaticMessages[i].time = millisecondsToMinutes(automaticMessages[i].time);
        }
       
        setMens_automatic_array(automaticMessages);
        setMens_automatic(data.data.enableMessage)
        setClose_automatic_conv(data.data.closeConversation)
        setEnable_hour_encole(data.data.enableHourEncole)

        setEnable_control_message(data.data.reasignConversation.text)
        setEnable_control_time(millisecondsToMinutes(data.data.reasignConversation.time))
        setEnable_control_time_input_value(millisecondsToMinutes(data.data.reasignConversation.time))
        setClose_message(data.data.closeMessage)
        setLink_close(data.data.linkClose)
        setHourMessage(data.data.hourMessage)
        setAgentMessage(data.data.agentMessage)
        setAssign_conversation_only_online_agents(data.data.assignConversationOnlyOnlineAgents)
        
    
       
        if(data.data.required_props){
          setUserProperties(data.data.required_props);
        }

        const reasign_conversation_config = (data.data.reasignConversation);
        if(!reasign_conversation_config.mode){
          setReasign_conversation("No");
        }else{
          setReasign_conversation(reasign_conversation_config.mode);
        }
        //
        
      }else{
        cleanForm()
      }
      setIsLoading(false);
    });
  }
  const getGroups =  function(group){
    let data=[]
    for (const key in group) {
      data.push({name:key})
    }
    return data
  }
  const getSubGroups =  function(event){
    setGroup(event.target.value);
    setSubGroup('');
    setSubGroups([])
    let grupo=document.getElementById("groups").value;
    if(grupo!==""){
      let data=[]
      for (const key in dataGroup) {
        if(key===grupo){
          for (const keys in dataGroup[key]) {
            data.push({name:keys})
          }
          break;
        }
      }
      setSubGroups(data);
    }else{

      document.getElementById("subgroups").value = '';
      getConfig(null, optionsUserProperties);
    }

  }
  const checks = async function (event) {
   
    switch(event.target.name){
      case 'mens_automatic': setMens_automatic(event.target.checked);
      break;
      case 'close_automatic_conv': setClose_automatic_conv(event.target.checked);
      break;
      case 'enable_automathic_message': setEnable_automathic_message(event.target.checked);
      break;
      /*case 'reasign_conversation': setReasign_conversation(event.target.value);
      break;*/
      case 'enable_limit_conversations': setEnable_limit_conversations(event.target.checked);
      break;
      case 'enable_hour_encole': setEnable_hour_encole(event.target.checked);
      break;
      case 'assign_conversation_only_online_agents': setAssign_conversation_only_online_agents(event.target.checked);
      break;
    }
    localStorage.setItem('updated', 'true');
  }
  const agg = async function(event){
    event.preventDefault();
    let time=document.getElementById("mens_automatic_time").value
    let text=document.getElementById("mens_automatic_text").value
    if(time !== "" && text!== ""){
      let data={
        time,
        text
      }
      let mens=JSON.parse(JSON.stringify(mens_automatic_array))
      mens.push(data)
      setMens_automatic_array(mens)
      localStorage.setItem('updated', 'true');
    }
  }
  const arrayHours =  function(){
    let h=[]
    document.getElementsByName("horas").forEach((a,e)=>{
      let id=a.getAttribute('id')
      let start=document.getElementById("inicio_"+id).value
      let end=document.getElementById("fin_"+id).value
      let data={
        start:"",
        end:"",
        enable:false
      }
      if(a.checked){
        data={
          start,
          end,
          enable:true
        }
      }
      h.push(data)
    
    })
    return h
  }
  const handleSubmit = async function (event) {
    event.preventDefault();
    if(reasign_conversation!=="No" && !document.getElementById("enable_control_time").value){
      AlertForm({
        title: "¡Tiempo inválido!",
        text: `Para habilitar la re-asignación, debes especificar el tiempo. Por favor intenta de nuevo con una cantidad de minutos válida`,
        icon: ICON.ERROR,
        position: "top-end",
      });
      return
    }
    let schedules=await arrayHours();
    let mens_automatic_array_temp = JSON.parse(JSON.stringify(mens_automatic_array));
    for(let i in mens_automatic_array_temp){
      mens_automatic_array_temp[i].time = minutesToMilliseconds(mens_automatic_array_temp[i].time);
    }
    let automaticMessages=mens_automatic_array_temp;
    let limitConversations=document.getElementById("limit_conversations").value
    let enableLimitConversations=document.getElementById("enable_limit_conversations").checked
    let enableHourEncole=document.getElementById("enable_hour_encole").checked 
    let assignConversationOnlyOnlineAgents=document.getElementById('assign_conversation_only_online_agents').checked;
    let group=document.getElementById("groups").value
    let subgroup=document.getElementById("subgroups").value
    let closeConversation=document.getElementById("close_automatic_conv").checked
    let enableMessage=document.getElementById("mens_automatic").checked
    let automaticMessageContact=document.getElementById("enable_automathic_message").checked
    let contactMessage=document.getElementById("contactMessage").value
    let closeMessage=document.getElementById("close_message").value
    let hourMessage=document.getElementById("hour_message").value
    let agentMessage=document.getElementById("agent_message").value
    let linkClose=document.getElementById("link_close").value
    let reasignConversation={
      time:minutesToMilliseconds(document.getElementById("enable_control_time").value),
      text:document.getElementById("enable_control_message").value,
      mode:reasign_conversation
    }

  
    
    if((group !== "" && subgroup!=="") || (group === "" && subgroup==="")){
      const requestData = {
        schedules,
        enableHourEncole,
        limitConversations,
        enableLimitConversations,
        assignConversationOnlyOnlineAgents,
        automaticMessages,
        closeConversation,
        enableMessage,
        contactMessage,
        reasignConversation,
        automaticMessageContact,
        closeMessage,
        linkClose,
        hourMessage,
        agentMessage,
        group,
        subgroup,
        required_props:userProperties,
        gtm:timeZone
      };
      const endpoint = `/automation/update`;
      Utils.Petition.post(endpoint, requestData, (response) => {
        AlertForm({
          title: "¡Todo salió correctamente!",
          text: `${response.message}`,
          icon: ICON.SUCCESS,
          position: "top-end",
        });
        localStorage.setItem('updated', '');
        Utils.Petition.logs({data: requestData, endpoint, type: "Update", register: "Automatizaciones: Actualizar" });
      },(err)=>{
        AlertForm({
          title: "¡Ocurrió un error!",
          text: `Por favor intenta de nuevo más tarde`,
          icon: ICON.ERROR,
          position: "top-end",
        });
      }, ()=>{
        setIsLoading(false);
      });
    }
  }

  const cleanForm = () => {
    setContactMessage("")
    setEnable_automathic_message(false)
    setEnable_hour_encole(false)
    setMens_automatic_array([])
    setEnable_limit_conversations(false);
    setAssign_conversation_only_online_agents(false);
    setMens_automatic(false)
    setClose_automatic_conv(false)
    //setReasign_conversation("")
    setEnable_control_message("")
    setEnable_control_time("")
    setClose_message([])
    setLink_close([])
    setHourMessage("")
    setAgentMessage("")
    setTimezone("America/Bogota");
    setLimit_conversations(1);
    setUserProperties([]);
  }


  let msgContext;
  if(reasign_conversation === 'No'){
      msgContext = <ReasignContext class="msg_context">No reasignará la conversacion a otro facilitador en ningún momento.</ReasignContext>
    } else if (reasign_conversation === 'OnlyWhenConversationStarts') {
      msgContext = <ReasignContext class="msg_context">La conversación dejará de reasignar cuando el agente atienda al chat al iniciar una conversación.</ReasignContext>
    } else if (reasign_conversation === 'Always'){
      msgContext = <ReasignContext class="msg_context">La conversación se reasignará siempre, independientemente de que si el agente responde al inicio de la conversación o no.</ReasignContext>
    }
  

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && <div className='p2'><Loading /></div>}
      <MDBCard className="mb-3">
        <MDBCardBody>
          <div class="form-group">
            <label htmlFor="groups">Grupo</label>
            <div className='row'>  
            <div className="col-4">
              <select className="form-control" id="groups" name='groups' value={group} onChange={getSubGroups}>
                <option value="">Seleccione una opción</option>
                {
                  groups?.map((g) => (
                    <option value={g.name} >{g.name}</option>
                  ))
                }
              </select>
            </div>
            </div>
          </div>
          <div class="form-group">
            <label htmlFor="subgroups">Subgrupo</label>
            <div className='row'>
              <div className="col-4">
                <select className="form-control" id="subgroups" value={subGroup} name='subgroups' disabled={group===''} onChange={(event)=>getConfig(event, optionsUserProperties)} >
                  <option value="" defaultChecked>Seleccione una opción</option>
                  {
                    subGroups?.map((g) => (
                      <option value={g.name} >{g.name}</option>
                    ))

                  }
                </select>
              </div>
            </div>
          </div>
          {!group && !subGroup && <div class="alert alert-info" role="alert">Esta configuración aplica para todos los grupos y subgrupos</div>} 
          {group && !subGroup && <div class="alert alert-danger" role="alert">Selecciona un subgrupo</div>} 
          {group && subGroup && <div class="alert alert-info" role="alert">Esta configuración aplica para el grupo <b>{group}</b> y el subgrupo <b>{subGroup}</b></div>} 
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="mb-3">
        <MDBCardHeader>Limite de conversaciones activas</MDBCardHeader>
        <MDBCardBody>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                <input type="checkbox" name='enable_limit_conversations' id='enable_limit_conversations' checked={enable_limit_conversations} onClick={checks}/>
                  <label htmlFor="enable_limit_conversations"> <small>
                  Habilitar limite
                  </small></label>
                  
                </div>
              </div>
              <div className="col-4">
                  <label htmlFor="limit_conversations"> <small>
                    Limite de conversaciones
                  </small></label>
                  <input type="number" min="1" step="any" name='limit_conversations' id='limit_conversations' className='form-control'  value={limit_conversations} onChange={(event)=>{localStorage.setItem('updated', 'true'); setLimit_conversations(event.target.value)} }/>
              </div>
            </div>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="mb-3">
        <MDBCardHeader>Mensajes generados automaticamente si el contacto no responde</MDBCardHeader>
        <MDBCardBody>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    <input type="checkbox" name='mens_automatic' id='mens_automatic' checked={mens_automatic} onClick={checks}/>
                    Habilitar mensajes automaticos
                  </label>
                </div>
              </div>
              <div className="col-4">
                  <label htmlFor="mens_automatic_time"> <small>
                    Tiempo (minutos)
                  </small></label>
                  <input type="number" min="0.5" step="any" name='mens_automatic_time' id='mens_automatic_time' className='form-control' value={mens_automatic_time} onChange={(event)=>{setMens_automatic_time(event.target.value); localStorage.setItem('updated', 'true')}} />
                  <br />
                  <input type="button" value="Agregar" disabled={!mens_automatic_time || !mens_automatic_text} className="form-control btn btn-sm btn-orange m-auto w-100" onClick={agg}/>
              </div>
              <div className="col-8">
                  <label htmlFor="mens_automatic_text"> <small>
                    Mensaje
                  </small></label>
                  <textarea type="text" rows="3" name='mens_automatic_text' id='mens_automatic_text' className='form-control' value={mens_automatic_text} onChange={(event)=>{setMens_automatic_text(event.target.value); localStorage.setItem('updated', 'true')}}></textarea>
              </div>
              <MDBTable align="middle" responsive={true}>
                <MDBTableHead>
                  <tr>
                    <th scope="col">Tiempo (minutos)</th>
                    <th scope="col">Mensaje</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <TableContent removeRow={removeRowAutomaticArray} updateRow={updateRowData} data={mens_automatic_array} row={VisibleRow} colsCount={4} isEditModeOn={mens_automatic_edit_mode}/>
                </MDBTableBody>
              </MDBTable>
              <div className="col-12">
                <div className="form-group">
                  
                  <input type="checkbox" name='close_automatic_conv' id='close_automatic_conv' checked={close_automatic_conv} onClick={checks}/>
                  <label htmlFor="close_automatic_conv"> <small>
                    Cerrar conversacion en el ultimo mensaje automático
                  </small></label>
                </div>
              </div>
            </div>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="mb-3">
          <MDBCardHeader>Mensaje automático cuando se transfiera a un asesor</MDBCardHeader>
          <MDBCardBody>
                <div className="form-group">
                  <input type="checkbox" name='enable_automathic_message' id='enable_automathic_message' checked={enable_automathic_message} onClick={checks}/>
                  <label htmlFor="enable_automathic_message"> <small>
                    Habilitar mensaje automatico
                  </small></label>
                  <textarea type="text" rows="3" name='contactMessage' defaultValue={contactMessage} id='contactMessage' className='form-control' onChange={()=>localStorage.setItem('updated', 'true')} />
                </div>
          </MDBCardBody>
        </MDBCard>
        <MDBCard className="mb-3">
        <MDBCardHeader>Reasignación automática</MDBCardHeader>
          <MDBCardBody>
                <div className='form-group'>
                  <label htmlFor="reasign_conversation"> <small>
                      Modo de reasignación automática de mensajes
                  </small></label>
                  <select className='form-control' name='reasign_conversation' value={reasign_conversation} onChange={(e)=>{setReasign_conversation(e.target.value)}}>
                    {
                      reasign_options.map((option)=> (
                        <OptionsReasign value={option.value}>{option.name}</OptionsReasign>
                      ))
                    }
                  </select>
                  {msgContext}               
                </div>
                <div className="form-group">
                      <label htmlFor="enable_control_time"> <small>
                        Tiempo (minutos)
                      </small></label>
                      <input type="number" min="0.5" step="any" name='enable_control_time' id='enable_control_time' className='form-control' defaultValue={enable_control_time===0 ? null :enable_control_time } required={reasign_conversation!=="No"} onChange={(e)=>{setEnable_control_time_input_value(e.target.value)}} onKeyDown={checks}/>
                  
                  </div>
                  <div className="form-group">
                    <label htmlFor="enable_control_message"> <small>
                      Mensaje al contacto
                    </small></label>
                    <textarea type="text" rows="3" name='enable_control_message' id='enable_control_message' className='form-control' defaultValue={enable_control_message} onChange={()=>localStorage.setItem('updated', 'true')} />
                  </div>
                  <div class="alert alert-info" role="alert">El mensaje sólo se enviará una vez y permitirá indicarle al contacto que espere un poco más mientras es atendido</div>
                

          </MDBCardBody>
      </MDBCard>
      <MDBCard className="mb-3">
          <MDBCardHeader>Mensaje automatico al cerrar conversación manualmente</MDBCardHeader>
          <MDBCardBody>
            <div className='row'>
              <div className="col-6">
                <label htmlFor="close_message"> <small>
                  Mensaje al contacto
                </small></label>
                <textarea type="text" rows="3" name='close_message' id='close_message' className='form-control' defaultValue={close_message} onChange={()=>localStorage.setItem('updated', 'true')} />
              </div>
              <div className='col-6'>
                <label htmlFor="link_close"> <small>
                  Enlace de la encuesta de satisfacción
                </small></label>
                <textarea type="text" rows="3" name='link_close' id='link_close' className='form-control' defaultValue={link_close} onChange={()=>localStorage.setItem('updated', 'true')} />
              </div>
            </div>
          </MDBCardBody>
      </MDBCard>
      <MDBCard className="mb-3">
          <MDBCardHeader>Horarios de atencion</MDBCardHeader>
          <MDBCardBody>
            <div class="form-group">
              <label htmlFor="timeZone">Huso horario</label>
              <div className='row'>  
              <div className="col-4">
                <select className="form-control" id="timeZone" name='timeZone' value={timeZone} onChange={(event)=>{setTimezone(event.target.value); localStorage.setItem('updated', 'true')}}>
                  {
                    timeZones.map(timeZone=>(
                      <option value={timeZone}>{timeZone}</option>
                    ))
                  }
                </select>
              </div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-3">
                <input type="checkbox" name='horas' className="checkbox-hour" id='lunes' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <label htmlFor="lunes"> <small> 
                  Lunes
                </small></label>
                <input type="time"  name='inicio_lunes' id='inicio_lunes' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <input type="time"  name='fin_lunes' id='fin_lunes' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
              </div>
              <div className="col-3">
                <input type="checkbox" name='horas' className="checkbox-hour" id='martes' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <label htmlFor="martes"> <small>
                  Martes
                </small></label>
                <input type="time"  name='inicio_martes' id='inicio_martes' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <input type="time"  name='fin_martes' id='fin_martes' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
              </div>
              <div className="col-3">
                <input type="checkbox" name='horas' className="checkbox-hour" id='miercoles' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <label htmlFor="miercoles"> <small>
                  Miercoles
                </small></label>
                <input type="time"  name='inicio_miercoles' id='inicio_miercoles' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <input type="time"  name='fin_miercoles' id='fin_miercoles' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
              </div>
              <div className="col-3">
                <input type="checkbox" name='horas' className="checkbox-hour" id='jueves' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <label htmlFor="jueves"> <small>
                  Jueves
                </small></label>
                <input type="time"  name='inicio_jueves' id='inicio_jueves' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')} />
                <input type="time"  name='fin_jueves' id='fin_jueves' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
              </div>
              <div className="col-3">
                <input type="checkbox" name='horas' className="checkbox-hour" id='viernes' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <label htmlFor="viernes"> <small>
                  Viernes
                </small></label>
                <input type="time"  name='inicio_viernes' id='inicio_viernes' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <input type="time"  name='fin_viernes' id='fin_viernes' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
              </div>
              <div className="col-3">
                <input type="checkbox" name='horas' className="checkbox-hour" id='sabado' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <label htmlFor="sabado"> <small>
                  Sabado
                </small></label>
                <input type="time"  name='inicio_sabado' id='inicio_sabado' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <input type="time"  name='fin_sabado' id='fin_sabado' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
              </div>
              <div className="col-3">
                <input type="checkbox" name='horas' className="checkbox-hour" id='domingo' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <label htmlFor="domingo"> <small>
                  Domingo
                </small></label>
                <input type="time"  name='inicio_domingo' id='inicio_domingo' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
                <input type="time"  name='fin_domingo' id='fin_domingo' className='form-control date-hour' onChange={()=>localStorage.setItem('updated', 'true')}/>
              </div>
            </div>
            <div className="form-group">
              <input type="checkbox" name='enable_hour_encole' id='enable_hour_encole' checked={enable_hour_encole} onClick={checks}/>
              <label htmlFor="enable_hour_encole"> 
                Cerrar conversaciones automaticamente al finalizar el horario laboral
              </label>
            </div>
            <div className='row form-group'>
              <div className="col-12">
                <label htmlFor="hour_message"> <small>
                  Mensaje si no hay horario de atención
                </small></label>
                <textarea type="text" name='hour_message' id='hour_message' className='form-control' defaultValue={hourMessage} onChange={()=>localStorage.setItem('updated', 'true')}/>
              </div>
            </div>
            
          </MDBCardBody>
      </MDBCard>
      <MDBCard className='mb-3'>
        <MDBCardHeader>Control de agentes</MDBCardHeader>
        <MDBCardBody>
          <div className="form-group">
            <label>   
              <input type="checkbox" name='assign_conversation_only_online_agents' id='assign_conversation_only_online_agents' checked={assign_conversation_only_online_agents} onClick={checks}/>
                <small>
                  Asignar conversaciones sólo a quien esté conectado, en caso de que no hayan agentes disponibles, se encolarán mensajes hasta que alguien se conecte. Si esta opción está desahibilitada, se asignarán conversaciones sin importar si los agentes estan conectados o no. Puedes utilizar la reasignación automática para rotar conversaciones que no sean atendidas en el tiempo definido.
                </small>
            </label>
          </div>
          <div className='form-group'>
            <label htmlFor="agent_message"> <small>
              Mensaje automático si no hay agentes disponibles
            </small></label>
            <textarea type="text" name='agent_message' id='agent_message' className='form-control' defaultValue={agentMessage} onChange={()=>localStorage.setItem('updated', 'true')}/>
          </div>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className='mb-3'>
        <MDBCardHeader>Propiedades obligatorias para el agente</MDBCardHeader>
        <MDBCardBody>
          <div className='form-group'>
            <label>
              <small>Requisitos que se solicitan a los agentes para finalizar una conversación, como por ejemplo, proporcionar información personal como el nombre.</small>
            </label>
            <Select isMulti options={optionsUserProperties} onChange={(data)=>setUserProperties(data)} id='userProperties' value={userProperties} />
          </div>
        </MDBCardBody>
      </MDBCard>
      <div className="form-group">
        <ContainerInput>
          <button type="submit" disabled={group && !subGroup} className="btn btn-info">
            Actualizar
          </button>
        </ContainerInput>
      </div>
    </form>
  )
}
export default FormTemplate;