import React, { useState } from "react";

import FragmentsIcon from "../../components/icons/pages/FragmentsIcon";
import styled from "styled-components";
import Utils from "../../utils";
import ExcelExport from "../../components/ExcelExport";
import { getDateByTime } from "../../utils/Times";
import DateFilter from "../Conversations/components/filters/DateFilter";
import PaginationTable from "../../components/tables/PaginationTable";
import usePagination from '../../hooks/usePagination';
import MessageRecordRow from './components/MessageRecordRow';

import {
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Loading from "../../components/Loading/Loading";
import GraficaDonut from "../../components/Charts/GraficaDoughnut";
import Select from "react-select";

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;
export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;

let controller;
export const TableContainer = styled.div`
  border: 1px solid #dee2e6;
  border-radius: 5px;
`;
function getStartAndEndOfWeek() {
  const today = new Date();

  // Obtiene el día actual de la semana (0 para domingo, 1 para lunes, etc.)
  const dayOfWeek = today.getDay();

  // Calcular la fecha de inicio (lunes)
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - ((dayOfWeek + 6) % 7));

  // Calcular la fecha de fin (domingo)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  // Formatear las fechas en el formato YYYY-MM-DD
  const formatDate = (date, zerohour = false) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan en 0
    const day = String(date.getDate()).padStart(2, "0");
    if (!zerohour) {
      return `${year}-${month}-${day}T${new Date()
        .getHours()
        .toString()
        .padStart(2, "0")}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }
    return `${year}-${month}-${day}T00:00:00`;
  };

  return {
    start: formatDate(startDate, true),
    end: formatDate(endDate),
  };
}
const dates = getStartAndEndOfWeek();

const MessageRecord = () => {
  const [stateModal, setStateModal] = useState({
    modal: false,
  });
  const toggle = () => {
    setStateModal({
      modal: !stateModal.modal,
    });
  };

  const [since, setSince] = useState(dates.start);
  const [until, setUntil] = useState(dates.end);
  const [errors, setErrors] = useState([]);


  const [milliseconds, setMillisecond] = useState(1);
  const [massiveCount, setMassiveCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [massiveCountRead, setMassiveCountRead] = useState(0);
  const [templateAssessorCountRead, setTemplateAssessorCountRead] = useState(0);
  const [attendedData, setAttendedData] = useState({});
  const [loadingAttended, setLoadingAttended] = useState(false);
  const [messageType, setMessageType] = useState(false);
  const [messageStatus, setMessageStatus] = useState(false);
  const [messageStatusMassive, setMessageStatusMassive] = useState(false);
  const [messageStatusTemplateAssessor, setMessageStatusTemplateAssessor] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateAssessorsList, setTemplateAssessorsList] = useState([]);
  const [selectedTemplateAssessor, setSelectedTemplateAssessor] = useState(null);
  const [isLoadingMassiveData] = useState(false);

  const { paginationPages, setCurrentPage, showedData } = usePagination({ data: errors, showRows: 10 });


  const formatMessageRecord = (data) => {
    return data.map((obj) => {
      return {
        "ID mensaje": obj.id,
        "Grupo de conversacion": obj.conversationGroupId,
        "ID conversacion": obj.idConversacion,
        Agente: obj.owner,
        Teléfono: obj.identificatorCode,
        Línea: obj.line,
        "Nombre Línea": obj.lineName,
        Plantilla: obj.plantilla,
        Tipo: formatType(obj.type),
        Texto: obj.text,
        Bot: obj.bot,
        Fecha: getDateByTime(obj.date),
        Error: !obj.status_message?"": (obj.status_message === "failed"?"Si":"No"),
        "Código de error": obj.code,
        Enviado: !obj.status_message?"": (obj.status_message === "sent" || obj.status_message === "delivered" || obj.status_message === "read" ? "Si": "No"),
        Recibido: !obj.status_message?"": (obj.status_message === "delivered" || obj.status_message === "read" ? "Si": "No"),
        Leido: !obj.status_message?"": (obj.status_message === "read" ? "Si": "No"),
        

      };
    });
  };
  const formatStatus = (status) => {
    if (status === "sent") {
      return "Enviado";
    } else if (status === "delivered") {
      return "Recibido";
    } else if (status === "read") {
      return "Leido";
    } else if (status === "failed") {
      return "Fallo al enviar";
    } else {
      return "";
    }
  };
  const formatType = (type) => {
    if (type.toLowerCase().includes("user")) {
      return "Agente";
    } else if (type.toLowerCase().includes("record")) {
      return "Grabación de llamada";
    } else if (type.toLowerCase().includes("bot")) {
      return "Bot";
    } else if (type.toLowerCase() === "template") {
      return "Plantilla";
    } else if (type.toLowerCase() === "template_massive") {
      return "Plantilla de envio masivo";
    } else if (type.toLowerCase().includes("system")) {
      return "Sistema";
    } else {
      return "Contacto";
    }
  };
  const updateFilterByDate = (callback) => {
    if (milliseconds > 0 && milliseconds <= 2678500000) {
      setSelectedTemplate(null)
      // setShowedMessageRecords(null);
      /* Utils.Petition.get('/call-records', (response)=>{ */
      Utils.Petition.get(
        `/message-records/detail/start/${since}/end/${until}`,
        (response) => {
          // setShowedMessageRecords(response.data);
          callback(response.data);
        }
      );
    }
  };

  const loadMessageData = (callback) => {
    if (milliseconds > 0 && milliseconds <= 2678500000) {
      setLoadingAttended(true);
      setSelectedTemplate(null)
      Utils.Petition.get(
        `/message-records/data/start/${since}/end/${until}`,
        (response) => {
          controller = null;
          setLoadingAttended(false);

          // setShowedMessageRecords(response.data);
          // callback(response.data);
          let message_type = [];
          let message_status = [];
          let message_status_massive = [];
          let message_status_template_assessor = [];
          let totalMessages=0
          let cantMassive=0
          let cantMassiveRead=0
          let cantTemplateAssessorRead=0
          
         
          setAttendedData(response.data);
          if(response.data.length){

            // Cantidad de mensajes masivos respondidos
            for (let index = 0; index < response.data[0]?.contactAndTemplateCounts.length; index++) {
              const element = response.data[0]?.contactAndTemplateCounts[index];
              cantMassiveRead=cantMassiveRead+parseInt(element.count)
            }
            // Cantidad de mensajes de platilla de asesores respondidos
            for (let index = 0; index < response.data[0]?.contactAndTemplateAsesorCounts.length; index++) {
              const element = response.data[0]?.contactAndTemplateAsesorCounts[index];
              cantTemplateAssessorRead=cantTemplateAssessorRead+parseInt(element.count)
            }
            // Data de mensajes
            for (let index = 0; index < response.data[0]?.messageTypeCounts?.length; index++) {
              const dataType = response.data[0]?.messageTypeCounts[index];
              console.log(dataType)
              let cant=0
              for (let c = 0; c < dataType?.templates.length; c++) {
                const data = dataType?.templates[c];
                console.log(data)
                cant=cant+parseInt(data.totalMessages)
                if (dataType.type === "Template_massive") {
                  cantMassive=cantMassive+parseInt(data.totalMessages)
                  for (let status = 0; status < data.statuses?.length; status++) {
                    const dataStatus = data.statuses[status];
                    if(dataStatus?.status && dataStatus?.status !== ""){
                      if (
                        !message_status_massive.find(
                          (e) => e.key === formatStatus(dataStatus.status)
                        )
                      ) {
                        message_status_massive.push({
                          key: formatStatus(dataStatus.status),
                          value: dataStatus.count,
                        });
                      } else {
                        let typeCont = message_status_massive.find(
                          (e) => e.key === formatStatus(dataStatus.status)
                        ).value;
                        message_status_massive.find((e) => e.key === formatStatus(dataStatus.status)).value = typeCont + dataStatus.count;
                      }
                      
                    }
                  }
                }else 
                if(dataType.type === "Template"){
                  
                  for (let status = 0; status < data.statuses?.length; status++) {
                    const dataStatus = data.statuses[status];
                    if(dataStatus?.status && dataStatus?.status !== ""){
                      if (
                        !message_status_template_assessor.find(
                          (e) => e.key === formatStatus(dataStatus.status)
                        )
                      ) {
                        message_status_template_assessor.push({
                          key: formatStatus(dataStatus.status),
                          value: dataStatus.count,
                        });
                      } else {
                        let typeCont = message_status_template_assessor.find(
                          (e) => e.key === formatStatus(dataStatus.status)
                        ).value;
                        message_status_template_assessor.find((e) => e.key === formatStatus(dataStatus.status)).value = typeCont + dataStatus.count;
                      }
                      
                    }
                  }
                }
              }
              if (
                !message_type.find(
                  (e) => e.key === formatType(dataType.type)
                )
              ) {
                message_type.push({ key: formatType(dataType.type), value: cant });
  
              } else {
                let typeConts = message_type.find(
                  (e) => e.key === formatType(dataType.type)
                ).value;
                message_type.find(
                  (e) => e.key === formatType(dataType.type)
                ).value = typeConts + cant;
              }
              //Data total de conversaciones
              totalMessages=totalMessages+parseInt(cant)
            }
            //Data de estados
            let dataError=[]
            for (let index = 0; index < response.data[0]?.statusCounts?.length; index++) {
              const dataStatus = response.data[0]?.statusCounts[index];
              console.log(dataStatus)
              if(dataStatus?.status && dataStatus?.status != "")  message_status.push({ key: formatStatus(dataStatus.status), value: dataStatus.count });
            }
            const options = response.templates.map((template) => ({
              value: template,
              label: template,
            }));
            const options_template_assessors = response.templates_assessors.map((template) => ({
              value: template,
              label: template,
            }));
            setTemplateList(options);
            setTemplateAssessorsList(options_template_assessors)
            setMassiveCount(cantMassive);
            setMessageCount(totalMessages);
            setMessageType(message_type);
            setMessageStatus(message_status);
            setMessageStatusMassive(message_status_massive);
            setMessageStatusTemplateAssessor(message_status_template_assessor)
            setMassiveCountRead(cantMassiveRead)
            setTemplateAssessorCountRead(cantTemplateAssessorRead)
          }
        },
        (err) => {
          console.error("Error en la solicitud:", err);
          // Resetear el controlador incluso en caso de error
          controller = null;
        },
        () => {
          console.log("Solicitud completada");
        },
        controller
      );
    }
  };
  const handleDatesFilter = (dates) => {
    setSince(dates.since);
    setUntil(dates.until);
    setMillisecond(dates.until - dates.since);
  };
  
  const handleSelectTemplateAssessor = async (option) => {
    console.log("option",option)
    let filteredData=[]
    if(!option?.value){
      setSelectedTemplateAssessor(null);
      let cantTemplateAssessorRead=0
      for (let index = 0; index < attendedData[0]?.contactAndTemplateAsesorCounts.length; index++) {
        const element = attendedData[0]?.contactAndTemplateAsesorCounts[index];
        cantTemplateAssessorRead=cantTemplateAssessorRead+parseInt(element.count)
      }
      filteredData= attendedData[0]?.messageTypeCounts.filter(
        e=>e.type.toLowerCase() === "template")[0]?.templates;
      setTemplateAssessorCountRead(cantTemplateAssessorRead)
    }else{
      setSelectedTemplateAssessor(option);
      let filterTemplateAssessorRead =  attendedData[0]?.contactAndTemplateAsesorCounts?.filter(temp=>temp.template_name==option.value);
      console.log("filterasdas",attendedData[0]?.templateAssessorCountRead)
      setTemplateAssessorCountRead(filterTemplateAssessorRead?.length?filterTemplateAssessorRead[0]?.count:0)
      filteredData = attendedData[0]?.messageTypeCounts?.filter(
        e=>e.type.toLowerCase() === "template")[0]?.templates.filter(temp=>temp.template_name==option.value);
    }
    let message_status_template_assessor = [];
    for (let index = 0; index < filteredData.length; index++) {
      const data = filteredData[index];
      for (let status = 0; status < data.statuses?.length; status++) {
        const dataStatus = data.statuses[status];
        if(dataStatus?.status && dataStatus?.status != ""){
          if (
            !message_status_template_assessor.find(
              (e) => e.key === formatStatus(dataStatus.status)
            )
          ) {
            message_status_template_assessor.push({
              key: formatStatus(dataStatus.status),
              value: dataStatus.count,
            });
          } else {
            let typeCont = message_status_template_assessor.find(
              (e) => e.key === formatStatus(dataStatus.status)
            ).value;
            message_status_template_assessor.find((e) => e.key === formatStatus(dataStatus.status)).value = typeCont + dataStatus.count;
          }
          
        }
      }
    }
    setMessageStatusTemplateAssessor(message_status_template_assessor);
  };
  const handleSelectTemplate = async (option) => {
    console.log(option)
    setSelectedTemplate(option);
    console.log("attendedData",attendedData)
    const filteredData = attendedData[0]?.messageTypeCounts.filter(
      e=>e.type === "Template_massive")[0]?.templates.filter(temp=>temp.template_name==option.value);
    let filterMassiveRead =  attendedData[0]?.contactAndTemplateCounts?.filter(temp=>temp.template_name==option.value);
    setMassiveCountRead(filterMassiveRead?.length?filterMassiveRead[0].count:0)
    console.log("DATA FILTRO MSIVO",filterMassiveRead)
    let message_status_massive = [];
    for (let index = 0; index < filteredData.length; index++) {
      const data = filteredData[index];
      for (let status = 0; status < data.statuses?.length; status++) {
        const dataStatus = data.statuses[status];
        if(dataStatus?.status && dataStatus?.status != ""){
          if (
            !message_status_massive.find(
              (e) => e.key === formatStatus(dataStatus.status)
            )
          ) {
            message_status_massive.push({
              key: formatStatus(dataStatus.status),
              value: dataStatus.count,
            });
          } else {
            let typeCont = message_status_massive.find(
              (e) => e.key === formatStatus(dataStatus.status)
            ).value;
            message_status_massive.find((e) => e.key === formatStatus(dataStatus.status)).value = typeCont + dataStatus.count;
          }
          
        }
      }
    }
    setMessageStatusMassive(message_status_massive);
  };

  const handleClearTemplateFilter = async () => {
    setSelectedTemplate(null);
    const filteredData = attendedData[0]?.messageTypeCounts.filter(
      e=>e.type === "Template_massive")[0]?.templates
    console.log("DATA FILTRO 2",filteredData)
    let cantMassiveRead=0

    for (let index = 0; index < attendedData[0]?.contactAndTemplateCounts.length; index++) {
      const element = attendedData[0]?.contactAndTemplateCounts[index];
      cantMassiveRead=cantMassiveRead+parseInt(element.count)
    }
    setMassiveCountRead(cantMassiveRead)

    let message_status_massive = [];
    for (let index = 0; index < filteredData.length; index++) {
      const data = filteredData[index];
      for (let status = 0; status < data.statuses?.length; status++) {
        const dataStatus = data.statuses[status];
        if(dataStatus?.status && dataStatus?.status != ""){
          if (
            !message_status_massive.find(
              (e) => e.key === formatStatus(dataStatus.status)
            )
          ) {
            message_status_massive.push({
              key: formatStatus(dataStatus.status),
              value: dataStatus.count,
            });
          } else {
            let typeCont = message_status_massive.find(
              (e) => e.key === formatStatus(dataStatus.status)
            ).value;
            message_status_massive.find((e) => e.key === formatStatus(dataStatus.status)).value = typeCont + dataStatus.count;
          }
          
        }
      }
    }
    setMessageStatusMassive(message_status_massive);
  };
  const handleModal = (type) =>{
    console.log("attendedData",attendedData)
    if(type=="global"){

      const dataErrors=attendedData[0]?.errorCounts??[]
      let arrayErrors=[]
      for (let index = 0; index < dataErrors.length; index++) {
        const element = dataErrors[index];
        arrayErrors.push({error:element.error??"Sin información",count:element.count})
      }
      setErrors(arrayErrors)
    }else
    if(type.toLowerCase().includes("template")){
      let filteredData=[]
      let options=type=="Template"?selectedTemplateAssessor:selectedTemplate
      
      if(!options){
        filteredData= attendedData.length?attendedData[0]?.errorTypeCounts.filter(
          e=>e.type === type)[0]?.templates:[];
      }else{
        filteredData = attendedData.length?attendedData[0]?.errorTypeCounts?.filter(
          e=>e.type === type)[0]?.templates.filter(temp=>temp.template_name==options.value):[];
      }
      if(filteredData){

        let message_status_template_assessor_error = [];
        for (let index = 0; index < filteredData.length; index++) {
          const data = filteredData[index];
          for (let err = 0; err < data.errors?.length; err++) {
            const dataError= data.errors[err];
            let messageError=dataError?.code??"Sin información"
            if (
              !message_status_template_assessor_error.find(
                (e) => e.error === messageError
              )
            ) {
              message_status_template_assessor_error.push({
                error: messageError,
                count: dataError.count,
              });
            } else {
              let typeCont = message_status_template_assessor_error.find(
                (e) => e.error === messageError
              ).count;
              message_status_template_assessor_error.find((e) => e.error === messageError).count = typeCont + dataError.count;
            }
          }
        }
        setErrors(message_status_template_assessor_error)

      }else{
        setErrors([])
      }
    }
    toggle()
    // setErrorsTemplate
    // setErrorsTemplateMassive
  }
  return (
    
    <section className="mx-4 mb-4">
      <MDBContainer className="mb-12">
        <MDBModal isOpen={stateModal.modal} toggle={toggle} size="lg">
          <MDBModalHeader toggle={toggle}>
            Errores de mensajes
          </MDBModalHeader>
          <MDBModalBody>
            <TableContainer>
              <PaginationTable
                headers={[ "Error", "Cantidad"]}
                withoutRight={false}
                content={showedData}
                pages={paginationPages}
                setPages={setCurrentPage}
                row={MessageRecordRow}
              />
            <div style={{padding:"10px"}}>Para mas información de errores <a href="https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes" target="_blank">clic aquí.</a></div>

            </TableContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={toggle}>
              Cancelar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <div className="d-flex">
        <FragmentsIcon />
        <Title>Registro de mensajes</Title>
      </div>
      {/* <Description>Estado del plan ({months[new Date().getMonth()]}): {totalMinutes} de {totalPlan} minutes</Description> */}
      
      <div>
        <DateFilter
          setDateFilter={handleDatesFilter}
          since={since}
          until={until}
        />
        {milliseconds > 0 && milliseconds <= 604806000 && (
          <>
            <button
              className="btn btn-orange d-inline  btn-sm"
              onClick={loadMessageData}
              disabled={loadingAttended ? true : false}
            >
              Cargar datos
            </button>
            {
              !loadingAttended &&
              <ExcelExport
                asyncData={true}
                handleData={updateFilterByDate}
                formatData={formatMessageRecord}
                fileName="Resumen de mensajes"
              />
            }
          </>
        )}
        {Object.keys(attendedData).length > 0 && (
              <div className="container">
                

               
              </div>
            )}
      </div>

      {(milliseconds <= 0 || milliseconds > 604806000) && (
        <MDBAlert color="danger">
          La fecha de inicio debe ser menor que la fecha de fin y no puede
          superar mas de una semana.
        </MDBAlert>
      )}
      <MDBRow className="mt-4">
        <MDBCol>
          {Object.keys(attendedData).length > 0 && (
            <h5>Cantidad de mensajes</h5>
          )}
          {!loadingAttended && Object.keys(attendedData).length > 0 && (
            <MDBCard>
              <MDBCardBody>
                <div className="text-center">
                  <strong>{messageCount}</strong>
                  <br />
                  Mesajes totales
                </div>
              </MDBCardBody>
            </MDBCard>
          )}
          {loadingAttended && <Loading />}
        </MDBCol>
        <MDBCol>
          {Object.keys(attendedData).length > 0 && (
            <h5>Cantidad de mensajes (Envios masivos)</h5>
          )}
          {!loadingAttended && Object.keys(attendedData).length > 0 && (
            <MDBCard>
              <MDBCardBody>
                <div className="text-center">
                  <strong>{massiveCount}</strong>
                  <br />
                  Mesajes Masivos
                </div>
              </MDBCardBody>
            </MDBCard>
          )}
          {loadingAttended && <Loading />}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-4">
        
        <MDBCol md="4" >
          {Object.keys(attendedData).length > 0 && <h5>Estados de mensajes (general)</h5>}
          {!loadingAttended && Object.keys(attendedData).length > 0 && (
            <MDBCard>
              <MDBCardBody>
                <div className="text-center">
                  <GraficaDonut dataItems={messageStatus} title={[""]} labelPosition="top"/>
                  <button
                      className="btn btn-light d-inline  btn-sm"
                      onClick={(e)=>handleModal("global")}
                      disabled={loadingAttended ? true : false}
                    >
                      Detalle de errores
                  </button>
                </div>
              </MDBCardBody>
            </MDBCard>
          )}
        </MDBCol>
        <MDBCol md="4" >
          {Object.keys(attendedData).length > 0 && (
            <>
              <h5>Estados de plantillas masivas</h5>
            </>
          )}
          {isLoadingMassiveData && <Loading />}
          {!loadingAttended &&
            !isLoadingMassiveData &&
            Object.keys(attendedData).length > 0 && (
              <MDBCard>
                <MDBCardBody>
                  <div className="text-center">
                    <Select
                      options={templateList}
                      value={selectedTemplate}
                      isDisabled={loadingAttended}
                      isSearchable={true}
                      onChange={handleSelectTemplate}
                      placeholder="Filtrar envíos masivos por plantilla"
                    />
                    {selectedTemplate && (
                      <button
                        className="btn btn-orange d-inline  btn-sm"
                        onClick={handleClearTemplateFilter}
                        disabled={loadingAttended ? true : false}
                      >
                        Ver los envíos de todas las plantillas
                      </button>
                    )}
                  </div>
                  <div className="text-center">
                    <GraficaDonut dataItems={messageStatusMassive} title={[""]} labelPosition="top" subtitle={"Mensajes respondidos: "+massiveCountRead}/>
                    <button
                        className="btn btn-light d-inline  btn-sm"
                        onClick={(e)=>handleModal("Template_massive")}
                        disabled={loadingAttended ? true : false}
                      >
                        Detalle de errores
                    </button>
                  </div>
                </MDBCardBody>
              </MDBCard>
            )}
        </MDBCol>
        <MDBCol md="4" >
          {Object.keys(attendedData).length > 0 && (
            <>
              <h5>Estados de plantillas asesores</h5>
            </>
          )}
          {isLoadingMassiveData && <Loading />}
          {!loadingAttended &&
            !isLoadingMassiveData &&
            Object.keys(attendedData).length > 0 && (
              <MDBCard>
                <MDBCardBody>
                  <div className="text-center">
                    <Select
                      options={templateAssessorsList}
                      value={selectedTemplateAssessor}
                      isDisabled={loadingAttended}
                      isSearchable={true}
                      onChange={handleSelectTemplateAssessor}
                      placeholder="Filtrar envíos por plantilla (asesores)"
                    />
                    {selectedTemplateAssessor && (
                      <button
                        className="btn btn-orange d-inline  btn-sm"
                        onClick={handleSelectTemplateAssessor}
                        disabled={loadingAttended ? true : false}
                      >
                        Ver los envíos de todas las plantillas
                      </button>
                    )}
                  </div>
                  <div className="text-center">
                    <GraficaDonut dataItems={messageStatusTemplateAssessor} title={[""]} labelPosition="top" subtitle={"Mensajes respondidos: "+templateAssessorCountRead}/>
                    <button
                          className="btn btn-light d-inline  btn-sm"
                          onClick={(e)=>handleModal("Template")}
                        disabled={loadingAttended ? true : false}
                      >
                        Detalle de errores
                    </button>
                  </div>
                </MDBCardBody>
              </MDBCard>
            )}
        </MDBCol>
        <MDBCol md="4" >
          {Object.keys(attendedData).length > 0 && (
            <h5>Tipos de conversacion por mensaje</h5>
          )}
          {!loadingAttended && Object.keys(attendedData).length > 0 && (
            <MDBCard>
              <MDBCardBody>
                <GraficaDonut dataItems={messageType} title={[""]} labelPosition="top"/>
              </MDBCardBody>
            </MDBCard>
          )}
        </MDBCol>
      </MDBRow>
    </section>
  );
};
export default MessageRecord;
