import React, { useState } from 'react';
import Utils from '../../../utils';
const BlackListForm = ({refreshData}) => {
    const [phone, setPhone] = useState('');
    const isValidForm = () => {
        return (/^\+\d{10,}$/).test(phone);
    }
    const handleCreate = (event) => {
        event.preventDefault();
        const endpoint = '/black-list/create';
        Utils.Petition.post(endpoint, {phone}, (_)=>{
            Utils.Petition.logs({data: {phone}, endpoint, type: "Create", register: "Lista Negra: Agregar Numero" })
            refreshData();
        })
    }
    return (
        <form onSubmit={handleCreate} className='p-4 bg-white mb-4' style={{ border: "solid 1px var(--border-color)", borderRadius: "16px" }}>
    
            <h6 className='mb-4 text-center' style={{ fontWeight: 500 }}>Agrega nuevo número</h6>
    
            <div className="form-group w-100">
            <label htmlFor="phone" className='font-weight-normal'>Teléfono</label>
            <input
                className='form-control'
                type="text"
                name='phone'
                id='phone'
                placeholder='Ejm: +573012345678'
                value={phone}
                onChange={(event)=> setPhone(event.target.value)}
                required
            />
            </div>
            <button
                type='submit'
                className='btn btn-orange mx-0 w-100'
                disabled={!isValidForm()}
            >
                Agregar número de teléfono
      </button>
        </form>
    )
}
export default BlackListForm;