import React, { useState } from "react";
import ArrowDownIcon from "../../../components/icons/arrows/ArrowDownIcon";
import { SectionContainer, SectionContent, SectionTitle } from "../styles";

const DetailTemplate = ({section, name}) => {
    const [active, setActive] = useState(false);
    return (
        <SectionContainer onClick={() => setActive(!active)}>
            <SectionTitle active={active}>
                {name}<ArrowDownIcon className="mx-1" />
            </SectionTitle>
            <SectionContent active={active}>
                <div>
                    {section.format==="IMAGE" && <span className="badge badge-light">Imagen</span>}
                    {section.format==="VIDEO" && <span className="badge badge-light">Video</span>} 
                    {section.format==="DOCUMENT" && <span className="badge badge-light">Documento</span>} 
                    {
                        section.format==="TEXT" && 
                        <div>
                            <div>{section.text}</div>
                            <hr />
                            <div>
                                {section?.examples?.length>0 && 'Muestras (ejemplos): ' }
                                {
                                    section?.examples?.map((example, i)=>
                                        <div key={i}><span className="badge badge-light">{i+1} - {example}</span>&nbsp;&nbsp;</div>
                                    )

                                }
                            </div>
                        </div>
                    } 

                </div>
            </SectionContent>
        </SectionContainer>
    )
}
export default DetailTemplate;