import React from 'react'
import useCampaignForm from '../../../hooks/useCampaignForm';
import SelectGroup from '../../../components/selects/SelectGroup';
import { useContext } from 'react';
import { GroupsContext } from '../../../Context/Groups';
import SelectSubgroup from '../../../components/selects/SelectSubgroup';
import BotIcon from "../../../assets/Images/botIcon.svg";
import GroupICon from "../../../assets/Images/groupIcon.svg";
import SelectBot from './SelectBot';
import { isValidDateToISO } from '../../../utils/Times';

const EditableRow = ({ name, group, subgroup, isExpiration, expiration, setEditable, bot, to, updateCampaign }) => {

  const { fields, handleChange, handleChangeIsCampaignExpiration } = useCampaignForm(name, isExpiration, expiration, to, { group, subgroup, bot });
  const { isLoading, groupsKeys, subgroupsKeysByGroupKey } = useContext(GroupsContext);

  const handleClick = () => {
    // if (!Object.values(fields).includes("")) {

      let campaign = {
        name: fields['campaign-name'],
        isExpiration: fields['is-campaign-expiration']==='yes' ? true : false
      }

      if (fields['campaign-redirection'] === "bot") {
        campaign.bot = fields['campaign-bot'];
      } else if (fields['campaign-redirection'] === "group") {
        campaign.group = fields['campaign-group'];
        campaign.subgroup = fields['campaign-subgroup'];
      }

      if (campaign.isExpiration) {
        campaign.expiration = fields['campaign-expiration'];
      }

      updateCampaign(campaign, () => setEditable(false));
    // }
  }

  return (
    <tr>
      <td>
        <input
          className='form-control form-control-sm'
          type="text"
          name={'campaign-name'}
          onChange={handleChange}
          value={fields['campaign-name']}
        />
      </td>
      <td> <img src={(to === "bot") ? BotIcon : GroupICon} alt="" /> </td>

      {
        fields['campaign-redirection'] === "group" && (
          <>
            <td>
              <SelectGroup
                name={'campaign-group'}
                field={fields["campaign-group"]}
                handleChange={handleChange}
                groupsKeys={groupsKeys}
                isLoading={isLoading}
                mode={"update"}
              />
            </td>
            <td>
              <SelectSubgroup
                name={"campaign-subgroup"}
                field={fields["campaign-subgroup"]}
                group={fields['campaign-group']}
                handleChange={handleChange}
                isLoading={isLoading}
                subgroupsKeysByGroupKey={subgroupsKeysByGroupKey}
                mode={"update"}
              />
            </td>
          </>
        )
      }

      {
        fields['campaign-redirection'] === "bot" && (
          <td colSpan={2}>
            <SelectBot
              name='campaign-bot'
              id='campaign-bot'
              onChange={handleChange}
              value={fields["campaign-bot"]}
            />
          </td>
        )
      }

      <td>
        <div className='form-inline' style={{width:'185px'}}>
          <input type="checkbox" className='mr-2' checked={fields['is-campaign-expiration']==='yes'} onChange={handleChangeIsCampaignExpiration} />
          <input
            className='form-control form-control-sm'
            type="date"
            readOnly={fields['is-campaign-expiration']==='no'}
            name={'campaign-expiration'}
            onChange={handleChange}
            value={(()=>{
              if(isValidDateToISO(fields["campaign-expiration"])){
              console.log(new Date(fields["campaign-expiration"]).toISOString());
                return new Date(fields["campaign-expiration"])
                  .toISOString()
                  .substring(0, 10);
              }else{
                
                return new Date().toISOString()
              }
            })()}
          />
        </div>
      </td>
      <td>
        <div className='d-flex'>
          <button
            className='btn btn-sm w-100 px-2 btn-danger'
            style={{ width: 'max-content !important' }}
            onClick={handleClick}
          >
            Actualizar
          </button>

          <button
            className='btn btn-sm px-2 btn-orange'
            onClick={() => setEditable(false)}
          >
            X
          </button>

        </div>
      </td>
    </tr>
  )
}

export default EditableRow;