export const getSecondMinuteHourBySecond = (seconds) => {
  let second = seconds % 60;
  let minutes = (seconds / 60) % 60;
  let hour = Math.floor(seconds / 60 / 60);
  return `${parseInt(hour).toString().padStart(2, '0')} : ${parseInt(minutes).toString().padStart(2, '0')} : ${parseInt(
    second).toString().padStart(2, '0')} `;
}

export const getSecondMinuteBySecond = (seconds) => {
  if(seconds < 0) return '00 : 00';
  let second = seconds % 60;
  let minutes = (seconds / 60);
  return `${parseInt(minutes).toString().padStart(2, '0')} : ${parseInt(
    second).toString().padStart(2, '0')} `;
}

export const getHourByTime = (time) => {
  return new Date(Number(time)).toLocaleTimeString("es", { hour: '2-digit', minute: '2-digit' });
}

export const getDateByTime = (time) => {
  const date = new Date(Number(time));
  const dateString = date.toLocaleDateString("es");
  const timeString = date.toLocaleTimeString("es", { hour12: false, hour: '2-digit', minute: '2-digit' });
  return dateString === 'Invalid Date' ? 'No registrado' : `${dateString} ${timeString}`;
}

export const getMinutesBetweenDates = (startDate, endDate) => {
  let diffMs = (startDate - endDate);
  let diffDays = Math.floor(diffMs / 86400000);
  let diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

  return [diffDays, diffHrs, diffMins];

}

export const getYMD = (date) => {
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + (date.getDate())).slice(-2);
  var year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export const isValidDateToISO = (possibleDate)=>{
  let fecha = new Date(possibleDate);
 
  if (isNaN(fecha.getTime())) {
  
    return false;
  } else {
 
    try {
      fecha.toISOString().substring(0, 10);
   
      return true;
    } catch (error) {
     
      return false;
    }
  }
}