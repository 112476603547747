import React, { createContext, useState, useEffect } from 'react';
import useGet from '../hooks/useGet';

const initialState = {
  groups: null,
  groupsKeys: [],
  subgroupsKeysByGroupKey: {},
  isLoading: false,
  error: null,
  setData: (state) => { }
}

export const GroupsContext = createContext(initialState);

const GroupsContextProvider = ({ children }) => {
  const { data, isLoading, error, setData } = useGet({ route: "/groups/get" });
  const [groups, setGroups] = useState([]);
  const [subgroups, setSubgroups] = useState({});

  useEffect(() => {
    if (data) {
      let subgroups = {};
      let groups = Object.keys(data);
      groups.forEach(group => {
        subgroups[group] = Object.keys(data[group]);
      });

      setGroups(groups);
      setSubgroups(subgroups);
    }
  }, [data]);

  return (
    <GroupsContext.Provider value={{
      groups: data,
      groupsKeys: groups,
      subgroupsKeysByGroupKey: subgroups,
      isLoading,
      error,
      setData
    }}>
      {children}
    </GroupsContext.Provider>
  )
}

export default GroupsContextProvider;
