import React, { useEffect, useState } from 'react';
import FragmentsIcon from "../../components/icons/pages/FragmentsIcon";
import styled from "styled-components";
import Utils from '../../utils';
import DateFilter from '../Conversations/components/filters/DateFilter';
import { MDBAlert, MDBRow, MDBCol, MDBCard, MDBCardBody,MDBContainer } from 'mdbreact';
import Loading from '../../components/Loading/Loading';
import GraficaPie from '../../components/Charts/GraficaPie';
import GraficaDoughnut from '../../components/Charts/GraficaDoughnut';
import GraficaBarVertical from '../../components/Charts/GraficaBarVertical';
import { Description } from "../Conversations";
import ExcelExport from '../../components/ExcelExport';

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;
// export const Description = styled.p`
//   color: #fa7820;
//   font-weight: 500;
// `;
const months =['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre', 'Diciembre'];

function getStartAndEndOfWeek() {
  const today = new Date();
  
  // Obtiene el día actual de la semana (0 para domingo, 1 para lunes, etc.)
  const dayOfWeek = today.getDay();
  
  // Calcular la fecha de inicio (lunes)
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - ((dayOfWeek + 6) % 7));
  
  // Calcular la fecha de fin (domingo)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  
  // Formatear las fechas en el formato YYYY-MM-DD
  const formatDate = (date, zerohour=false) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
    const day = String(date.getDate()).padStart(2, '0');
    if(!zerohour){
      return `${year}-${month}-${day}T${(new Date().getHours()).toString().padStart(2, '0')}:${(new Date().getMinutes()).toString().padStart(2, '0')}`;
    }
    return `${year}-${month}-${day}T00:00:00`;
  };
  
  return {
    start: formatDate(startDate, true),
    end: formatDate(endDate)
  };
}
const dates = getStartAndEndOfWeek();

const ConversationRecords = ()=>{
  const [totalConversations, setTotalConversations] = useState(0);
  const [totalPlan, setTotalPLan] = useState(0);
  const [assignationsPerHour,setAssignationsPerHour]=useState([])
  
  const [since, setSince] = useState(dates.start);
  const [until, setUntil] = useState(dates.end);
  const [group, setGroup] = useState('*');
  const [subgroup, setSubgroup] = useState('*');
  const [groups, setGroups] = useState({'Cargando...':{'Cargando...':''}});
  const [milliseconds, setMillisecond] = useState(1);
  const [attendedData, setAttendedData] = useState({});
  const [loadingAttended, setLoadingAttended] = useState(false);
  const [conversationsLength, setConversationsLength] = useState(0);
  const [conversationsTypes, setConversationsTypes] = useState([]);
  const [conversationsStates, setConversationsStates] = useState([]);
  const [conversationsAttendedBot, setConversationsAttendedBot] = useState([]);
  const [assessorSended, setAssessorSended] = useState([]);
  const [attended, setAttended] = useState([]);
  const [noAtended, setNoAtended] = useState([]);
  const [noAvailable, setNoAvailable] = useState([]);
  const [outHour, setOutHour] = useState([]);
    useEffect(()=>{
      Utils.Petition.get('/chat/count-message-whatsapp', (response)=>{
          console.log(response)
          setTotalConversations(response.count);
          setTotalPLan(response.plan);
      })
    }, []);
    const getHourDate = (date) =>{
      return new Date(date).getHours()
    }
    const loadConversationData = ()=>{
      if(milliseconds > 0 && milliseconds <= 2678500000){
        setLoadingAttended(true);
        setAttendedData({})
        Utils.Petition.get(`/conversation-records/attended/start/${since}/end/${until}/group/${group}/subgroup/${subgroup}`, (response)=>{
          setAttendedData(response.data);
          setLoadingAttended(false);
          let typeConversations=[{key:"Envio Masivo",value:0},{key:"Contacto",value:0},{key:"Asesor",value:0}]
          let conversationsStates=[]
          let conversationAssign=[];
          let conversationsAttendedBot = [];
          if(response.data.length>0){
            let dataConversations=response.data[0]
            setConversationsLength(dataConversations?.conversationCounts?.length)
            setAttended(dataConversations?.attended?.length)
            setAssessorSended(dataConversations?.assessorSended?.length)
            setNoAtended(dataConversations?.noAtended?.length)
            setNoAvailable(dataConversations?.noAvailable?.length)
            setOutHour(dataConversations?.outHour?.length)
            for (let index = 0; index < dataConversations?.firstMessages?.length; index++) {
              const element = dataConversations.firstMessages[index];
              if(element.firstMessage.toLowerCase().includes("contact"))typeConversations.find(e=>e.key === "Contacto").value=changeCant("Contacto",typeConversations,element.count)
              else if(element.firstMessage.toLowerCase() == "template")typeConversations.find(e=>e.key === "Asesor").value=changeCant("Asesor",typeConversations,element.count)
              else if(element.firstMessage.toLowerCase() == "template_massive"|| element.firstMessage.toLowerCase().includes("bot")  || element.firstMessage.toLowerCase().includes("system"))typeConversations.find(e=>e.key === "Envio Masivo").value=changeCant("Envio Masivo",typeConversations,element.count)
              
            }
            let timezone = -(new Date().getTimezoneOffset() / 60);
            for (let index = 0; index < dataConversations.firstAssign.length; index++) {
              const element = dataConversations.firstAssign[index];
              if(timezone<0 && element.hour<(timezone*-1)){
                element.hour=24-element.hour;
              }else{
                element.hour=(element.hour+timezone)%24;
              }
              conversationAssign.push({key:element.hour,value:element.count})
            }
            conversationAssign.sort((a, b) => a.key - b.key);
            console.log({conversationAssign});
            if(conversationAssign[0].key===0 && conversationAssign[conversationAssign.length-1].key===24){
              conversationAssign[0].value+=conversationAssign[conversationAssign.length-1].value;
              conversationAssign.pop();

            }
            conversationAssign = conversationAssign.map(item => ({
              ...item,
              key: item.key.toString()
            }));

            for (let index = 0; index < dataConversations?.stateCounts?.length; index++) {
              const element = dataConversations.stateCounts[index];
              if(!conversationsStates.find(e=>e.key === formatState(element.status))){
                conversationsStates.push({key:formatState(element.status),value:element.count})
              }else{
                let typeCont=conversationsStates.find(e=>e.key === formatState(element.status)).value
                conversationsStates.find(e=>e.key === formatState(element.status)).value=typeCont+element.count
              }
            }                                              
            for (let index = 0; index < dataConversations?.conversationsAttendedBot?.length; index++) {
              const element = dataConversations.conversationsAttendedBot[index];
              if(!conversationsAttendedBot.find(e=>e.key === formatStateClose(element.status_close))){
                conversationsAttendedBot.push({key:formatStateClose(element.status_close),value:element.count})
              }else{
                let typeCont=conversationsAttendedBot.find(e=>e.key === formatStateClose(element.status_close)).value
                conversationsAttendedBot.find(e=>e.key === formatStateClose(element.status_close)).value=typeCont+element.count
              }
            }
            setConversationsTypes(typeConversations)
            setConversationsStates(conversationsStates);
            setConversationsAttendedBot(conversationsAttendedBot);
            setAssignationsPerHour(conversationAssign)
          }
          
        })
      }
    }
    const changeCant=(type,data,cant=1)=>{
      let typeCont=data.find(e=>e.key === type).value
      return data.find(e=>e.key === type).value=typeCont+cant
    }
    const handleGroupChange = (event)=>{
      setSubgroup('*');
      setGroup(event.target.value);
    }
    const handleSubgroupChange = (event)=>{
      setSubgroup(event.target.value);
    }
    const handleDatesFilter = (dates) => {
      setSince(dates.since);
      setUntil(dates.until);
      setMillisecond(dates.until - dates.since);
    };
    const formatState = (state)=>{
      if(state){
        if(state.toLowerCase().includes("open")) {
          return "Abiertas";
        } else if(state.toLowerCase().includes("close")) {
          return "Cerradas";
        } else if (state.toLowerCase().includes("bot")) {
          return "Bot";
        } else if (state.toLowerCase() == "nonstart") {
          return "Sin iniciar";
        } else if (state.toLowerCase() == "cola") {
          return "Cola";
        }
      }else{
        return "Sin iniciar"
      }

    }
    const formatStateClose = (state)=>{
      if(state){
        if(state.toLowerCase()===("bot_close")) {
          return "Finalizadas";
        } else if(state.toLowerCase()===("bot_close_automatic")) {
          return "Inactividad";
        }else{
          return "Otro"
        }
      }else{
        return "Sin cerrar"
      }

    }

    const formatExportData = (data)=>{
      const stateOptions = {close: "Cerrado",
        bot: "Bot",
        open: "Abierto",
        cola: "En cola",
        nonstart: "Sin iniciar",
      }
      const statusCloseOptions = {
        manual: "Manual",
        automatic_no_answer: "Automático - Sin respuesta",
        automatico: "Automático",
        automatic_outside_working_hours: "Automático - Fuera de horario",
        manual_administrator: "Manual administrador",
        manual_forced: 'Manual forzado',
        bot_close:'Bot',
        bot_close_automatic:'Automático - Sin respuesta bot',
      }
      try {
        return data.map((item,index)=>{

          let requiredProps = "Sin propiedades obligatorias"
          if(item.requiredPropsValues){
            requiredProps = ""
            let propsQuantity = Object.keys(item.requiredPropsValues)
            let propsCounter = 0
            for (let property in item.requiredPropsValues) {
              // Verificar si la propiedad es propia del objeto y no heredada
              if (item.requiredPropsValues.hasOwnProperty(property)) {
                // Concatenar la clave y el valor al resultado
                requiredProps += property + ':' + (item.requiredPropsValues[property] ? item.requiredPropsValues[property] : "Sin valor definido")
                propsCounter++
                if (propsCounter < propsQuantity && propsQuantity>1) {
                  requiredProps += ', ';
                }
              }
            }
          }

          let state = "Sin estado"
          if(item.state && stateOptions[item.state]){
            state = stateOptions[item.state]
          }

          let statusClose = "N/A"
          if(item.status_close && statusCloseOptions[item.status_close]){
            statusClose = statusCloseOptions[item.status_close]
          }

          const record =  {
            "Fecha Inicio Conversación": new Date(item.date).toISOString().replace('T', ' ').replace(/\.\d+Z$/, ''),
            "Estado": state,
            "Grupo": item.group ? item.group : "Sin grupo",
            "Subgrupo": item.subgroup ? item.subgroup : "Sin subgrupo",
            "Nombre línea": item.lineDetails?.name ? item.lineDetails.name :"Sin nombre de línea",
            "Número línea": item.lineDetails?.phone ? item.lineDetails.phone :"Sin número de línea",
            "Nombre del contacto": item.conversationGroup.name ? item.conversationGroup.name : "Sin nombre" ,
            "Email":item.conversationGroup.email ? item.conversationGroup.email : "Sin email",
            "Teléfono":item.conversationGroup.identificatorCode,
            "Agente": item.owner ? item.owner : "Sin agente",
            "Total de minutos de atención": item.first_assign_date ? ((item.dateUpdate - item.first_assign_date) / (1000 * 60)).toFixed(2)  : "No tiene",
            "Fecha asignación del asesor":  item.first_assign_date ? new Date(item.dateUpdate).toISOString().replace('T', ' ').replace(/\.\d+Z$/, '') : "Sin asignar",
            "Segundos de respuesta de asesor":  item.first_assign_date && item.first_message_assesor?  ((item.first_message_assesor - item.first_assign_date) / 1000 ).toFixed(2)  : "",
            "Tipo de cierre": statusClose,
            "Propiedades obligatorias" :requiredProps,
            "Tiene llamada(s)" : item.hasCall ? "Si" : "No",
            
        }
        //if(item.first_assign_date && item.first_message_assesor) console.log(item.first_message_assesor,  item.first_assign_date)
        return record
        })
      } catch (error) {
        console.log(error)
      }

    
    }
    const handleGetExportData = async (callback)=>{
      Utils.Petition.get(`/conversation-records/data/start/${since}/end/${until}/group/${group}/subgroup/${subgroup}`, (response)=>{
        //console.log(response)
        callback(response)
      })
    }
    useEffect(()=>{
      Utils.Petition.get(`/groups/get`, (response)=>{
        delete response.data.data_users;
        setGroups(response.data);
      })
    }, []);


    return (
        <section className='mx-4 mb-4'>
            <div className="d-flex">
                <div>
                    <div className="d-flex">
                      <FragmentsIcon />
                      <Title>Registro de conversaciones</Title>
                    </div>
                    <Description>Estado del plan ({months[new Date().getMonth()]}): {totalConversations} de {totalPlan} sesiones</Description>
                </div>
            </div>
         
          {/* <Description>Estado del plan ({months[new Date().getMonth()]}): {totalMinutes} de {totalPlan} minutes</Description> */}
          <div>
              <div className='d-flex'>
                <div>
                  <DateFilter
                      setDateFilter={handleDatesFilter}
                      since={since}
                      until={until}
                  />
                  
                </div>
                <div className='ml-3'>

                  <div className="d-flex align-items-center">
                    <div className="group">
                      <label htmlFor="group">Grupo: </label>
                      <select
                        className="form-control p-2"
                        id="group"
                        onChange={handleGroupChange}
                      >
                        <option value="*">Todos</option>
                        {
                          Object.keys(groups).map((group, i)=><option key={i} value={group}>{group}</option>)
                        }
                      </select>
                    </div>
                    <div className="subgroup ml-2">
                      <label htmlFor="subgroup">Subgrupo: </label>
                      <select
                        className="form-control p-2"
                        id="subgroup"
                        onChange={handleSubgroupChange}
                      >
                        <option value="*">Todos</option>
                        {
                          group !== '*' &&
                          Object.keys(groups[group]).map((subgroup)=><option value={subgroup}>{subgroup}</option>)
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>

                            
              {
                milliseconds > 0 && milliseconds <= 2678500000 && 
                <>
                
                
                <button  className="btn btn-orange d-inline  btn-sm" onClick={loadConversationData} disabled={loadingAttended ? true : false}>
                  Cargar datos
                </button>
                {
                  !loadingAttended &&
                  <ExcelExport asyncData={true} handleData={handleGetExportData} formatData={formatExportData} fileName={"conversations"}/>
                }
                </>
                

              }
          </div>
          { 
            (milliseconds <= 0 || milliseconds > 2678500000) &&
            <div className='mt-4'>
              <MDBAlert color="danger">
                  La fecha de inicio debe ser menor que la fecha de fin y no puede superar mas de un mes.
              </MDBAlert>
            </div> 
          }
          <MDBRow className='mt-4'>
            <MDBCol >
              { Object.keys(attendedData).length > 0 && <h5>Cantidad de conversaciones</h5> }
              {
                !loadingAttended && Object.keys(attendedData).length > 0 &&
              
                <MDBCard>
                  <MDBCardBody>
                    <div className='text-center'>
                      <strong>{conversationsLength}</strong><br/>conversaciones
                    </div>
                  </MDBCardBody>
                </MDBCard>
              }
              {
                loadingAttended && <Loading />
              }
            </MDBCol>
            
          </MDBRow>

          <MDBRow className='mt-4'>
            <MDBCol md="7">
              { Object.keys(attendedData).length > 0 && <h5>Datos generales de atendimiento (excluyendo llamadas)</h5> }
              <MDBRow>
                <MDBCol>
                {
                  !loadingAttended && Object.keys(attendedData).length > 0 &&
                
                  <MDBCard>
                    <MDBCardBody>
                      <div className='text-center'>
                        <strong>{assessorSended}</strong><br/>asignadas
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                }
                {
                  loadingAttended && <Loading />
                }
                </MDBCol>
                <MDBCol>
                {
                  !loadingAttended && Object.keys(attendedData).length > 0 &&
                
                  <MDBCard>
                    <MDBCardBody>
                      <div className='text-center'>
                        <strong>{attended}</strong>
                        <br/>
                        atendidas
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                }
                {
                  loadingAttended && <Loading />
                }
                </MDBCol>
                <MDBCol>
                {
                  !loadingAttended && Object.keys(attendedData).length > 0 &&
                
                  <MDBCard>
                    <MDBCardBody>
                      <div className='text-center'>
                        <strong>{noAtended}</strong>
                        <br/>
                        no atendidas
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                }
                {
                  loadingAttended && <Loading />
                }
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol md="5">
              { Object.keys(attendedData).length > 0 && <h5>Cerradas automáticamente al finalizar el día</h5> }
              <MDBRow>
                <MDBCol>
                {
                  !loadingAttended && Object.keys(attendedData).length > 0 &&
                
                  <MDBCard>
                    <MDBCardBody>
                      <div className='text-center'>
                        <strong>{outHour}</strong><br/>fuera de horario
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                }
                {
                  loadingAttended && <Loading />
                }
                </MDBCol>
                <MDBCol>
                {
                  !loadingAttended && Object.keys(attendedData).length > 0 &&
                
                  <MDBCard>
                    <MDBCardBody>
                      <div className='text-center'>
                        <strong>{noAvailable}</strong><br/>indisponibilidad
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                }
                {
                  loadingAttended && <Loading />
                }
                </MDBCol>
              </MDBRow>
            </MDBCol>
            
          </MDBRow>
          
          <MDBRow className='mt-4'>
            <MDBCol lg="4" className="item-graph">
              { Object.keys(attendedData).length > 0 && <h5>Inicio de conversación</h5> }
              {
                !loadingAttended && Object.keys(attendedData).length > 0 &&
                <MDBCard>
                  <MDBCardBody>
                    <GraficaDoughnut
                      dataItems={conversationsTypes}
                      title={['']}
                    />
                  </MDBCardBody>
                </MDBCard>
              }
              
            </MDBCol>
            <MDBCol lg="4" className="item-graph">
              { Object.keys(attendedData).length > 0 && <h5>Conversaciones por estado</h5> }
              {
                !loadingAttended && Object.keys(attendedData).length > 0 &&
                <MDBCard>
                  <MDBCardBody>
                    <GraficaDoughnut
                      dataItems={conversationsStates}
                      title={['']}
                    />
                  </MDBCardBody>
                </MDBCard>
              }
            </MDBCol>
            <MDBCol lg="4" className="item-graph">
              { Object.keys(attendedData).length > 0 && <h5>Conversaciones atendidas por el bot</h5> }
              {
                !loadingAttended && Object.keys(attendedData).length > 0 &&
                <MDBCard>
                  {console.log(conversationsAttendedBot)}
                  <MDBCardBody>
                    <GraficaDoughnut
                      dataItems={conversationsAttendedBot}
                      title={['']}
                    />
                  </MDBCardBody>
                </MDBCard>
              }
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-4">
          <MDBCol lg="12"  >
                { !loadingAttended && Object.keys(assignationsPerHour).length > 0 && <h5>Conversaciones por hora (momento en el que un cliente necesita un asesor)</h5> }
                {
                  !loadingAttended && Object.keys(assignationsPerHour).length > 0 &&
                  <MDBCard >
                    <MDBCardBody >
                      <GraficaBarVertical dataItems={[assignationsPerHour]} title="" hours={true} subtitle="Numero de conversaciones"/>
                    </MDBCardBody>
                  </MDBCard>
                }
                {
                  loadingAttended && <Loading />
                }
                
            </MDBCol>
        </MDBRow> 
        </section>
    )
}
export default ConversationRecords;