import React from 'react'
import styled from 'styled-components';

const Audio = styled.audio`
  height: 35px;
  max-width: 100%;
  z-index: 12;
`

const AudioMessage = ({ url }) => {
  return (
    <Audio controls>
      <source src={url} type="audio/mpeg" />
      Your browser does not support the audio element.
    </Audio>
  )
}

export default AudioMessage;