import React from 'react'
import styled from 'styled-components';
import pdfLogo from '../../../../assets/Images/pdf-icon.png';
import videoLogo from '../../../../assets/Images/archivo_mp4.png';

const Image = styled.img`
  max-width: ${props => props.type === "pdf" || props.type === "mp4" ? "120px" : "300px" };
  padding:${props => props.type === "pdf" ?  "20px 29px 10px 10px" : "" };
  width: 100%;
  border-radius: 10px;
  transition: ${props => props.type === "pdf" ? "transform 0.7s ease" : "inherit" } ;
`;

const FileMessage = ({ message, type }) => {
  return (
    <a href={message} target="_blank" rel="noopener noreferrer">
      <span>
        <Image
          src={type === "pdf" ? pdfLogo : type === "mp4" ? videoLogo : message }
          alt="message"
          type={type}
        />
      </span>
    </a>
  )
}

export default FileMessage;