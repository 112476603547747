import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MenuWraper } from "./style";
import { TiFlowMerge } from "react-icons/ti";

import { MdDashboard, MdComputer, MdSettings } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import LogoTalksPro from "../../assets/Images/TalksPro_Logo.svg";

const menuListGeneral = [
  {
    title: "Estadísticas",
    path: null,
    rol: "user",
    icon: MdDashboard,
    submenu: [
      {
        title: "Conversaciones",
        path: "/conversation-records",
      },
      {
        title: "Mensajes",
        path: "/message-records",
      },
      {
        title: "SMS",
        path: "/dashboard-sms",
      },
      {
        title: "Asignación de asesores",
        path: "/dashboard-asesores",
      },
      {
        title: "Llamadas",
        path: "/call-records",
      },
    ],
  },
  {
    title: "Monitoreo",
    path: null,
    rol: "user",
    icon: MdComputer,
    submenu: [
      {
        title: "Interacciones",
        path: "/conversations",
      },
      {
        title: "Estado de asesores",
        path: "/assessors-status",
      },
      {
        title: "Llamadas en espera",
        path: "/calls-queue",
      },
      {
        title:'Eventos del Bot',
        path: 'bot-events'
      },
      {
        title: "Auditoria",
        path: "/logs-activity",
      },
      
    ],
  },
  {
    title: "Gestión de usuarios",
    path: null,
    rol: "user",
    icon: FaUsers,
    submenu: [
      {
        title: "Acceso al chat",
        path: "/users-management",
      },
      {
        title: "Grupos y subgrupos",
        path: "/groups-subgroups",
      },
    ],
  },
  {
    title: "Automatizaciones",
    path: "/automatic_messages",
    rol: "user",
    submenu: [],
    icon: TiFlowMerge,
    provider: ["aldeamo", "meta"],
  },
  {
    title: "Configuración",
    path: null,
    rol: "user",
    icon: MdSettings,
    submenu: [
      {
        title: "Notificaciones del plan",
        path: "/notifications",
      },
      {
        title: "Chat",
        path: "/chat",
      },
      {
        title: "Plantillas",
        path: "/templates",
      },
      {
        title: "Campañas",
        path: "/campaigns",
      },
      {
        title: "Fragmentos",
        path: "/fragments",
      },
      {
        title: "Lista negra",
        path: "/black-list",
      },
      {
        title: "Mi cuenta",
        path: "/my-account",
      },
    ],
  },
];

function MenuComponent() {
  let history = useHistory();
  let location = useLocation();
  const [selectItemMenu, setSelectItemMenu] = useState("Conversaciones");
  const [menuSelect, setMenuSelect] = useState("");
  const [menuSelectCurrent, setMenuSelectCurrent] = useState("Estadísticas");

  useEffect(() => {

    if (location) {
      let path = location.pathname;
      let findInMenu = menuListGeneral.find((el) => el.path === path);
      if (findInMenu) {
        setMenuSelect(findInMenu.title);
        setMenuSelectCurrent(findInMenu.title);
        setSelectItemMenu(findInMenu.title);
      } else {
        for (const menu of menuListGeneral) {
          for (const submenu of menu.submenu) {
            if (submenu.path === path) {
              setMenuSelect(menu.title);
              setMenuSelectCurrent(menu.title);
              setSelectItemMenu(submenu.title);
              return;
            }
          }
        }
      }
    }
  }, []);

  function selectMenu(e, titleMenu) {
    if (localStorage.getItem("updated")) {
      const _confirm = window.confirm(
        "Parece que hay cambios que no se han guardado, ¿está seguro desea salir de la página?"
      );
      if (_confirm) {
        setSelectItemMenu(e.title);
        history.push(e.path);
        localStorage.setItem("updated", "");
      }
    } else {
      setMenuSelect(titleMenu);
      setSelectItemMenu(e.title);
      history.push(e.path);
    }
  }


  return (
    <MenuWraper>
      <div className="sidebar-brand d-flex align-items-center justify-content-center">
        <Link to="/conversation-records">
          <div className="sidebar-brand-text mx-3">
            <img src={LogoTalksPro} alt="logo" width="100px" />
          </div>
        </Link>
      </div>
      <Sidebar
        width="241px"
        backgroundColor="#4d4d4d"
        rootStyles={{
          height: "calc(100vh - 56px)",
        }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree

              if (level === 0)
                return {
                  padding: "0 20px 0 10px",
                  backgroundColor: active ? "#323232" : undefined,
                  color: "white",

                  [`&.active`]: {
                    backgroundColor: "#3b3b3b",
                    color: "#b6c8d9",
                    fontWeight: "bold",
                  },
                  [`&:hover`]: {
                    backgroundColor: "#3b3b3b",
                    color: "#b6c8d9",
                  },
                };
              if (level === 1)
                return {
                  padding: "0 20px 0 40px",
                  color: active ? "#f9791f" : "black",
                  height: "40px",
                  fontWeight: active ? "bold" : "normal",
                };
            },
          }}
          rootStyles={{
            [`.${menuClasses.icon}`]: {
              backgroundColor: "#e1e1e120",
              color: "#f9791f",
            },
          }}
        >
          {menuListGeneral.map(
            ({ icon: Icon, path, title, submenu, rol, provider }, index) => {
              if (
                (rol === "user" || rol === localStorage.getItem("rol")) &&
                (!provider ||
                  provider.includes(localStorage.getItem("provider")))
              ) {
                if (path)
                  return (
                    <MenuItem
                      icon={<Icon />}
                      onClick={() => selectMenu({ title, path }, title)}
                      active={menuSelect === title}
                      key={index + "-menu"}
                    >
                      {title}
                    </MenuItem>
                  );
                return (
                  <SubMenu
                    open={menuSelectCurrent === title}
                    label={title}
                    icon={<Icon />}
                    active={menuSelect === title}
                    key={index + "-menu"}
                    onClick={() => setMenuSelectCurrent(title)}
                  >
                    {submenu.map((item, idx) => {
                      return (
                        <MenuItem
                          active={item.title === selectItemMenu}
                          rootStyles={{
                            backgroundColor: "#ececec",
                          }}
                          onClick={() => selectMenu(item, title)}
                          key={idx + "-item-menu"}
                        >
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                );
              } else return null;
            }
          )}
        </Menu>
      </Sidebar>
    </MenuWraper>
  );
}

export default MenuComponent;
