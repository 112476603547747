import React from 'react';
import { getDateByTime } from '../../../utils/Times';
const BotEventRow = ({phone, timeStamp, name, line, step, type, action, message, detail}) => {
    
    return (
        <tr>
            <td>{name??'Sin nombre'}</td>
            <td>{getDateByTime(timeStamp)}</td>
            <td>{type}</td>
            <td>{phone}</td>
            <td>{line}</td>
            <td>{step}</td>
            <td>{action}</td>
            <td>{message}</td>
            <td>{detail}</td>
        </tr>
    )
}
export default BotEventRow;