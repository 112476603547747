import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import React, { useEffect, Fragment, useState, Suspense } from 'react';
import { Footer } from '../components/footer';
import LoginForm from '../Views/Login';
import { PageWrapper, PageStyles, PageWrapperMenu, ContentWrapper } from '../GlobalStyle';
import { VERSION_SESSION } from '../config';
import Utils from '../utils';
import { LoadingMain } from '../components/LoadingMain';

import Topbar from '../components/topbar';
import { NotFound } from '../Views/NotFound';
import { Canales } from '../Views/Canales';
import { Bots } from '../Views/Bots';
import Configuration from '../Views/Configuration';
import Conversations from '../Views/Conversations';
import Templates from '../Views/Templates';
import Notification from '../components/notification';
import Campaigns from '../Views/Campaigns';
import GroupsAndSubgroups from '../Views/GroupsAndSubgroups';
import MessagesAut from '../Views/messages';
import UsersManagement from '../Views/UsersManagement';

import Fragments from '../Views/Fragments';
import Chat from '../Views/Chat';
import AssessorsStatus from '../Views/AssessorsStatus';
import CallRecords from '../Views/CallRecords';
import MessageRecords from '../Views/MessageRecords';
import CallsQueue from '../Views/CallsQueue';
import DashboardSMS from '../Views/DashboardSMS';
import BlackList from '../Views/BlackList';
import Notifications from '../Views/Notifications';
import ConversationRecords from '../Views/ConversationRecords';
import AsessorsRecords from '../Views/AsessorsRecords';
import MenuComponent from '../components/menu';
import MyAccount from '../Views/MyAccount';
import LogsActivity from '../Views/LogsActivity';
import BotEvents from '../Views/BotEvents';

const AuthenticatedUser = ({ children, handleCloseSession, company, setCompany }) => {
  return (
    <Fragment>
      <PageStyles>
        <PageWrapperMenu>
          <MenuComponent />
        </PageWrapperMenu>
        <PageWrapper>
          <Topbar handleCloseSession={handleCloseSession} company={company} setCompany={setCompany} />
          <Notification />
          <ContentWrapper>{children}</ContentWrapper>
          <Footer />
        </PageWrapper>
      </PageStyles>
    </Fragment>
  );
};

const NotAuthenticatedUser = ({ children }) => {
  return children;
};

export function Routes() {
  const [company, setCompany] = useState(null);
  const [login, setLogin] = useState(false);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginAut, setLoginAut] = useState(false);

  const handleCloseSession = (e) => {
    localStorage.clear();
    sessionStorage.clear();
    setCompany(null);
    setLogin(false);
  };

  useEffect(() => {
    setLoginAut(false);
    if (localStorage.getItem('token_user')) {
      if (VERSION_SESSION !== localStorage.getItem('VERSION_SESSION')) {
        localStorage.clear();
        sessionStorage.clear();
        setLoad(true);
      } else {
        setLoading(true);
        Utils.Petition.get(
          '/users/current',
          (response) => {
            setLogin(true);
            setLoginAut(false);
            // localStorage.setItem('menu', response.data.rol.permisos);
            localStorage.setItem('client', response.data.user.connectionBusiness.business.client);
            localStorage.setItem('firstname', response.data.user.firstname);
            localStorage.setItem('updated', '');
            localStorage.setItem('company', response.data.company);
          },
          (err) => localStorage.clear(),
          () => {
            setLoading(false);
            setLoad(true);
          }
        );
      }
    } else {
      if (window.location.href.includes('/login/')) {
        setLoginAut(true);
      }
      setLoad(true);
    }
  }, []);

  return (
    <Fragment>
      {loading && <LoadingMain />}
      {load && (
        <BrowserRouter>
          {!loginAut && !login && <Redirect to="/login" />}
          {!login && (
            <NotAuthenticatedUser>
              <Switch>
                <Route
                  path="/login/:correo/:id"
                  exact
                  render={({ match }) => {
                    return <LoginForm setLogin={setLogin} login={login} correo={match.params.correo} id={match.params.id} />;
                  }}
                />
                <Route path="/login" exact component={() => <LoginForm setLogin={setLogin} />} />
                {/* <Route path="/signup" exact component={Signup} /> */}
                {/* <Route path="/" exact component={LandingPage} /> */}
                {/* <Route path="*" component={NotFound} /> */}
              </Switch>
            </NotAuthenticatedUser>
          )}
          {login && (
          
            <AuthenticatedUser handleCloseSession={handleCloseSession} company={company} setCompany={setCompany}>
                <Switch>
                  <Route exact path="/">
                    <Suspense fallback={<LoadingMain />}>
                      <ConversationRecords />
                    </Suspense>
                  </Route>
                  <Route path="/notifications" setLogin={setLogin} component={() => <Notifications />} />
                  <Route path="/dashboard-sms" setLogin={setLogin} component={()=><DashboardSMS/>}/>
                  <Route path="/canales" setLogin={setLogin} component={() => <Canales />} />
                  <Route path="/bots" setLogin={setLogin} component={() => <Bots />} />
                  <Route path="/phone-configuration" setLogin={setLogin} component={() => <Configuration />} />
                  <Route path="/chat" setLogin={setLogin} component={() => <Chat />} />
                  <Route path="/conversations" setLogin={setLogin} component={() => <Conversations />} />
                  <Route path="/templates" setLogin={setLogin} component={() => <Templates />} />
                  <Route path="/campaigns" setLogin={setLogin} component={() => <Campaigns />} />
                  <Route path="/groups-subgroups" setLogin={setLogin} component={() => <GroupsAndSubgroups />} />
                  <Route path="/my-account" setLogin={setLogin} component={() => <MyAccount />} />
                  <Route path="/automatic_messages" setLogin={setLogin} component={() => <MessagesAut />} />
                  <Route path="/users-management" setLogin={setLogin} component={() => <UsersManagement />} />
                  <Route path="/fragments" setLogin={setLogin} component={() => <Fragments />} />
                  <Route path="/assessors-status" setLogin={setLogin} component={() => <AssessorsStatus />} />
                  <Route path="/call-records" setLogin={setLogin} component={()=><CallRecords/>}/>
                  <Route path="/message-records" setLogin={setLogin} component={()=><MessageRecords/>}/>
                  <Route path="/dashboard-asesores" setLogin={setLogin} component={()=><AsessorsRecords/>}/>
                  <Route path="/conversation-records" setLogin={setLogin} component={()=><ConversationRecords/>}/>
                  <Route path="/calls-queue" setLogin={setLogin} component={()=><CallsQueue/>}/>
                  <Route path="/black-list" setLogin={setLogin} component={()=><BlackList/>}/>
                  <Route path="/logs-activity" setLogin={setLogin} component={()=><LogsActivity/>}/>
                  <Route path="/bot-events" setLogin={setLogin} component={()=><BotEvents/>}/>
                  <Route path="*" component={NotFound} />
                </Switch>
            </AuthenticatedUser>
          )}
        </BrowserRouter>
      )}
    </Fragment>
  );
}
