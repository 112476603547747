import React, { useContext } from 'react';
import OptionLoading from '../../../components/Loading/OptionLoading';
import { PhoneLinesContext } from '../../../Context/PhoneLines';

const PhoneLines = ({ name, id, onChange, value }) => {
    const { phoneLines, phoneLinesAreLoading } = useContext(PhoneLinesContext);
  
    return (
      <select
        className='form-control'
        name={name}
        id={id}
        onChange={onChange}
        value={value}
      >
        <option value="">Seleccione una linea de teléfono</option>
        <OptionLoading isLoading={phoneLinesAreLoading} />
  
        {
          phoneLines?.map((line) => (
            <option key={line._id} value={line._id}>{line.name + " / "+ line.phone}</option>
          ))
        }
  
      </select>
    )
  }
  
  export default PhoneLines;