import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Utils from '../../utils';
import { VERSION_SESSION } from '../../config';
import { MDBBtn } from 'mdbreact';
import { LoginStyles } from './styles';
import { LoadingMain } from '../../components/LoadingMain';

function LoginForm({ setLogin, id,correo }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState('');
  const [message, setMessage] = useState('');
  const [autoLogin, setAutoLogin] = useState(false);
  const history = useHistory();
  
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    if(autoLogin){
      setEmail(correo)
      setPassword(id)
    }
    try{

      Utils.Petition.publicPost(
        '/users/login',
        {
          email: email,
          password: password,
          autologin: autoLogin
        },
        (result) => {          
          //window.location.href = "/dashboard";
          localStorage.setItem('token_user', result.token);
          localStorage.setItem('rol', result.rol);
          localStorage.setItem('provider', result.provider);
          localStorage.setItem('multiline', result.multiline);
          localStorage.setItem('VERSION_SESSION', VERSION_SESSION);
          localStorage.setItem('company', result.company);
  
          Utils.Petition.get('/users/current', (response) => {
            localStorage.setItem('client', response.data.user.connectionBusiness.business.client);
            // localStorage.setItem('menu', response.data.rol?.permisos);
            localStorage.setItem('firstname', response.data.user.firstname);
            setLogin(true);
            history.push('/conversation-records');
          });
        },
        (err) => {
          setMessage('Correo y/o contraseña inválidos');
          if(autoLogin){
            window.location.href = "/login";
          }
        },
        () => {
          setLoading(false);
        }
      );
    }catch(e){
      console.log(e)
    }
  };

  useEffect(() => {
    setEmail(correo)
    setPassword(id)
    if(typeof id != "undefined"){
      setTimeout(function(){
        document.getElementById("submit").click()
      },2000)
      setAutoLogin(true)
    }
  }, []);

  return (
    <LoginStyles>
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="card card-signin my-5">
              <div className="card-body">
                <form className="form-signin" onSubmit={handleSubmit}>
                  {loading && <LoadingMain />}
                  <div>
                    <img
                      src="https://www.triario.co/hs-fs/hubfs/Talks_Pro_-_Logo.png?width=846&name=Talks_Pro_-_Logo.png"
                      alt="logo"
                      width="200px"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="inputEmail">Correo</label>
                    <input
                      type="email"
                      id="inputEmail"
                      className="form-control"
                      placeholder="example@gmail.com"
                      required
                      autoFocus
                      value={email??''}
                      onChange={handleChangeEmail}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="inputPassword">Contraseña</label>
                    <input
                      type="password"
                      id="inputPassword"
                      className="form-control"
                      placeholder="********"
                      required
                      value={password??''}
                      onChange={handleChangePassword}
                    />
                  </div>

                  <div className={`alert ${message ? 'alert-danger' : ''}`}>{message}</div>
                  <MDBBtn className="text-uppercase" type="submit" color="deep-orange" id="submit">
                    Iniciar sesión
                  </MDBBtn>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyles>
  );
}

export default LoginForm;
