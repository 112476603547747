import styled from "styled-components";


export const SectionTitle = styled.span`
  font-weight:500;
  transition: 0.5s;
  svg {
    transition: 0.5s;
    transform: ${props => props.active ? "rotate(180deg)" : "rotate(0deg)" };
  }
`;
export const SectionContent = styled.span`
    display: ${props => props.active ? "block" : "none"};
`;
export const SectionContainer = styled.span`
  display: block;
  border-radius: 12px;
  border: 1px solid #ccc;
  margin-bottom:8px;
  padding:6px;
  cursor:pointer;
`;
export const SectionHtmlEditor = styled.span`
  strong{
    font-weight: 600;
  }
`;
export const SectionWhatsappPreview = styled.div`
  height:100%;
  background-color: #e5ddd5;
  position:relative;
  padding:15px;
  border-radius: 15px;
  &:before{
    background-image: url('/rmr3BrOAAd8.png');
    position:absolute;
    left:0;
    top:0;
    opacity: 0.06;
    content:'';
    height:100%;
    width:100%;
  }
`;
export const MessageItem = styled.div`
  margin-left:8px;
  marging-right:8px;
  position:relative;
  display:inline-block;
  .box{
    border-radius: 7.5px;
    border-top-left-radius:0;
    background-color:white;
    position:relative;
    word-wrap: break-word;
    .p-header{
      color: rgba(0, 0, 0, .76);
      font-size: 15px;
      font-weight: bold;
      line-height: 19px;
      padding: 6px 7px 0 9px;
      .media-display{
        background-color:#ccd0d5;
        padding: 25px;
        text-align:center;
      }
    }
    .p-body{
      font-size: 13.6px;
      white-space: pre-wrap;
      padding: 7px 7px 6px 9px;
      &:after{
        content: '';
        display: inline-block;
        margin-right: 78px;
      }
      ul{
        margin-bottom:0;
      }
    }
    .p-footer{
      color: rgba(0, 0, 0, .45);
      font-size: 13px;
      line-height: 17px;
      padding: 0 7px 8px 9px;
      &:after{
        content: '';
        display: inline-block;
        margin-right: 78px;
      }
    }
    time{
      bottom: 3px;
      color: rgba(0, 0, 0, .4);
      font-size: 11px;
      height: 15px;
      line-height: 15px;
      position: absolute;
      right: 7px;
    }
    &:after{
      background: url(/cTpzmA9a2VH.png) 50% 50% no-repeat;
      background-size: contain;
      content: '';
      height: 19px;
      left: -12px;
      position: absolute;
      top: 0;
      width: 12px;
    }
  }
  .custom-button-group{
    min-width:240px;
    .custom-button{
      background-color: #fff;
      border-radius: 7.5px;
      box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
      box-sizing: border-box;
      flex-grow: 1;
      margin: 2px 0 0 2px;
      min-width: calc(50% - 2px);
      
      color: #00a5f4;
      font-size: 14px;
      height: 34px;
      line-height: 20px;
      padding: 0 16px;
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      .icon{
        
        background-repeat: no-repeat;
        background-size: contain;
        flex-shrink: 0;
        height: 16px;
        opacity: .8;
        width: 16px;
        margin-right:4px;
        &.icon-url{
          background-image: url(/OzOCQC-ukqH.png);
        }
        &.icon-phone{
          background-image: url(/8o77vvYFLgb.png);
        }

      }
    }
  }

`;


