import React, { useState } from 'react';
import { getDateByTime, getSecondMinuteBySecond } from '../../../utils/Times';
const WAITING_MINUTES=5;
const CallRecordRow = ({identificatorCode, createdAt, group, subgroup   }) => {
    const [date] = useState(Date.now());
    return (
        <tr>
            <td>{identificatorCode}</td>
            <td>{getDateByTime(new Date(createdAt).getTime())}</td>
            <td>{getSecondMinuteBySecond(((new Date(createdAt).getTime()+1000+(WAITING_MINUTES*60*1000)) - date)/1000)} </td>
            <td>{group} </td>
            <td>{subgroup} </td>
        </tr>
    )
}
export default CallRecordRow;