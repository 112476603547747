import React, { useEffect, useState } from 'react';
import usePagination from '../../hooks/usePagination';
import useGet from '../../hooks/useGet';
import CallRecordRow from './components/CallRecordRow';
import PaginationTable from "../../components/tables/PaginationTable";
import FragmentsIcon from "../../components/icons/pages/FragmentsIcon";
import styled from "styled-components";
import Utils from '../../utils';
import ExcelExport from '../../components/ExcelExport';
import { getDateByTime } from '../../utils/Times';
import DateFilter from '../Conversations/components/filters/DateFilter';
import { MDBAlert } from 'mdbreact';

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;
export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;
const months =['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre', 'Diciembre'];
const CallRecords = ()=>{
  const [since, setSince] = useState(`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-01T00:00`);
  const [until, setUntil] = useState(`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${(new Date().getDate()).toString().padStart(2, '0')}T${(new Date().getHours()).toString().padStart(2, '0')}:${(new Date().getMinutes()).toString().padStart(2, '0')}`);
  const [milliseconds, setMillisecond] = useState(1);
  const [firstLoading, setFirstLoading] = useState(false);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [totalPlan, setTotalPlan] = useState(0);
  useEffect(()=>{
    Utils.Petition.get('/call-records/plan', (response)=>{
        setTotalMinutes(response.count);
        setTotalPlan(response.plan);
    })
  }, []);

  useEffect(()=>{
    if(!firstLoading && Number.isInteger(since) && Number.isInteger(until) && milliseconds>1){
      setFirstLoading(true);
      updateFilterByDate();
    }
  }, [since, until, milliseconds]);

    const [showedCallRecords, setShowedCallRecords] = useState();
    const { paginationPages, setCurrentPage, showedData } = usePagination({ data: showedCallRecords, showRows: 10 });
    
    const formatCallRecord = (data)=>{
      return data.map(obj => {
        return {
          identificatorCode: obj.identificatorCode,
          start: getDateByTime(obj.start), 
          end: obj.end?getDateByTime(obj.end):'N/A',
          seconds: (obj.seconds<0)?'N/A': obj.seconds,
          assessor: obj.assessor,
          type: obj.type,
          email: obj.email,
          name: obj.name,
        }
      });
    }
    
    const updateFilterByDate = ()=>{
      if(milliseconds > 0 && milliseconds <= 2678500000){
        setShowedCallRecords(null);
        /* Utils.Petition.get('/call-records', (response)=>{ */
        Utils.Petition.get(`/call-records/detail/start/${since}/end/${until}`, (response)=>{
          setShowedCallRecords(response.data);
        })
      }
    }
    const handleDatesFilter = (dates) => {
      setSince(dates.since);
      setUntil(dates.until);
      setMillisecond(dates.until - dates.since);
    };
    

    return (
        <section className='mx-4 mb-4'>
          <div className="d-flex">
            <FragmentsIcon />
            <Title>Registro de llamadas</Title>
          </div>
          <Description>Estado del plan ({months[new Date().getMonth()]}): {totalMinutes} de {totalPlan} minutes</Description>
          <div>
            <DateFilter
                setDateFilter={handleDatesFilter}
                since={since}
                until={until}
            />
            <button className="btn btn-sm btn-orange" onClick={updateFilterByDate}>Filtrar</button>
            <ExcelExport excelData={showedCallRecords}  formatData={formatCallRecord} fileName="Resumen de llamadas" />
          </div>
          { 
            (milliseconds <= 0 || milliseconds > 2678500000) && 
            <MDBAlert color="danger">
                La fecha de inicio debe ser menor que la fecha de fin y no puede superar un mes
            </MDBAlert>
          }
          <PaginationTable
            headers={["Teléfono", "Fecha de llamada", "Fecha fin", "Segundos", "Asesor", "Acción", "Correo", "Nombre"]}
            withoutRight={false}
            content={showedData}
            pages={paginationPages}
            setPages={setCurrentPage}
            row={CallRecordRow}
          />

        </section>
    )
}
export default CallRecords;