import React from "react";
import "./../styles/style.css";

const STATUS = [
  {
    name: "En línea",
    value: "online",
  },
  {
    name: "Desconectado",
    value: "offline",
  },
  {
    name: "En llamada",
    value: "onCall",
  },
];

const AssessorStatusIndicator = ({ status }) => {
  return (
    <div>      
      <span className={
        status === "online" ? "c-pill c-pill--success" : "c-pill c-pill--danger"
      }>
        {STATUS.find((s) => s.value === status)?.name}
      </span>
    </div>
  );
};

export default AssessorStatusIndicator;

