import React from 'react'

const NewUserCallIcon = () => {
  return (
    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.96603 25C7.50126 25 0 25 0 20.3485C0 16.2016 5.68822 15.7222 9.96603 15.7222C12.4308 15.7222 19.9295 15.7222 19.9295 20.3737C19.9295 24.5206 14.2426 25 9.96603 25Z" fill="#C9CDD2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.96602 13.3602H9.92576C6.24937 13.3488 3.27126 10.3506 3.28384 6.67672C3.28384 2.99655 6.28082 -0.000427246 9.96602 -0.000427246C13.65 -0.000427246 16.6457 2.99655 16.6457 6.6805C16.6457 10.3644 13.65 13.3602 9.96602 13.3602Z" fill="#C9CDD2" />
    </svg>
  )
}

export default NewUserCallIcon;