import { useEffect, useState } from "react";
export const defaultTransform = (data) => data;
const usePagination = ({ data, showRows, transformShowedData = defaultTransform, numOfConversations }) => {
  const [paginationPages, setPaginationPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showedData, setShowedData] = useState(data);

  
  useEffect(() => {
    setPaginationPages(Math.ceil(data?.length  / showRows) || 1);
  }, [data, showRows]);

  useEffect(() => {
    setShowedData(
      transformShowedData(data?.slice((currentPage * showRows - showRows), (currentPage * showRows))));
  }, [currentPage, showRows, data, transformShowedData]);


  return { showedData, paginationPages, setCurrentPage, currentPage };

}

export default usePagination;