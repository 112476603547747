import React from 'react';
import { getDateByTime } from '../../../utils/Times';
const CallRecordRow = ({identificatorCode, start, end, seconds, type, email, name, assessor}) => {
    
    return (
        <tr>
            <td>{identificatorCode}</td>
            <td>{getDateByTime(start)}</td>
            <td>{end?getDateByTime(end):'N/A'}</td>
            <td>{(seconds<0)?'N/A': seconds}</td>
            <td>{assessor}</td>
            <td>{type}</td>
            <td>{email}</td>
            <td>{name}</td>
        </tr>
    )
}
export default CallRecordRow;