import React from "react";
import ProfileIcon from "../../../../components/icons/ProfileIcon";
import { Row } from "../../../../components/tables/styles";
import { TextElipsis } from "../../styles";
import ContactCard from "./ContactCard";
import Interaction from "./Interaction";
import State from "./State";
import { getDateByTime } from "../../../../utils/Times";
import Utils from "../../../../utils";
import { formatMessages } from "../../../../utils/Format";

const ContactRow = ({
  conversation,
  setCurrentConversation,
  active,
  setUpdateScroll,
}) => {
  const handleCurrentConversation = ()=>{
    console.log(conversation.key);
    
    Utils.Petition.get('/conversations/get-conversation/'+conversation.key, (response)=>{
      
      let ccs = JSON.parse(JSON.stringify(response.data));
      for(let i= ccs.conversation.length-1; i>=0; i--){
        ccs.conversation[i].messages = formatMessages(ccs.conversation[i].messages);
      }
       
      setCurrentConversation(ccs);
      setUpdateScroll(true);
    })
    //setCurrentConversation(conversation);
  }
  const getPhoneLineName = ()=>{
    const phoneLine = conversation.phoneLines ? conversation.phoneLines.find((item)=>item._id===conversation.line):null;
    return phoneLine?.name ?? "No registrado";
  }
  return (
    
      <Row
        active={active}
        onClick={handleCurrentConversation}
      >
        <td>
          <ContactCard
            channel={
              conversation?.channel || conversation?.lastChannel
            }
            name={conversation?.name}
            number={conversation?.identificatorCode}
            email={conversation?.email}
          />
        </td>

        <td>
          {getPhoneLineName()}
        </td>
        <td>
          <div className="d-flex align-items-center">
            <ProfileIcon className="mr-2" />
            <TextElipsis className="m-0">
              {conversation?.agent
                ? conversation?.agent
                : "Sin asignar"}
            </TextElipsis>
          </div>
        </td>

        <td>
          <div className="d-flex justify-content-center align-items-center">
            <State state={conversation?.state} />
          </div>
        </td>
        <td>
          <div>
            {
              conversation.requiredPropsValues && Object.keys(conversation.requiredPropsValues).map((property, i)=>(
                  <span key={i} className="c-pill c-pill--light">{property}: {conversation.requiredPropsValues[property]}</span>
                )
              )
            }
          </div>
        </td>
        <td>
          {conversation?.group}
        </td>
        <td>
          {conversation?.subgroup}
        </td>
        <td>
          {getDateByTime(conversation.start)}
        </td>
        <td>
          <Interaction
            direction={conversation?.direction}
            state={conversation?.state}
            date={conversation?.lastInteraction}
          />
        </td>
        <td>
            {getDateByTime(conversation.dateUpdate)}
        </td>
      </Row>
    )
  
};

export default ContactRow;

