import React from 'react'
import { useState } from 'react'



function TemplateSearch({handleSearch}) {



  const handleOnChangeText = (textSearch)=>{
    handleSearch(textSearch)
  }

  return (
    <div>
      <input
        className="form-control m-2 w-50"
        type="text"
        placeholder="Buscar por nombre o código de plantilla"
        onChange={(e) => {
          handleOnChangeText(e.target.value);
        }}
      />
      
    </div>
  );
}

export default TemplateSearch