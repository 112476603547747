import React from 'react'

const ArrowDownLeftIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3.49997V11M1 11H8.50003M1 11L11 1" stroke="#FA7820" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowDownLeftIcon;