import React from 'react'
import CanceledCallIcon from '../../../../components/icons/calls/CanceledCallIcon';
import IncomingCallIcon from '../../../../components/icons/calls/IncomingCallIcon';
import NewUserCallIcon from '../../../../components/icons/calls/NewUserCallIcon';
import OutcomingCallIcon from '../../../../components/icons/calls/OutcommingCallIcon';
import RejectedCallIcon from '../../../../components/icons/calls/RejectedCallIcon';

export const ACTIONSCALLS = {
  outcoming: 'Llamada Saliente', // call-success
  incoming: 'Llamada Entrante',
  rejected: 'Llamada Rechazada', //call-cancel
  canceled: 'Llamada Perdida',
  newUser: 'New User'
}

export const TYPESCALLS = {
  outcoming: 'call-success', // call-success
  general: 'call',
  rejected: 'call-cancel'
}

const CallActionIcon = ({ action }) => {
  switch (action) {
    case ACTIONSCALLS.incoming:
      return <IncomingCallIcon />
    case ACTIONSCALLS.outcoming:
      return <OutcomingCallIcon />
    case ACTIONSCALLS.rejected:
      return <RejectedCallIcon />
    case ACTIONSCALLS.canceled:
      return <CanceledCallIcon />
    case ACTIONSCALLS.newUser:
      return <NewUserCallIcon />
    default:
      return null;
  }
}

export default CallActionIcon;