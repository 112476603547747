import React, { useContext } from 'react';
import OptionLoading from '../../../components/Loading/OptionLoading';
import { CampaignsContext } from '../../../Context/Campaigns';

const SelectBot = ({ name, id, onChange, value }) => {
  const { bots, botsAreLoading } = useContext(CampaignsContext);

  return (
    <select
      className='form-control'
      name={name}
      id={id}
      onChange={onChange}
      value={value}
    >
      <option value="">Seleccione un bot</option>
      <OptionLoading isLoading={botsAreLoading} />

      {
        bots?.map((bot) => (
          <option key={bot._id} value={bot._id}>{bot.name}</option>
        ))
      }

    </select>
  )
}

export default SelectBot;