import React from "react";
import { HighlightedRow, Row } from "../../../components/tables/styles";
import LogsStatusIndicator from "./LogsStatusIndicator";
import { getMinutesBetweenDates } from '../../../utils/Times';
const getResolutionTime = (date) => {
  const [days, hours, minutes] = getMinutesBetweenDates(new Date(), new Date(date));
  let daysStr = (days > 0) ? (`${days} ` + ((days===1) ? 'dia': 'dias')) : "";
  let hrsStr = (hours > 0) ? (`${hours} ` + ((hours===1) ? 'hora': 'horas')) : "";
  let minsStr = (minutes > 0) ? (`${minutes} ` + ((minutes===1) ? 'min': 'mins')) : "";
  return `${daysStr} ${hrsStr} ${minsStr}`;
}
const LogsRow = ({ _id, app, createdAt, user, register, type, url }) => {
  return (
    <Row key={_id}>
      <td>
        <strong> {`${new Date(createdAt).toLocaleDateString()}`}</strong><br />
        <strong><small>{`${new Date(createdAt).toLocaleTimeString("es")}`}</small></strong>
      </td>
      <td><strong>{`${user.firstname} ${user.lastname}`}</strong></td>
      <td>{register}</td>
      <td>
          <LogsStatusIndicator status={type} />
      </td>
      <td>
        <strong>{`${app}`}</strong>
      </td>
    </Row>
  );
};

export default LogsRow;

