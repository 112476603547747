import styled from "styled-components";

export const MenuWraper = styled.aside`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  & .sidebar-brand {
    background-color: #4d4d4d;
    padding: 12px 0;
  }
`;

