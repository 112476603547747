import React from 'react'
import { useColor } from '../../../hooks/useColor';

const CallIcon = ({ full, color, ...props }) => {

  const colorCode = useColor(color);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path d="M15 29C22.7319 29 29 22.7319 29 15C29 7.26785 22.7319 1 15 1C7.26806 1 1 7.26785 1 15C1 22.7319 7.26807 29 15 29Z" stroke={colorCode} strokeWidth="2" />
      <mask id="mask0_136_8396" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="16" y="5" width="9" height="10">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.354 5.91695H24.893V14.0942H16.354V5.91695Z" fill="white" />
      </mask>
      <g mask="url(#mask0_136_8396)">
        <path fillRule="evenodd" clipRule="evenodd" d="M24.1427 14.0942C23.7657 14.0942 23.4417 13.823 23.3987 13.456C23.0197 10.2206 20.3977 7.70981 17.0207 7.35043C16.6097 7.30635 16.3127 6.95177 16.3587 6.55693C16.4037 6.16306 16.7707 5.87268 17.1867 5.92252C21.2647 6.35664 24.4317 9.38785 24.8887 13.295C24.9347 13.6898 24.6387 14.0454 24.2277 14.0894C24.1997 14.0923 24.1707 14.0942 24.1427 14.0942Z" fill={colorCode} />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.6027 14.1046C20.2507 14.1046 19.9377 13.8669 19.8677 13.5238C19.5797 12.1055 18.4387 11.012 16.9607 10.737C16.5537 10.6613 16.2887 10.2847 16.3677 9.89462C16.4467 9.50458 16.8467 9.24966 17.2467 9.32633C19.3277 9.7135 20.9347 11.2526 21.3397 13.2478C21.4187 13.6388 21.1537 14.0155 20.7477 14.0912C20.6987 14.0998 20.6507 14.1046 20.6027 14.1046Z" fill={colorCode} />
      <path d="M9.13541 7.49212C9.34405 7.6071 9.58071 7.79474 9.90651 8.10255L11.3286 9.51156C11.3556 9.53788 11.3805 9.56192 11.4028 9.58325C11.9435 10.1015 12.0632 10.4376 11.9583 10.9196L11.8992 11.141L11.7788 11.4885L11.7353 11.6084C11.0569 13.4735 11.2944 14.7688 13.319 16.7086C15.2739 18.5816 16.6014 18.8577 18.4415 18.2882L19.0758 18.0818L19.2291 18.0396C19.76 17.9039 20.1097 17.968 20.6214 18.4202L22.1526 19.8372L22.2977 19.9764L22.543 20.2246C22.7238 20.4164 22.848 20.5733 22.9333 20.7165C23.1577 21.0926 23.1098 21.4944 22.5851 22.1642L22.3798 22.4121C22.2293 22.5853 22.0511 22.7749 21.8425 22.9841L21.3976 23.4143C20.8143 23.987 20.2423 24.2473 19.1209 24.0978C17.1973 23.8414 14.5314 22.2521 11.0325 18.8998C7.67451 15.6826 6.00561 13.193 5.641 11.3578C5.4188 10.2394 5.67665 9.58903 6.221 9.05619L6.44626 8.83931L6.4762 8.80346L6.80848 8.49332C6.86138 8.44516 6.91308 8.39874 6.96362 8.35403L7.25339 8.10595C8.17559 7.34529 8.67389 7.23778 9.13541 7.49212Z" fill={colorCode} />
    </svg>
  )
}

export default CallIcon