import React, { useRef, useEffect, useState } from 'react';
import ArrowDownIcon from '../../../../components/icons/arrows/ArrowDownIcon';
import { FilterOption, FilterOptions, FilterVisible } from '../../styles';

const Filter = ({ label, options, field, exclude = false, setSelected }) => {
  const filterRef = useRef(null);
  const [active, setActive] = useState(false);

  const handleClickOutside = (e) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) {
      setActive(false);
    }
  }

  const handleCheckChange = (e, value) => {
   
    setSelected(field, value, e.target.checked, exclude);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    }
  }, []);

  return (
    <div className='m-2 p-relative' ref={filterRef}>
      <FilterVisible onClick={() => setActive(!active)} active={active}>{label} <ArrowDownIcon className="mx-1" /></FilterVisible>
      <FilterOptions active={active}>
        {
          options?.map((option, i) => (
            <FilterOption key={i} description={(option.description)}>
              <div className="d-flex">
                <input
                  type={ exclude ? "radio" : "checkbox" }
                  name={ exclude ? field : option.label }
                  id={field + option.value + "-check"}
                  className="mr-2"
                  onChange={(e) => handleCheckChange(e, option.value)}
                  checked={option.selected}
                />

                <label htmlFor={field + option.value + "-check"} className="m-0">
                  <span>{option.label}</span>
                  <p>{option?.description}</p>
                </label>
              </div>
            </FilterOption>
          ))
        }
      </FilterOptions>
    </div>
  )
}

export default Filter;