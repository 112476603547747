import React from 'react';
import { MDBContainer } from "mdbreact"
import ConversationsIcon from '../../components/icons/pages/ConversationsIcon';
import { Description, Title } from '../Conversations';
import ChatForm from './components/ChatForm';

const Chat = ()=>{
    return (
        <MDBContainer className="mb-3">
            <section className='mx-4'>
                <div className="d-flex">
                    <ConversationsIcon />
                    <Title>Chat</Title>
                </div>
                <Description>Configuraciones generales para el comportamiento del chat</Description>
                <ChatForm />
            </section>
        </MDBContainer>
    )
}
export default Chat;