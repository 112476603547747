import React from 'react'

const OptionLoading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <option disabled={true}>Cargando...</option>
    )
  } else {
    return null;
  }
}

export default OptionLoading;