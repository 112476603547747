import React from 'react';

export const Canales = () => {
  return (
    <section class="section-preview text-center p-4">
      <h2>Selecciona un canal</h2>
      <div>
        <p>En desarrollo....</p>
      </div>
    </section>
  );
};
