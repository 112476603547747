import { useState, useEffect } from "react";

export const useColor = (color) => {
  const [colorCode, setColorCode] = useState("#C9CDD2");

  useEffect(() => {
    setColorCode(color === "orange" ? "#FA7820" : "#C9CDD2");
  }, [color]);

  return colorCode;

}