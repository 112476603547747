import React from "react";
import "../styles/style.css";

export const STATUS_LOGS = [
  {
    name: "Actualizar",
    value: "Update",
    className: "c-pill c-pill--light"
  },
  {
    name: "Crear",
    value: "Create",
    className: "c-pill c-pill--success"
  },
  {
    name: "Eliminar",
    value: "Delete",
    className: "c-pill c-pill--danger"
  },
  {
    name: "Consulta",
    value: "Get",
    className: "c-pill c-pill--success"
  },
];

const LogsStatusIndicator = ({ status }) => {
  return (
    <div>      
      <span className={
        STATUS_LOGS.find((s) => s.value === status)?.className
      }>
        {STATUS_LOGS.find((s) => s.value === status)?.name}
      </span>
    </div>
  );
};

export default LogsStatusIndicator;

