import React, { useEffect, useState } from "react";

import FragmentsIcon from "../../components/icons/pages/FragmentsIcon";
import Select from 'react-select'
import styled from "styled-components";
import Utils from "../../utils";
import ExcelExport from "../../components/ExcelExport";
import { getDateByTime } from "../../utils/Times";
import DateFilter from "../Conversations/components/filters/DateFilter";
import {
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
} from "mdbreact";
import Loading from "../../components/Loading/Loading";
import GraficaDonut from "../../components/Charts/GraficaDoughnut";
import useGet from "../../hooks/useGet";
import GraficaBarVertical from "../../components/Charts/GraficaBarVertical";

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;
export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;


let controller;

const assignTypes = {"assign-bot": "Asignación por bot",
  "assign-template": "Por plantilla",
  "assign-cole": "Cola de asignación",
  "manual-reassign": "Reasignacion Manual",
  "reassign-admin": "Reasignacion desde administrador",
}
const resolutionTypes = {
  "automatic-close": "Cierre automatico",
  "close-admin":"Cierrado desde administrador",
  "forced-manual-close": "Cerrado forzado",
  "assign-cole": "Cola de asignación",
  "manual-reassign": "Reasignacion Manual",
  "normal-manual-close": "Cerrado por asesor",
  "reassign-admin": "Reasignacion desde administrador",
}
function getStartAndEndOfWeek() {
  const today = new Date();

  // Obtiene el día actual de la semana (0 para domingo, 1 para lunes, etc.)
  const dayOfWeek = today.getDay();

  // Calcular la fecha de inicio (lunes)
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - ((dayOfWeek + 6) % 7));

  // Calcular la fecha de fin (domingo)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  // Formatear las fechas en el formato YYYY-MM-DD
  const formatDate = (date, zerohour = false) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan en 0
    const day = String(date.getDate()).padStart(2, "0");
    if (!zerohour) {
      return `${year}-${month}-${day}T${new Date()
        .getHours()
        .toString()
        .padStart(2, "0")}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }
    return `${year}-${month}-${day}T00:00:00`;
  };

  return {
    start: formatDate(startDate, true),
    end: formatDate(endDate),
  };
}
const dates = getStartAndEndOfWeek();

const AsessorsRecords = () => {
  const [since, setSince] = useState(dates.start);
  const [until, setUntil] = useState(dates.end);
  const [milliseconds, setMillisecond] = useState(1);
  const [loading, setLoading] = useState(false);
  const [attendedData, setAttendedData] = useState({});
  const [averageResponseTime,setAverageResponseTime]=useState([])
  const [averageAssignTime,setAverageAssignTime]=useState([])
  const [assignationsPerHour,setAssignationsPerHour]=useState([])
  const [assignationsByTypeAndOwner,setAssignationsByTypeAndOwner]=useState([])
  const [lastInteractionByAssessor,setLastInteractionByAssessor]=useState([])
  const [lastInteractionByContact,setLastInteractionByContact]=useState([])
  const [lastInteractionBy,setLastInteractionBy]=useState([])
  const [assignationsByResolutionAndOwner,setAssignationsByResolutionAndOwner]=useState([])
  const [assignationsByOwner,setAssignationsByOwner]=useState([])
  const [assignationsCount,setAssignationsCount]=useState(-1);
  const [excludeTemplates, setExcludeTemplates] = useState(false)
  const [group, setGroup] = useState('*');
  const [subgroup, setSubgroup] = useState('*');
  const [groups, setGroups] = useState({'Cargando...':{'Cargando...':''}});
  const [assessors, setAssessors] = useState({'Cargando...':{'Cargando...':''}});
  const [selectedAssessor, setSelectedAssessor] = useState('*');
 
  const handleGroupChange = (event)=>{
    setSubgroup('*');
    setGroup(event.target.value);
  }
  const handleAssessorChange = (data)=>{
    if(data){

      changeOwner(data.value)
    }else{
      setSelectedAssessor('*');
      changeOwner("*");
    }
    // setSelectedAssessor(event.target.value);
  }
  const handleSubgroupChange = (event)=>{
    setSubgroup(event.target.value);
  }
  const loadMessageData = (callback) => {
    if (milliseconds > 0 && milliseconds <= 2629800000) {
      getHourAssignData()
    }
  };
  useEffect(()=>{
    Utils.Petition.get(`/clients/users`, (response)=>{
      let asesorData=[]
      console.log(response.data)
      for (const key in response.data) {
        if (Object.hasOwnProperty.call(response.data, key)) {
          const element = response.data[key];
          asesorData.push({value:key?.trim()?.replace(/\./g, "")?.replace(/-/g, "")?.replace(/@/g, ""),label:key + " - "+ element?.name + " "+ element?.lastname})
        }
      }
      setAssessors(asesorData);
    })
    Utils.Petition.get(`/groups/get`, (response)=>{
      delete response.data.data_users;
      setGroups(response.data);
    })
  }, []);
  
  const getHourDate = (date) =>{
    return new Date(date).getHours()
  }
  const getHourAssignData = ()=>{
    setLoading(true);

    Utils.Petition.get(
      `/message-records/dataHoursAssign/start/${since}/end/${until}/group/${group}/subgroup/${subgroup}/exclude-templates/${excludeTemplates}`,
      (response) => {
        controller = null;
        setLoading(false);
        let countLastContact = 0;
        let countLastAssessor = 0;
        let assignCant = 0;
        let _averageResponseTime=[]
        let _averageAssignTime=[]
        let _assignationsPerHour=[]
        let _assignationsByTypeAndOwner=[]
        let _lastInteractionByAssessor=[]
        let _lastInteractionByContact=[]
        let _assignationsByResolutionAndOwner=[]
        let _assignationsByOwner=[]
        setAttendedData(response)
        if(response.data.length){
          for (let index = 0; index < response.data[0].assignationsByOwner.length; index++) {
            const element = response.data[0].assignationsByOwner[index];
            _assignationsByOwner.push({ key: element.owner, value: element.count });
          }
          for (let index = 0; index < response.data[0].averageResponseTime.length; index++) {
            const element = response.data[0].averageResponseTime[index];
            let countAssign=_averageResponseTime.find((e)=>e.key==element.owner)
            if(countAssign)_averageResponseTime.find((e)=>e.key==element.owner).value=countAssign.value+parseInt(element.averageResponseTimeInMinutes)
            else _averageResponseTime.push({key:element.owner,value:parseInt(element.averageResponseTimeInMinutes)})
          }
          for (let index = 0; index < response.data[0].averageAssignTime.length; index++) {
            const element = response.data[0].averageAssignTime[index];
            let countAssign=_averageAssignTime.find((e)=>e.key==element.owner)
            if(countAssign)_averageAssignTime.find((e)=>e.key==element.owner).value=countAssign.value+parseInt(element.averageAssignTimeInMinutes)
            else _averageAssignTime.push({key:element.owner,value:parseInt(element.averageAssignTimeInMinutes)})
          }

          for (let index = 0; index < response.data[0].assignationsByResolutionAndOwner.length; index++) {
            const element = response.data[0].assignationsByResolutionAndOwner[index];
            if(element?.assignResolution){
              let countAssign=_assignationsByResolutionAndOwner.find((e)=>e.key==resolutionTypes[element.assignResolution])
              if(countAssign)_assignationsByResolutionAndOwner.find((e)=>e.key==resolutionTypes[element.assignResolution]).value=countAssign.value+element.count
              else _assignationsByResolutionAndOwner.push({key:resolutionTypes[element.assignResolution],value:element.count})
            }
          }
          for (let index = 0; index < response.data[0].assignationsByTypeAndOwner.length; index++) {
            const element = response.data[0].assignationsByTypeAndOwner[index];
            if(element?.assignType){
              assignCant=assignCant+element.count
              let countAssign=_assignationsByTypeAndOwner.find((e)=>e.key==assignTypes[element.assignType])
              if(countAssign)_assignationsByTypeAndOwner.find((e)=>e.key==assignTypes[element.assignType]).value=countAssign.value+element.count
              else _assignationsByTypeAndOwner.push({key:assignTypes[element.assignType] ,value:element.count})
            }
          }
          for (let index = 0; index < response.data[0].assignationsPerHour.length; index++) {
            const element = response.data[0].assignationsPerHour[index];
            let countAssign=_assignationsPerHour.find((e)=>e.key==getHourDate(element.hour).toString())
            if(countAssign)_assignationsPerHour.find((e)=>e.key==getHourDate(element.hour).toString()).value=countAssign.value+element.count
            else _assignationsPerHour.push({key:getHourDate(element.hour).toString(),value:element.count})
          }
          for (let index = 0; index < response.data[0].lastInteractionByAssessor.length; index++) {
            const element = response.data[0].lastInteractionByAssessor[index];
            countLastAssessor=countLastAssessor + element.count
            let countAssign=_lastInteractionByAssessor.find((e)=>e.key==element.owner)
            if(countAssign)_lastInteractionByAssessor.find((e)=>e.key==element.owner).value=countAssign.value+element.count
            else _lastInteractionByAssessor.push({key:element.owner,value:element.count})
          }
          for (let index = 0; index < response.data[0].lastInteractionByContact.length; index++) {
            const element = response.data[0].lastInteractionByContact[index];
            countLastContact=countLastContact + element.count
            let countAssign=_lastInteractionByContact.find((e)=>e.key==element.owner)
            if(countAssign)_lastInteractionByContact.find((e)=>e.key==element.owner).value=countAssign.value+element.count
            else _lastInteractionByContact.push({key:element.owner,value:element.count})
          }
          
          if(countLastContact || countLastAssessor) setLastInteractionBy([{key:"Contacto",value:countLastContact},{key:"Asesor",value:countLastAssessor}])
          else setLastInteractionBy([])
          setAssignationsPerHour(_assignationsPerHour)
          setAssignationsByOwner(_assignationsByOwner)
          setAssignationsByResolutionAndOwner(_assignationsByResolutionAndOwner)
          setAssignationsByTypeAndOwner(_assignationsByTypeAndOwner)
          setAssignationsCount(assignCant)
          setLastInteractionByAssessor(_lastInteractionByAssessor)
          setLastInteractionByContact(_lastInteractionByContact)
          setAverageResponseTime(_averageResponseTime)
          setAverageAssignTime(_averageAssignTime)
          console.log("_assignationsPerHour",_assignationsPerHour)
          console.log("_assignationsByOwner",_assignationsByOwner)
          console.log("_assignationsByResolutionAndOwner",_assignationsByResolutionAndOwner)
          console.log("_assignationsByTypeAndOwner",_assignationsByTypeAndOwner)
          console.log("assignCant",assignCant)
          console.log("_lastInteractionByAssessor",_lastInteractionByAssessor)
          console.log("_lastInteractionByContact",_lastInteractionByContact)
        }
        // for (let index = 0; index < response.dataHourAssessors.length; index++) {
        //   const element = response.dataHourAssessors[index];
        //   let countAssign=assigns.find((e)=>e.key==getHourDate(element.first_assign_date))
        //   if(getHourDate(element.first_assign_date)>17 || getHourDate(element.first_assign_date)<8){
        //     console.log(element)
        //   }
        //   if(countAssign)assigns.find((e)=>e.key==getHourDate(element.first_assign_date).toString()).value=countAssign.value+1
        //   else assigns.push({key:getHourDate(element.first_assign_date).toString(),value:1})
        // }
        
        // setAssignCount(assigns);
      },
      (err) => {
        console.error("Error en la solicitud:", err);
        // Resetear el controlador incluso en caso de error
        controller = null;
      },
      () => {
        console.log("Solicitud completada");
      },
      controller
    );
  }
  const changeOwner =(asesor) =>{
    let response=attendedData
    if(response?.data?.length){
      let countLastContact = 0;
      let countLastAssessor = 0;
      let assignCant = 0;
      let _assignationsPerHour=[]
      let _assignationsByTypeAndOwner=[]
      let _lastInteractionByAssessor=[]
      let _lastInteractionByContact=[]
      let _assignationsByResolutionAndOwner=[]
      
      
      let resolutionData=response.data[0].assignationsByResolutionAndOwner?.filter((resolution)=>resolution.owner==asesor || asesor=="*")
      
      for (let index = 0; index < resolutionData.length; index++) {
        const element = resolutionData[index];
        if(element?.assignResolution){
          let countAssign=_assignationsByResolutionAndOwner.find((e)=>e.key==resolutionTypes[element.assignResolution])
          if(countAssign)_assignationsByResolutionAndOwner.find((e)=>e.key==resolutionTypes[element.assignResolution]).value=countAssign.value+element.count
          else _assignationsByResolutionAndOwner.push({key:resolutionTypes[element.assignResolution],value:element.count})
        }
      }

      let typeData=response.data[0].assignationsByTypeAndOwner?.filter((type)=>type.owner==asesor || asesor=="*")
      for (let index = 0; index < typeData.length; index++) {
        const element = typeData[index];
        if(element?.assignType){
          assignCant=assignCant+element.count
          let countAssign=_assignationsByTypeAndOwner.find((e)=>e.key==assignTypes[element.assignType])
          if(countAssign)_assignationsByTypeAndOwner.find((e)=>e.key==assignTypes[element.assignType]).value=countAssign.value+element.count
          else _assignationsByTypeAndOwner.push({key:assignTypes[element.assignType] ,value:element.count})
        }
      }
      
      let perHourData=response.data[0].assignationsPerHour?.filter((hour)=>hour.owner==asesor || asesor=="*")
      for (let index = 0; index < perHourData.length; index++) {
        const element = perHourData[index];
        let countAssign=_assignationsPerHour.find((e)=>e.key==getHourDate(element.hour).toString())
        if(countAssign)_assignationsPerHour.find((e)=>e.key==getHourDate(element.hour).toString()).value=countAssign.value+element.count
        else _assignationsPerHour.push({key:getHourDate(element.hour).toString(),value:element.count})
      }

      let lastInteractionAssessorData=response.data[0].lastInteractionByAssessor?.filter((interaction)=>interaction.owner==asesor || asesor=="*")
      console.log("filter",lastInteractionAssessorData)
      console.log("response",response.data[0].lastInteractionByAssessor)
      for (let index = 0; index < lastInteractionAssessorData.length; index++) {
        const element = lastInteractionAssessorData[index];
        console.log("element",element)
        countLastAssessor=countLastAssessor + element.count
        let countAssign=_lastInteractionByAssessor.find((e)=>e.key==element.owner)
        console.log("Count",countAssign)
        if(countAssign)_lastInteractionByAssessor.find((e)=>e.key==element.owner).value=countAssign.value+element.count
        else _lastInteractionByAssessor.push({key:element.owner,value:element.count})
      }

      let lastInteractionContactData=response.data[0].lastInteractionByContact?.filter((interaction)=>interaction.owner==asesor || asesor=="*")
      for (let index = 0; index < lastInteractionContactData.length; index++) {
        const element = lastInteractionContactData[index];
        countLastContact=countLastContact + element.count
        let countAssign=_lastInteractionByContact.find((e)=>e.key==element.owner)
        if(countAssign)_lastInteractionByContact.find((e)=>e.key==element.owner).value=countAssign.value+element.count
        else _lastInteractionByContact.push({key:element.owner,value:element.count})
      }
      if(countLastContact || countLastAssessor) setLastInteractionBy([{key:"Contacto",value:countLastContact},{key:"Asesor",value:countLastAssessor}])
      else setLastInteractionBy([])
      setAssignationsPerHour(_assignationsPerHour)
      setAssignationsByResolutionAndOwner(_assignationsByResolutionAndOwner)
      setAssignationsByTypeAndOwner(_assignationsByTypeAndOwner)
      setAssignationsCount(assignCant)
      console.log(_lastInteractionByAssessor)
      console.log(_lastInteractionByContact)
      setLastInteractionByAssessor(_lastInteractionByAssessor)
      setLastInteractionByContact(_lastInteractionByContact)
    }
  }
  const handleDatesFilter = (dates) => {
    setSince(dates.since);
    setUntil(dates.until);
    setMillisecond(dates.until - dates.since);
  };
  const formatExportData = (data)=>{
    try {
      
      return data.data.map((item,index)=>{
        const record =  {
          "Número de teléfono": item.identificatorCode ?? "Desconocido",
          "Fecha Asignación": new Date(item.date).toISOString().replace('T', ' ').replace(/\.\d+Z$/, ''),
          "Asesor": item.owner,
          "Grupo": item.group,
          "Subgrupo": item.subgroup,
          "Tipo de asignación": item.assignType ? assignTypes[item.assignType] : "",
          "Resolución": item?.resolution ? resolutionTypes[item.resolution] : "",
          "Fecha de resolución": item?.dateResolution ? new Date(item.dateResolution).toISOString().replace('T', ' ').replace(/\.\d+Z$/, '') :"",
          "Fecha primer mensaje de asesor": item?.firstMessageAssessor ? new Date(item.firstMessageAssessor).toISOString().replace('T', ' ').replace(/\.\d+Z$/, '') :"",
          "Fecha ultima interacción": item?.dateLastInteraction ? new Date(item.dateLastInteraction).toISOString().replace('T', ' ').replace(/\.\d+Z$/, '') :"",
          "Ultima Interacción": item?.lastActivityBy?? item?.lastActivityBy == "contact" ? "Contacto" : "Asesor" ,
        }
        return record
      })
    } catch (error) {
      console.log(error)
    }

  
  }
  const handleGetExportData = async (callback)=>{
    Utils.Petition.get(`/message-records/dataAssignDetails/start/${since}/end/${until}/group/${group}/subgroup/${subgroup}/exclude-templates/${excludeTemplates}`, (response)=>{
      callback(response)
    })
  }

  
  
  return (
    <section className="mx-4 mb-4">
      <div className="d-flex">
        <Title>Asignación de asesores</Title>
      </div>
      <div>
        <div className='d-flex'>
          <div>
            <DateFilter
              setDateFilter={handleDatesFilter}
              since={since}
              until={until}
            />
          </div>
          <div className='ml-3'>
            <div className="d-flex align-items-center">
              <div className="group">
                <label htmlFor="group">Grupo: </label>
                <select
                  className="form-control p-2"
                  id="group"
                  onChange={handleGroupChange}
                >
                  <option value="*">Todos</option>
                  {
                    Object.keys(groups).map((group)=><option value={group}>{group}</option>)
                  }
                </select>
              </div>
              <div className="subgroup ml-2">
                <label htmlFor="subgroup">Subgrupo: </label>
                <select
                  className="form-control p-2"
                  id="subgroup"
                  onChange={handleSubgroupChange}
                >
                  <option value="*">Todos</option>
                  {
                    group !== '*' &&
                    Object.keys(groups[group]).map((subgroup)=><option value={subgroup}>{subgroup}</option>)
                  }
                </select>
              </div>
              
            </div>
          </div>
        </div>
        <div class="subgroup">
          <label>
            <input checked={excludeTemplates} type="checkbox" onChange={()=>setExcludeTemplates(!excludeTemplates)} /> Excluir asignaciones de plantilla
          </label>
        </div>
        {milliseconds > 0 && milliseconds <= 2629800000 && (
          <>
            <button
              className="btn btn-orange d-inline  btn-sm"
              onClick={loadMessageData}
              disabled={ loading ? true : false}
            >
              Cargar datos
            </button>
            
            {
              !loading &&
              <ExcelExport asyncData={true} handleData={handleGetExportData} formatData={formatExportData} fileName={"Gestión Asesores"}/>
            }
         
          </>
        )}
        
      </div>

      {(milliseconds <= 0 || milliseconds > 2629800000) && (
        <MDBAlert color="danger">
          La fecha de inicio debe ser menor que la fecha de fin y no puede
          superar mas de una semana.
        </MDBAlert>
      )}
      <MDBRow>
        <MDBCol lg="12" className="mt-4">
          {!loading && Object.keys(assignationsByOwner).length > 0 && <h5># Asignaciones por asesor</h5> }
          {
            !loading && Object.keys(assignationsByOwner).length > 0 &&
            <MDBCard >
              <MDBCardBody >
                <GraficaBarVertical dataItems={[assignationsByOwner]} title="" height={200} subtitle="Numero de asignaciones"/>
              </MDBCardBody>
            </MDBCard>
          }
          {
            loading && <Loading />
          }
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg="12" className="mt-4">
          {!loading && Object.keys(averageResponseTime).length > 0 && <h5>Promedio velocidad de atención (Tiempo en responder el primer mensaje)</h5> }
          {
            !loading && Object.keys(averageResponseTime).length > 0 &&
            <MDBCard >
              <MDBCardBody >
                <GraficaBarVertical dataItems={[averageResponseTime]} title="" height={200} subtitle="Minutos"/>
              </MDBCardBody>
            </MDBCard>
          }
          {
            loading && <Loading />
          }
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg="12" className="mt-4">
          {!loading && Object.keys(averageAssignTime).length > 0 && <h5>Promedio de duración de la asignación</h5> }
          {
            !loading && Object.keys(averageAssignTime).length > 0 &&
            <MDBCard >
              <MDBCardBody >
                <GraficaBarVertical dataItems={[averageAssignTime]} title="" height={200} subtitle="Minutos"/>
              </MDBCardBody>
            </MDBCard>
          }
          {
            loading && <Loading />
          }
        </MDBCol>
      </MDBRow>
      {!loading && assignationsCount>=0  && <div className="owner mt-4">
        <label htmlFor="owner">Asesor: </label>
        <Select options={assessors} value={selectedAssessor} isClearable={true} onChange={(data)=>{setSelectedAssessor(data);handleAssessorChange(data)}} />
      </div>}
      <MDBRow className="mt-4">
        <MDBCol>
          {!loading && assignationsCount>=0  && (
            <h5>Cantidad de asignaciones</h5>
          )}
          {!loading && assignationsCount>=0  && (
            <MDBCard>
              <MDBCardBody>
                <div className="text-center">
                  <strong>{assignationsCount}</strong>
                  <br />
                  Asignaciones
                </div>
              </MDBCardBody>
            </MDBCard>
          )}
          {loading && <Loading />}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-4">
        <MDBCol md="4" >
          {!loading && Object.keys(lastInteractionBy).length > 0 && <h5>Ultimo mensaje por</h5>}
          {!loading && Object.keys(lastInteractionBy).length > 0 && (
            <MDBCard>
              <MDBCardBody>
                <div className="text-center">
                  <GraficaDonut dataItems={lastInteractionBy} title={[""]} labelPosition="top"/>
                 
                </div>
              </MDBCardBody>
            </MDBCard>
          )}
        </MDBCol>
        <MDBCol md="4" >
          {!loading && Object.keys(assignationsByTypeAndOwner).length > 0 && <h5>Por tipo de asignación</h5>}
          {!loading && Object.keys(assignationsByTypeAndOwner).length > 0 && (
            <MDBCard>
              <MDBCardBody>
                <div className="text-center">
                  <GraficaDonut dataItems={assignationsByTypeAndOwner} title={[""]} labelPosition="top"/>
                 
                </div>
              </MDBCardBody>
            </MDBCard>
          )}
        </MDBCol>
        <MDBCol md="4" >
          {!loading && Object.keys(assignationsByResolutionAndOwner).length > 0 && <h5>Gestion</h5>}
          {!loading && Object.keys(assignationsByResolutionAndOwner).length > 0 && (
            <MDBCard>
              <MDBCardBody>
                <div className="text-center">
                  <GraficaDonut dataItems={assignationsByResolutionAndOwner} title={[""]} labelPosition="top"/>
                </div>
              </MDBCardBody>
            </MDBCard>
          )}
        </MDBCol>
      </MDBRow>
      
      
      <MDBRow className="mt-4">
          <MDBCol lg="12"  >
              { !loading && Object.keys(assignationsPerHour).length > 0 && <h5>Asignaciones por hora</h5> }
              {
                !loading && Object.keys(assignationsPerHour).length > 0 &&
                <MDBCard >
                  <MDBCardBody >
                    <GraficaBarVertical dataItems={[assignationsPerHour]} title="" hours={true} subtitle="Numero de asignaciones"/>
                  </MDBCardBody>
                </MDBCard>
              }
              {
                loading && <Loading />
              }
              
          </MDBCol>
      </MDBRow>  
    </section>
  );
};
export default AsessorsRecords;
