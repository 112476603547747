import React from 'react'

const ConversationsHomeIcon = (props) => {
  return (
    <svg width="209" height="172" viewBox="0 0 219 182" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.2791 34.8849C6.85322 34.8883 4.5273 35.8552 2.8131 37.5728C1.09891 39.2903 0.138723 41.6199 0.142099 44.0457V131.27C0.138718 133.698 1.09891 136.025 2.8131 137.743C4.52726 139.462 6.85339 140.429 9.2791 140.431H18.974L21.0867 168.741C21.4631 173.784 27.527 176.128 31.1975 172.649L65.1905 140.431H133.97C136.4 140.429 138.729 139.464 140.447 137.747C142.164 136.029 143.13 133.699 143.131 131.27V117.673H84.4306C78.7894 117.667 73.3816 115.422 69.3936 111.434C65.404 107.444 63.1609 102.036 63.1522 96.395V34.8856L9.2791 34.8849Z" fill="#FEE1CD" />
      <path d="M153.201 105.552L187.199 137.776C190.869 141.255 196.933 138.912 197.31 133.869L199.428 105.552H209.102C211.53 105.552 213.859 104.586 215.577 102.871C217.293 101.153 218.258 98.8251 218.258 96.3959V9.15405C218.258 6.72648 217.293 4.39706 215.577 2.68113C213.859 0.96356 211.53 -0.00168558 209.102 2.21639e-06H84.4258C81.9982 -0.00168818 79.6688 0.963604 77.9512 2.68113C76.2353 4.39697 75.27 6.72653 75.27 9.15405V96.3959C75.27 98.8251 76.2353 101.153 77.9512 102.871C79.6687 104.586 81.9982 105.552 84.4258 105.552H153.201Z" fill="#FEE1CD" />
      <line x1="104" y1="40" x2="189" y2="40" stroke="white" strokeWidth="12" strokeLinecap="round" />
      <line x1="136" y1="64" x2="189" y2="64" stroke="white" strokeWidth="12" strokeLinecap="round" />
    </svg>

  )
}

export default ConversationsHomeIcon;