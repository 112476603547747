import { MDBContainer } from "mdbreact";
import React, { useEffect, useState } from "react";

import { AlertForm, ICON } from "../../utils/Alert";
import Utils from "../../utils";
import ConversationsIcon from "../../components/icons/pages/ConversationsIcon";
import styled from "styled-components";
import useGet from "../../hooks/useGet";
import Select from 'react-select'

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;

export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [countUsers, setCountUsers] = useState(0)
  const [rol, setRol] = useState("user")
  const [limitUsers, setLimitUsers] = useState(0)
  const [revisor, setRevisor] = useState();

  const { data } = useGet({
    route: "/clients/users",
  });
  
  const [isUpdating, setIsUpdating] = useState(false);
  const clearUser = {
    email: "",
    name: "",
    lastname: "",
    service: {
      Conversations: {
        "Chat-HubSpot-WhatsApp": {
          "date-payment": "",
          "last-payment": "",
          "next-payment": "",
          active: true,
          default: false,
        },
      },
    },
    default_group: "",
    default_subgroup: "",
    lines:[]
  };
  const [newUser, setNewUser] = useState(JSON.parse(JSON.stringify(clearUser)));

  // RQF - multiples lineas telefonicas a un usuario
  const [ phones, setPhones ] = useState([]);
  const [assessors, setAssessors] = useState({value:"",label:"Cargando..."});
      
  const { data:connectionBusiness } = useGet({
    route: "/connectionBusiness/current_business",
  });

  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState([]);

  const handleChangeOpcionesSeleccionadasNewUser = (e) => {
    const { value } = e.target;
    let nuevasOpcionesSeleccionadas = [...opcionesSeleccionadas];
    if (e.target.checked) {
      nuevasOpcionesSeleccionadas.push(value);
    } else {
      nuevasOpcionesSeleccionadas = nuevasOpcionesSeleccionadas.filter(opcion => opcion !== value);
    }
    setOpcionesSeleccionadas(nuevasOpcionesSeleccionadas);
    setNewUser({
      ...newUser,
      lines: nuevasOpcionesSeleccionadas
    });
  }
  const checks = async function (event, index) {
    const value  = event.target.checked;
    let tempUsers = JSON.parse(JSON.stringify(users));
    const user = tempUsers[index];
    user[Object.keys(user)[0]].reviewer=value 
    setUsers(tempUsers)
    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)
  }
  const handleChangeOpcionesSeleccionadas = (event, index) => {

    const { value } = event.target;


    let tempUsers = JSON.parse(JSON.stringify(users));
    const user = tempUsers[index];

    if (user[Object.keys(user)[0]].lines == undefined) {
      user[Object.keys(user)[0]].lines = []
    }
    if (event.target.checked) {
      user[Object.keys(user)[0]].lines.push(value)
    } else {
      user[Object.keys(user)[0]].lines = user[Object.keys(user)[0]].lines.filter(opcion => opcion !== value);
    }

    setUsers(tempUsers)

    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)

  }

  useEffect( () => {
    if (connectionBusiness) {
        const telefonos = []
        for (let i = 0; i < connectionBusiness.configsProduction.length; i++) {
          telefonos.push({
            id:connectionBusiness.configsProduction[i]._id,
            name:connectionBusiness.configsProduction[i].name,
            phone:connectionBusiness.configsProduction[i].phone
          })
        }
        setPhones(telefonos)
    }
  },[connectionBusiness]);
  function phoneLineExists(line) {
    return phones.some(function(el) {
      return el.id === line;
    }); 
  }
  const clearLines = (users)=>{

    for(let i in users){
      for(let email in users[i] ){
        let alreadyExistingLines=[];
        for(let j=users[i][email].lines.length-1; j>=0; j-- ){
          if(phoneLineExists(users[i][email].lines[j]) && alreadyExistingLines.indexOf(users[i][email].lines[j]) === -1){
            alreadyExistingLines.push(users[i][email].lines[j]);
          }else{
            users[i][email].lines.splice(j,1);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (data) {
      const usersArray = [];
      
      Utils.Petition.get(`/clients/users-hs`, (res)=>{
        setAssessors(res.data);
        for (const [key, value] of Object.entries(data)) {
          const user = {
            [key]: {
              name: value.name,
              lastname: value.lastname,
              service: value.service,
              default_group: value.default_group,
              default_subgroup: value.default_subgroup,
              lines:value.lines,
              reviewer:value.reviewer||false
            },
          };
          usersArray.push(user);
        }
        
        setCountUsers(usersArray.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)
        setLimitUsers(res.limit)
        setUsers(usersArray);
        setRol(res?.rol||"user");
      })
    }
  }, [data]);

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleKeyPressRegex = (event) => {
    
    const regex = /^[A-Za-z0-9\-]+$/;

    if (!regex.test(event.key)) {
      event.preventDefault();
    }
    
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChangeEmail = (event, index) => {
    let tempUsers = JSON.parse(JSON.stringify(users));
    const user = tempUsers[index];
    const newKey = event.value;
    let name=event.label?.split("-")[1]
    let lastname=name.split("  ")[1]
    name=name?.split("  ")[0]
    const oldKey = Object.keys(user)[0];

    if (oldKey !== newKey) {
      user[newKey] = user[oldKey];
      delete user[oldKey];
    }

    user[newKey].email = event.value;
    user[newKey].name = name?.trim();
    user[newKey].lastname = lastname;

    setUsers(tempUsers);
    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)

    localStorage.setItem('updated', 'true');
  };

  const handleNameChange = (event, index) => {
    let tempUsers = JSON.parse(JSON.stringify(users));
    const user = tempUsers[index];
    user[Object.keys(user)[0]].name = event.target.value;
    setUsers(tempUsers);
    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)
    localStorage.setItem('updated', 'true');
  };

  const handleLastNameChange = (event, index) => {
    let tempUsers = JSON.parse(JSON.stringify(users));
    const user = tempUsers[index];
    user[Object.keys(user)[0]].lastname = event.target.value;
    setUsers(tempUsers);
    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)

    localStorage.setItem('updated', 'true');
  };

  const replaceSpaces = (text) => {
    return text.replace(/\s+/g, "-");
  };

  const handleChangeGroup = (event, index) => {
    let tempUsers = JSON.parse(JSON.stringify(users));
    const user = tempUsers[index];
    user[Object.keys(user)[0]].default_group = replaceSpaces(
      event.target.value
    );
    setUsers(tempUsers);
    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)

    localStorage.setItem('updated', 'true');
  };

  const handleChangeSubgroup = (event, index) => {
    let tempUsers = JSON.parse(JSON.stringify(users));
    const user = tempUsers[index];
    user[Object.keys(user)[0]].default_subgroup = replaceSpaces(
      event.target.value
    );
    setUsers(tempUsers);
    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)

    localStorage.setItem('updated', 'true');
  };

  const handleDeleteUser = (index) => {
    let tempUsers = JSON.parse(JSON.stringify(users));
    let [deleteUser] = tempUsers.splice(index, 1);
    setUsers(tempUsers);
    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)

    localStorage.setItem('updated', 'true');
    Utils.Petition.logs({data: deleteUser, endpoint: "N/A", type: "Delete", register: "Gestión de Usuarios: Eliminar usuario (Temporal)" });
  };

  const isDataValid = () => {
    let isValid = true;
    let message = "";

    // verificar que los correos sean válidos y no esten repetidos

    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      const email = Object.keys(user)[0];

      if (!isValidEmail(email)) {
        isValid = false;
        message = "Hay correos inválidos";
        break;
      }

      if (phones.length) {

        if (user[email].lines == undefined) {
          isValid = false;
          message = "Debes de establecer por lo menos una Linea Teléfonica para los Usuarios";
          break;
        }

        if (user[email].lines != undefined && user[email].lines.length === 0 ) {
          isValid = false;
          message = "Debes de establecer por lo menos una Linea Teléfonica para los Usuarios";
          break;
        }

      }
      for (let j = i + 1; j < users.length; j++) {
        const user2 = users[j];
        const email2 = Object.keys(user2)[0];

        if (email === email2) {
          isValid = false;
          message = "Hay correos repetidos";
          break;
        }
      }
    }

    return { isValid, message };
  };

  const formatUsers = () => {
    let _users = JSON.parse(JSON.stringify(users));
    clearLines(_users);
    const usersObject = {};
    for (const user of _users) {
      const email = Object.keys(user)[0];
      usersObject[email] = user[email];
    }

    return usersObject;
  };

  const updateUsers = () => {
    setIsUpdating(true);
    const data = formatUsers();
    const endpoint = "/clients/users";
    
    Utils.Petition.patch(
      endpoint,
      data,
      (response) => {
        setIsUpdating(false);
        AlertForm({
          title: "¡Todo salió correctamente!",
          text: `${response.message}`,
          icon: ICON.SUCCESS,
          position: "top-end",
        });
        localStorage.setItem('updated', '');
        Utils.Petition.logs({data, endpoint, type: "Update", register: "Gestión de Usuarios: Actualizar Acceso al chat" });
      },
      (err) => {
        console.warn("Error actualizando usuarios..", err.message);
        setIsUpdating(false);
      }
    );
    
  };

  const saveChanges = () => {
    const { isValid, message } = isDataValid();

    if (!isValid) {
      AlertForm({
        title: "¡Espera!",
        text: `${message}. Por favor verifica los campos.`,
        icon: ICON.ERROR,
        position: "top-end",
      });
      return;
    }

    updateUsers();
  };

  const handleChangeEmailNewUser = (event) => {
    let name=event.label?.split("-")[1]
    let lastname=name.split("  ")[1]
    name=name?.split("  ")[0]
  
    setNewUser({
      ...newUser,
      name: name?.trim(),
      lastname: lastname,
      email: event.value,
    });
    localStorage.setItem('updated', 'true');
  };

  const handleNameChangeNewUser = (event) => {
    setNewUser({
      ...newUser,
      name: event.target.value,
    });
    localStorage.setItem('updated', 'true');
  };

  const handleLastNameChangeNewUser = (event) => {
    setNewUser({
      ...newUser,
      lastname: event.target.value,
    });
    localStorage.setItem('updated', 'true');
  };
  const handleReviewer = (event) => {
    setNewUser({
      ...newUser,
      reviewer: event.target.value,
    });
    localStorage.setItem('updated', 'true');
  };
  const handleChangeGroupNewUser = (event) => {
    setNewUser({
      ...newUser,
      default_group: replaceSpaces(event.target.value),
    });
    localStorage.setItem('updated', 'true');
  };

  const handleChangeSubgroupNewUser = (event) => {
    setNewUser({
      ...newUser,
      default_subgroup: replaceSpaces(event.target.value),
    });
    localStorage.setItem('updated', 'true');
  };

  const resetNewUserForm = () => {
    setNewUser(JSON.parse(JSON.stringify(clearUser)));
  }

  const addNewUser = () => {
    if (!isValidEmail(newUser.email)) {
      AlertForm({
        title: "¡Espera!",
        text: `El correo no es válido. Por favor verifica los campos.`,
        icon: ICON.ERROR,
        position: "top-end",
      });
      return;
    }

    if (phones.length && opcionesSeleccionadas.length<1) {
      AlertForm({
        title: "¡Espera!",
        text: `Debes seleccionar por lo menos una (1) linea Teléfonica`,
        icon: ICON.ERROR,
        position: "top-end",
      });
      return;
    }
    if(users?.filter((e)=>Object.keys(e)[0]==newUser.email)?.length){
      AlertForm({
        title: "¡Espera!",
        text: `Este correo ya esta registrado. Por favor verifica los campos.`,
        icon: ICON.ERROR,
        position: "top-end",
      });
      return;
    }
    let tempUsers = JSON.parse(JSON.stringify(users));
    tempUsers.push({ [newUser.email]: newUser });
    setOpcionesSeleccionadas([])
    setUsers(tempUsers);    
    setCountUsers(tempUsers.filter(e=>!e[Object.keys(e)[0]]?.reviewer)?.length??0)
    
    resetNewUserForm();
  };

  return (
    <MDBContainer>
      <section className="mx-4">
        <div className="d-flex">
          <ConversationsIcon />
          <Title>Gestión de usuarios</Title>
        </div>
       {( limitUsers!=0) && <Description>Usuarios {countUsers}/{limitUsers}</Description>}
        <div>
            <button
              disabled={isUpdating}
              className="btn btn-sm btn-orange mb-3"
              onClick={saveChanges}
            >
              {!isUpdating ? "Guardar cambios" : "Guardando cambios..."}
            </button>
        </div>
        {
            ( limitUsers>countUsers|| rol=="admin")&&
            <div className="accordion mb-3" id="accordionNewUser">
              <div className="card">
                <div className="card-header" id="headingNewUser">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseNewUser"
                      aria-expanded="true"
                      aria-controls="collapseNewUser"
                    >
                      <i className="fa fa-plus-circle mr-2"></i> Crear nuevo
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseNewUser"
                  className="collapse"
                  aria-labelledby="headingNewUser"
                  data-parent="#accordionNewUser"
                >
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label htmlFor="email">Usuario</label>
                            <Select
                                onChange={(event) => handleChangeEmailNewUser(event)}
                                options={assessors}
                                placeholder="Seleccione un usuario"
                                value={{value:newUser.email,label:newUser.email}}
                            />
                            {/* <input
                              type="email"
                              className={`form-control ${
                                (newUser.email && !isValidEmail(newUser.email)) ? "is-invalid" : ""
                              }`}
                              value={newUser.email}
                              onKeyDown={handleKeyPress}
                              onChange={(event) => handleChangeEmailNewUser(event)}
                              required
                              placeholder="Correo electrónico"
                            /> */}
                            {newUser.email && !isValidEmail(newUser.email) && (
                              <p
                                style={{
                                  color: "red",
                                  marginTop: 20,
                                  marginLeft: 10,
                                }}
                              >
                                {" "}
                                Formato de correo inválido
                              </p>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="firstName">Nombre</label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              id="firstName"
                              name="firstName"
                              value={newUser.name}
                              onChange={(event) => {
                                handleNameChangeNewUser(event);
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="lastname">Apellido</label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              name="lastname"
                              value={newUser.lastname}
                              onChange={(event) => {
                                handleLastNameChangeNewUser(event);
                              }}
                            />
                          </div>

                        </div>
                      </div>
                      {
                        rol=="admin" &&
                        <div className="form-group">
                          <div className="form-check" >
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={newUser.reviewer}
                                onChange={(event) => {
                                  handleReviewer(event);
                                }}
                              />
                              <strong>Es usuario revisor.</strong>
                            </label>
                          </div>
                        </div>        
                      }
                      {/* telefonos de usuarios*/}
                      <div className="form-group">
                      { phones.length > 0 && <label htmlFor="firstName">Selección Linea Teléfonica de atención</label> }  
                        
                        {
                          phones.map(opcion => (
                            <div className="form-check" key={opcion.id}>
                              <label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={opcion.id}
                                  checked={newUser.lines.includes(opcion.id)}
                                  onChange={handleChangeOpcionesSeleccionadasNewUser}
                                />
                                <strong>{opcion.name + ': ' + opcion.phone}</strong>
                              </label>
                            </div>
                            ))                        
                        }
                      </div>

                      <button
                        type="button"
                        className="btn btn-sm btn-orange"
                        onClick={addNewUser}
                        disabled={
                          newUser.email === "" ||
                          !isValidEmail(newUser.email) ||
                          newUser.name === ""
                        }
                      >
                        Agregar
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div> 
        }

        <div className="accordion mb-3" id="usersAccordion">
          {users.map((user, index) => (
            <div key={index} className="card">
              <div className="card-header" id={`card-${index}`}>
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapse-${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse-${index}`}
                  >
                    {Object.keys(user)[0]}
                    {user[Object.keys(user)[0]].reviewer && <span> (Revisor)</span>}
                  </button>
                </h2>
              </div>

              <div
                id={`collapse-${index}`}
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#usersAccordion"
              >
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group">
                          <label htmlFor="email">Usuario</label>
                          {
                            !user[Object.keys(user)[0]]?.reviewer &&
                            <Select
                              options={assessors}
                              value={{value:Object.keys(user)[0],label:Object.keys(user)[0]}}
                              onChange={(event) =>
                                handleChangeEmail(event, index)
                              }
                              placeholder="Seleccione un usuario"
                            />
                          }
                          {user[Object.keys(user)[0]]?.reviewer &&
                            <input
                              disabled
                              type="email"
                              className={`form-control`}
                              value={Object.keys(user)[0]}
                            />
                          }
                          {/* <input
                            type="email"
                            className={`form-control ${
                              !isValidEmail(Object.keys(user)[0])
                                ? "is-invalid"
                                : ""
                            }`}
                            value={Object.keys(user)[0]}
                            onKeyDown={handleKeyPress}
                            onChange={(event) =>
                              handleChangeEmail(event, index)
                            }
                            required
                          /> */}
                          {!isValidEmail(Object.keys(user)[0]) && (
                            <p
                              style={{
                                color: "red",
                                marginTop: 20,
                                marginLeft: 10,
                              }}
                            >
                              {" "}
                              Formato de correo inválido
                            </p>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="firstName">Nombre</label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            disabled={user[Object.keys(user)[0]]?.reviewer}
                            id="firstName"
                            name="firstName"
                            value={user[Object.keys(user)[0]].name}
                            onChange={(event) => {
                              handleNameChange(event, index);
                            }}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="lastname">Apellido</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            name="lastname"
                            disabled={user[Object.keys(user)[0]]?.reviewer}
                            value={user[Object.keys(user)[0]].lastname}
                            onChange={(event) => {
                              handleLastNameChange(event, index);
                            }}
                          />
                        </div>
                        {
                          rol=="admin" &&
                          <div className="form-group">
                            <div className="form-check" >
                              <label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={user[Object.keys(user)[0]]?.reviewer}
                                  onChange={(event) => {
                                    checks(event, index);
                                  }}
                                />
                                <strong>Es usuario revisor</strong>
                              </label>
                            </div>
                          </div> 
                        }
                        <div className="form-group">
                            { phones.length >0 && !user[Object.keys(user)[0]]?.reviewer && <label htmlFor="firstName">Linea(s) de atención Teléfonica:</label>}

                          {
                           !user[Object.keys(user)[0]]?.reviewer && phones.map(opcion => (
                              <div className="form-check" key={opcion.id}>
                                <label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={opcion.id}
                                    checked={user[Object.keys(user)[0]].lines && user[Object.keys(user)[0]].lines.includes((opcion.id))}
                                    onChange={(event) => {
                                      handleChangeOpcionesSeleccionadas(event, index);
                                    }}
                                  />
                                  <strong>{opcion.name + ': ' + opcion.phone}</strong>
                                </label>
                              </div>
                            ))                        
                          }

                        </div>
                      </div>
                    </div>

                    {user[Object.keys(user)[0]].service.default && !user[Object.keys(user)[0]]?.reviewer ? (
                      <div className="alert alert-info" role="alert">
                        <strong>No puedes eliminar este usuario.</strong> 
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => handleDeleteUser(index)}
                      >
                        Eliminar
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </MDBContainer>
  );
};

export default UsersManagement;

