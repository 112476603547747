import React from 'react'

const FragmentsIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.3334 3.3335H6.66671C4.83337 3.3335 3.35004 4.8335 3.35004 6.66683L3.33337 36.6668L10 30.0002H33.3334C35.1667 30.0002 36.6667 28.5002 36.6667 26.6668V6.66683C36.6667 4.8335 35.1667 3.3335 33.3334 3.3335ZM28.3334 23.3335H11.6667C10.75 23.3335 10 22.5835 10 21.6668C10 20.7502 10.75 20.0002 11.6667 20.0002H28.3334C29.25 20.0002 30 20.7502 30 21.6668C30 22.5835 29.25 23.3335 28.3334 23.3335ZM28.3334 18.3335H11.6667C10.75 18.3335 10 17.5835 10 16.6668C10 15.7502 10.75 15.0002 11.6667 15.0002H28.3334C29.25 15.0002 30 15.7502 30 16.6668C30 17.5835 29.25 18.3335 28.3334 18.3335ZM28.3334 13.3335H11.6667C10.75 13.3335 10 12.5835 10 11.6668C10 10.7502 10.75 10.0002 11.6667 10.0002H28.3334C29.25 10.0002 30 10.7502 30 11.6668C30 12.5835 29.25 13.3335 28.3334 13.3335Z" fill="#FA7820" />
    </svg>
  )
}

export default FragmentsIcon;