import { MDBContainer } from "mdbreact";
import React from "react";
import FragmentCard from "./components/FragmentCard";
import { Description, Title } from "../Conversations";
import FragmentsIcon from "../../components/icons/pages/FragmentsIcon";
import useGet from "../../hooks/useGet";
import FragmentForm from "./components/FragmentForm";
import GroupsContextProvider from "../../Context/Groups";
import { useState, useEffect } from "react";
import { FragmentsContainer } from "./styles";
import CardLoading from "../../components/Loading/CardLoading";
import TextFilter from "../../components/filters/TextFilter";

const Fragments = () => {
  const {
    data: fragments,
    isLoading,
    error,
    reload,    
  } = useGet({ route: "/fragments/get" });

  const [formIsOpen, setFormIsOpen] = useState(false);
  const [editing, setEditing] = useState(null);
  const [filteredFragments, setFilteredFragments] = useState(fragments);

  useEffect(() => {
    if (fragments) {
      setFilteredFragments(fragments);
    }
  }, [fragments]);

  const handleEdit = (fragment) => {
    setEditing(fragment);
    setFormIsOpen(true);
  };

  const handleTextFilter = (valueToFind) => {
    if (!valueToFind) {
      if (fragments) {
        setFilteredFragments(fragments);
      }
    } else {
      if (fragments) {
        setFilteredFragments(
          fragments.filter((fragment) =>
            fragment.name.toLowerCase().includes(valueToFind.toLowerCase())
          )
        );
      }
    }
  };

  return (
    <GroupsContextProvider>
      <MDBContainer className="mb-3">
        <section className="mx-4">
          <div className="d-flex">
            <div>
              <div className="d-flex">
                <FragmentsIcon />
                <Title>Fragmentos</Title>
              </div>
              <Description>Configurables según grupos/subgrupos</Description>
            </div>

            <div className="ml-auto">
              <button
                className="btn btn-orange"
                onClick={() => setFormIsOpen(true)}
              >
                Crear nuevo fragmento
              </button>
            </div>
          </div>

          {!isLoading && (
            <div className="d-flex flex-row mb-4 justify-content-between align-items-center">
              <TextFilter
                setTextFilter={handleTextFilter}
                filterAutomatically={true}
              />

              <Description>
                Mostrando{" "}
                <span className="black-text">{filteredFragments?.length}</span>{" "}
                de <span className="black-text">{fragments?.length}</span>
              </Description>
            </div>
          )}

          <FragmentsContainer>
            {filteredFragments?.map((fragment) => (
              <FragmentCard
                _id={fragment._id}
                key={fragment._id}
                name={fragment.name}
                url={fragment.url}
                body={fragment.body}
                group={fragment.group}
                subgroup={fragment.subgroup}
                groups={fragment.groups} 
                handleEdit={() => handleEdit(fragment)}
                reloadData={reload}
              />
            ))}

            {filteredFragments && filteredFragments?.length === 0 && (
              <div>No se han encontrado fragmentos.</div>
            )}

            {isLoading && (
              <>
                <CardLoading lines={5} />
                <CardLoading lines={5} />
                <CardLoading lines={5} />
                <CardLoading lines={5} />
              </>
            )}

            {error && <div>Upss, Ha ocurrido un error.</div>}
          </FragmentsContainer>
        </section>

        <FragmentForm
          fragment={editing}
          setEditedFragment={setEditing}
          isOpen={formIsOpen}
          setOpen={setFormIsOpen}
          reloadData={reload}
        />
      </MDBContainer>
    </GroupsContextProvider>
  );
};

export default Fragments;

