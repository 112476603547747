import React from 'react';
import { Row } from "../../../components/tables/styles";
const RecordRow = ({sms, setCurrentSMSSelect, active}) => {
    return (
        <Row 
            active={active}
            onClick={() => {
                setCurrentSMSSelect(sms);
            }}
        >
            <td>{sms.workflow}</td>
            <td>{sms.ok}</td>
            <td>{sms.error}</td>
        </Row>
    )
}
export default RecordRow;