import React from 'react'
import styled from 'styled-components';
import { getHourByTime } from '../../../../utils/Times';
import CallActionIcon from './CallActionIcon';

const ChatCall = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;

  &::after {
    content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      width: 300px;
      background: linear - gradient(45deg, transparent, var(--border - color), transparent);
      margin: auto;
  }

  > .section-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
  }

  .img-call-action {
    svg {
      width: 20px;
      path {
        &.not-fill {
          stroke: var(--theme-color);
        }

        &:not(.not-fill) {
          fill: var(--theme-color);
          stroke: var(--theme-color);
        }
      }

    }
  }

`

const Call = ({ message: messageJSON, time }) => {
  
  const message = JSON.parse(messageJSON);
  const { duration, actions } = message;

  return (
    <ChatCall>
      <div className="section-actions">
        <div className="img-call-action"><CallActionIcon action={actions}/> </div>
        <div> <span><b>{actions}</b> </span> </div>
      </div>
      <div>
        <span>{duration === 'N/A' ? '' : duration}</span>
      </div>
      <div><small>Hora: {getHourByTime(time)}</small></div>
    </ChatCall>
  )
}

export default Call;