import React from 'react'
import styled from 'styled-components';

const ItemConventionRow = styled.li`
  display:flex;
  margin-bottom:5px;
  .square-convention{
    width:25px;
    height:25px;
    margin-right:5px;
    border:1px solid #ccc;
  }
`;
const Conventions = ({conventionsData}) => {
 
  return (

      <ul className='list-unstyled ml-3 mb-4'>
        {conventionsData.map((convention) => (
          <ItemConventionRow
            color={convention.color}
          >
            <span className='square-convention' style={{backgroundColor:convention.color}}></span>
            {convention.description}
          </ItemConventionRow>
        ))}
      </ul>
  );
}

export default Conventions