import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Utils from '../../utils';
import { AlertForm, ICON } from '../../utils/Alert';



export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
`;
export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;
const MyAccount = ()=>{
  const [error, setError] = useState('');
  const [validatedForm, setValidatedForm] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const handleNewPassword = (event)=>{
    setPassword(event.target.value);
  }
  const handleConfirmPassword = (event)=>{
    setConfirmPassword(event.target.value);
  }
  useEffect(()=>{
    let valid=true;
    if(!password){
      setError('');
      valid=false;
    }else if(password.length<8){
      setError('La contraseña debe contener mínimo 8 caracteres');
      valid=false;
    }else if(!confirmPassword){
      setError('Debe completar el campo de confirmar contraseña');
      valid=false;
    }else if(password !== confirmPassword){
      setError('Los campos de contraseña y confirmar la contraseña deben coincidir');
      valid=false;
    }else{
      setError('');
    }
    setValidatedForm(valid);
  }, [password, confirmPassword])
  const changePassword = ()=>{
    const endpoint = '/users/change-password';
    const requestData = {password};
    Utils.Petition.post(endpoint, requestData, 
      (_)=> {
        AlertForm({title:'Cambio de contraseña',text:'Contraseña cambiada correctamente', icon: ICON.SUCCESS })
        Utils.Petition.logs({data: requestData, endpoint, type: "Update", register: "Cuenta: Actualizar Contraseña" });
      }, (error)=>{
        console.log(error);
        AlertForm({title:'Cambio de contraseña',text:'Ocurrió un problema, por favor intente más tarde', icon: ICON.ERROR })
      }
    )
  }
  return (
    <MDBContainer>
      <section className='mx-4 mb-4'>
        <div className="d-flex">
          <Title>Mi cuenta</Title>
        </div>
        <Description>Configuración de mi cuenta</Description>
        <MDBRow>
          <MDBCol size="6">
            <MDBCard className="mb-3">
              <MDBCardHeader>Cambiar mi contraseña</MDBCardHeader>
              <MDBCardBody>
                <form>
                  <div className="form-group">
                    <input type="password" className='form-control' onChange={handleNewPassword} placeholder='Nueva contraseña' />
                  </div>
                  <div className="form-group">
                    <input type="password" className='form-control' onChange={handleConfirmPassword} placeholder='Confirmar contraseña' />
                  </div>
                  <div>
                    {error!='' && <small style={{color:'red'}}>{error}</small>}
                  </div>
                </form>
                <button className="btn btn-sm btn-orange " onClick={changePassword} disabled={!validatedForm}>
                  Cambiar
                </button>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  )
}
export default MyAccount;