import React from 'react'
import styled from 'styled-components'
import ContentLoading from './ContentLoading';

const LoaderWrapper = styled.div`
  width: 100%;
  border: solid 1px #C4C4C4;
  border-radius: 8px;
  padding: 16px;
`;

const CardLoading = ({ lines = 1 }) => {
  return (
    <LoaderWrapper>
      <ContentLoading aproxWidth="70%" className='mx-auto mb-2' />
      <ContentLoading aproxWidth="50%" className='mx-auto mb-2' />

      {
        [...Array(lines)].map((_, i) => (
          <ContentLoading className='mx-auto my-1' key={i} />
        ))
      }

      <div className="d-flex mt-4" style={{ gap: "8px" }}>
        <ContentLoading /> <ContentLoading />
      </div>
    </LoaderWrapper>
  )
}

export default CardLoading;