import React, { useEffect, useState } from 'react';
import usePagination from '../../hooks/usePagination';
import useGet from '../../hooks/useGet';
import BlackListRow from './components/BlackListRow';
import PaginationTable from "../../components/tables/PaginationTable";
import FragmentsIcon from "../../components/icons/pages/FragmentsIcon";
import styled from "styled-components";
import Utils from '../../utils';
import BlackListForm from './components/BlackListForm';

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;
export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;
const BlackList = ()=>{

    
    const { data: blackListRecord } = useGet({ route: "/black-list" });
    const [showedBlackListRecords, setBacklListRecords] = useState(blackListRecord);
    const { paginationPages, setCurrentPage, showedData } = usePagination({ data: showedBlackListRecords, showRows: 10 });

    useEffect(()=>{
      setBacklListRecords(blackListRecord);
    }, [blackListRecord]);
    const update = ()=>{
      setBacklListRecords(null);
      const endpoint = '/black-list';
      Utils.Petition.get(endpoint, (response)=>{
        setBacklListRecords(response.data);
        Utils.Petition.logs({data: "", endpoint, type: "Get", register: "Lista Negra: Consultar" })
      })
    }
    
    return (
        <section className='mx-4 mb-4'>
          <div className="d-flex">
            <FragmentsIcon />
            <Title>Lista negra</Title>
          </div>
          <Description>Agrega números que quieras bloquear para que no puedan interactuar con el BOT, ni comunicarse con un asesor.</Description>
          <div>
            <button onClick={update} className="btn btn-orange d-inline  btn-sm">Actualizar</button>
          </div>
          <div className='row'>
            <div className='col-8'>
            <PaginationTable
                headers={["Teléfono", "Acciones"]}
                withoutRight={false}
                content={showedData}
                pages={paginationPages}
                setPages={setCurrentPage}
                row={BlackListRow}
            />
            </div>
            <div className='col-4'>
                <BlackListForm refreshData={update} />
            </div>
          </div>
         

        </section>
    )
}
export default BlackList;