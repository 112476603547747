import React from 'react'

const OutcomingCallIcon = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.14275 3.16107C5.42899 3.31882 5.75368 3.57625 6.20065 3.99854L8.15163 5.93162C8.18877 5.96773 8.2229 6.00071 8.25345 6.02997C8.99522 6.74096 9.15954 7.20204 9.01562 7.86333L8.93446 8.16718L8.76936 8.64388L8.70966 8.80831C7.77897 11.3671 8.10473 13.1443 10.8824 15.8055C13.5644 18.3751 15.3856 18.754 17.9101 17.9726L18.7803 17.6895L18.9906 17.6316C19.719 17.4453 20.1988 17.5334 20.9008 18.1538L23.0016 20.0978L23.2007 20.2887L23.5371 20.6293C23.7851 20.8924 23.9555 21.1077 24.0727 21.3041C24.3805 21.8202 24.3147 22.3714 23.5949 23.2902L23.3133 23.6303C23.1068 23.8679 22.8623 24.1281 22.5761 24.4151L21.9658 25.0053C21.1655 25.7911 20.3807 26.1481 18.8422 25.9431C16.2031 25.5913 12.5457 23.4108 7.74544 18.8118C3.13849 14.3979 0.848846 10.9823 0.348628 8.46449C0.0437895 6.93011 0.397543 6.03791 1.14436 5.30688L1.4534 5.00933L1.49448 4.96015L1.95034 4.53465C2.02292 4.46858 2.09384 4.4049 2.16319 4.34356L2.56073 4.00321C3.82593 2.95963 4.50956 2.81213 5.14275 3.16107Z" fill="#C9CDD2" />
      <path d="M25.2393 7.42859V1.00001H18.8107" stroke="#C9CDD2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.6678 9.57144L25.2393 1.00001" stroke="#C9CDD2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}


export default OutcomingCallIcon;