import React, { useState, useContext } from 'react'
import EditableRow from './EditableRow';
import GroupICon from "../../../assets/Images/groupIcon.svg";
import CalendarIcon from "../../../assets/Images/calendarIcon.svg";
import BotIcon from "../../../assets/Images/botIcon.svg";
import { StatusIndicator } from '../../Conversations/components/contacts/Interaction';
import { CampaignsContext } from '../../../Context/Campaigns';
import Petition from '../../../utils/Petition';
import Swal from 'sweetalert2';

const CampaignRow = ({ _id, name, to, group, subgroup, bot, isExpiration, expiration, is_open }) => {

  const [editable, setEditable] = useState(false);
  const { updateCampaign, bots } = useContext(CampaignsContext);

  const handleUpdate = (campaign, finally_callback = () => { }) => {
    const requestData = {
      id: _id,
      campaign
    };

    const endpoint = '/campaigns/update-campaign';

    Petition.patch(endpoint, requestData,
      (data) => {
        Swal.fire({
          title: "La campaña se ha actualizado con éxito.",
          icon: "success",
          timer: 2000
        });

        if (campaign.bot) { campaign.bot = bots.find(bot => bot._id === campaign.bot); }

        updateCampaign({
          _id,
          ...campaign
        });
        Petition.logs({data: requestData, endpoint, type: "Update", register: `Campaña: Actualizar, Nombre: ${name}` });
      },
      (err) => {
        Swal.fire({
          title: "No ha sido posible actualizar la campaña.",
          icon: "error",
          text: err?.response?.data?.data?.message,
          timer: 2000
        });
      },
      () => {
        finally_callback();
      }
    )
  }

  if (editable) {
    return (
      <EditableRow
        setEditable={setEditable}
        _id={_id}
        name={name}
        group={group}
        to={to}
        bot={bot}
        subgroup={subgroup}
        isExpiration={isExpiration}
        expiration={expiration}
        updateCampaign={handleUpdate}
      />
    )
  } else {
    return (
      <tr>
        <td className="text-truncate" style={{ maxWidth: "120px" }}>
          {name}
        </td>
        <td>
          <img src={to === "bot" ? BotIcon : GroupICon} alt="" />
        </td>

        {(to === "group" || to === undefined) && (
          <>
            <td>{group}</td>
            <td>{subgroup}</td>
          </>
        )}

        {to === "bot" && (
          <>
            <td colSpan={2}>{bot?.name}</td>
          </>
        )}

        <td>
          <div className="d-flex align-items-center">
            <img src={CalendarIcon} alt="" />
            <span className="mx-1 text-truncate">
              {
                isExpiration && new Date(
                  new Date(expiration).setHours(
                    new Date(expiration).getHours() + 5
                  )
                ).toDateString()
              }
              {!isExpiration && "Sin vencimiento"}
            </span>
            {( !isExpiration || new Date(expiration) > new Date()) && <StatusIndicator />}
          </div>
        </td>

        <td className="d-flex">
          <button
            className="btn btn-sm btn-orange m-auto w-100"
            onClick={() => setEditable(true)}
            disabled={!is_open}
          >
            editar
          </button>
          <button
            className={"btn btn-sm " + (is_open ? "btn-danger" : "btn-dark")}
            onClick={() => handleUpdate({ is_open: !is_open })}
            disabled={new Date(expiration) > new Date()}
          >
            {is_open ? "cerrar" : "reabrir"}
          </button>
        </td>
      </tr>
    );
  }

}

export default CampaignRow;