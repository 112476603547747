import React, { useEffect, useState } from 'react';
import usePagination from '../../hooks/usePagination';
import useGet from '../../hooks/useGet';
import BotEventRow from './components/BotEventRow';
import PaginationTable from "../../components/tables/PaginationTable";
import FragmentsIcon from "../../components/icons/pages/FragmentsIcon";
import styled from "styled-components";
import Utils from '../../utils';
import ExcelExport from '../../components/ExcelExport';
import { getDateByTime } from '../../utils/Times';
import DateFilter from '../Conversations/components/filters/DateFilter';
import { MDBAlert } from 'mdbreact';

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;
export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;
const months =['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre', 'Diciembre'];
const BotEvents = ()=>{
  const [since, setSince] = useState(`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-01T00:00`);
  const [until, setUntil] = useState(`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${(new Date().getDate()).toString().padStart(2, '0')}T${(new Date().getHours()).toString().padStart(2, '0')}:${(new Date().getMinutes()).toString().padStart(2, '0')}`);
  const [milliseconds, setMillisecond] = useState(1);
  const [firstLoading, setFirstLoading] = useState(false);


  useEffect(()=>{
    if(!firstLoading && Number.isInteger(since) && Number.isInteger(until) && milliseconds>1){
      setFirstLoading(true);
      updateFilterByDate();
    }
  }, [since, until, milliseconds]);

    const [showedBotEvents, setShowedBotEvents] = useState();
    const { paginationPages, setCurrentPage, showedData } = usePagination({ data: showedBotEvents, showRows: 10 });
    
    const formatBotEvent = (data)=>{
      return data.map(obj => {
        return {
          phone: obj.phone,
          date: getDateByTime(obj.timeStamp), 
          name: obj.name??'Sin nombre',
          line: obj.line??'Sin línea',
          type: obj.type,
          action: obj.action,
          message: obj.message?obj.message:"N/A",
          detail: obj.detail,
        }
      });
    }
    
    const updateFilterByDate = ()=>{
      if(milliseconds > 0 && milliseconds <= 2678500000){
        setShowedBotEvents(null);
        /* Utils.Petition.get('/call-records', (response)=>{ */
        Utils.Petition.get(`/bot-events/detail/start/${since}/end/${until}`, (response)=>{
          setShowedBotEvents(response.data);
        })
      }
    }
    const handleDatesFilter = (dates) => {
      setSince(dates.since);
      setUntil(dates.until);
      setMillisecond(dates.until - dates.since);
    };
    

    return (
        <section className='mx-4 mb-4'>
          <div className="d-flex">
            <FragmentsIcon />
            <Title>Eventos en el Bot</Title>
          </div>
          <Description>Sucesos importantes en la interacción con el bot del Constructor de bots</Description>
          <div>
            <DateFilter
                setDateFilter={handleDatesFilter}
                since={since}
                until={until}
            />
            <button className="btn btn-sm btn-orange" onClick={updateFilterByDate}>Filtrar</button>
            <ExcelExport excelData={showedBotEvents}  formatData={formatBotEvent} fileName="Resumen de logs" />
          </div>
          { 
            (milliseconds <= 0 || milliseconds > 2678500000) && 
            <MDBAlert color="danger">
                La fecha de inicio debe ser menor que la fecha de fin y no puede superar un mes
            </MDBAlert>
          }
          <PaginationTable
            headers={["Nombre del bot", "Fecha del evento", "Tipo", "Teléfono", "Línea", "Paso", "Acción", "Mensaje", "Detalle"]}
            withoutRight={false}
            content={showedData}
            pages={paginationPages}
            setPages={setCurrentPage}
            row={BotEventRow}
          />

        </section>
    )
}
export default BotEvents;