import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,registerables  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Loading from '../Loading/Loading';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,...registerables);
ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.weight = 'bold';
const GraficaBarVertical = ({ dataItems, title, isLoading,hours=false,subtitle="" }) => {
  const [labelsData, setLabelsData] = useState([]);
  const [amountData, setAmountData] = useState([]);
  useEffect(() => {
    let keys = [];
    let amount = [];
    for (const key in dataItems[0]) {
      if (Object.hasOwnProperty.call(dataItems[0], key)) {
        const value = dataItems[0][key].value;
        const label = dataItems[0][key].key;
        keys.push(label);
        amount.push(value);
      }
    }
    setLabelsData(keys);
    setAmountData(amount);
  }, [dataItems]);

  const dataLabelPlugin = {
    id: 'dataLabelPlugin',
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
  
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        if (!meta.hidden) {
          meta.data.forEach((element, index) => {
            // Dibujar el valor encima del punto
            ctx.fillStyle = 'rgb(0, 0, 0)'; // Color del texto
            const fontSize = 12;
            const fontStyle = 'normal';
            const fontFamily = 'Helvetica Neue';
            ctx.font = `${fontStyle} ${fontSize}px ${fontFamily}`;
  
            const dataString = dataset.data[index].toString();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const padding = 5;
            const position = element.tooltipPosition();
            ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
          });
        }
      });
    },
  };
  const options = {
    responsive: true,
    aspectRatio: 4,
    scales: {
        x: {
            ticks: {
                callback: function(value) {
                  if(hours){
                    return this.getLabelForValue(value).padStart(2,"0")+":00"
                  }
                  if(this.getLabelForValue(value).length>15) return this.getLabelForValue(value).substr(0, 15) + "...";//truncate
                  else return this.getLabelForValue(value);
                },
            }
        },
     
    },
    plugins: {
      
      title: {
        display: true,
        text: title,
        font: {
          size: 20,
        },
        color: 'black',
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      
    },
    transitions: {
      show: {
        animations: {
          x: {
            from: 0,
          },
          y: {
            from: 0,
          },
        },
      },
      hide: {
        animations: {
          x: {
            to: 0,
          },
          y: {
            to: 0,
          },
        },
      },
    },
  };

  const labels = labelsData;

  const data = {
    labels,
    datasets: [
      {
        label: subtitle,
        data: amountData,
        borderColor: '#090710',
        backgroundColor: '#f9781e',
        borderWidth: 0,
        borderRadius: 5,
        base: 0.5,
        barPercentage: 0.9,
      }
    ],
  };
  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && <Bar  options={options} data={data} plugins={[dataLabelPlugin]}/>}
    </>
  );
};

export default GraficaBarVertical;
