import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBContainer, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import Utils from '../../utils';
import EditableRow from './components/EditableRow';
import { SectionConfig } from './styles';
import VisibleRow from './components/VisibleRow';

const Configuration = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataTableNumbers, setDataTableNumbers] = useState([]);

  useEffect(() => {
    Utils.Petition.get(
      '/bots/getnumbers',
      (response) => {

        let dataTable = [];

        response.data.forEach(el => {
          Utils.Petition.get(
            `/bots/searchbyphone/${el.phone}`,
            (res) => {
              let config = {
                ...el,
                ...res.data,
                isActive: false,
                initial: {
                  ...el,
                  ...res.data,
                }
              }

              dataTable = [...dataTable, config];
              setDataTableNumbers(dataTable);
            },
            (err) => console.warn('Error obtener números', err.message),
            () => { setIsLoading(false) }
          )
        })

      },
      (err) => console.warn('Error obtener números', err.message),
      () => { }
    );
  }, [])

  const getDataAndIndex = (phone) => {
    let dataTable = [...dataTableNumbers];
    let index = dataTableNumbers.findIndex(number => number.phone === phone);
    return [dataTable, index];
  }

  const handleNumbersState = (parameters, values, phone) => {
    const [dataTable, index] = getDataAndIndex(phone);

    parameters.forEach((parameter, i) => {
      dataTable[index][parameter] = values[i];
    });

    setDataTableNumbers(dataTable);
  };

  const handleActiveEdit = (state, number) => {
    handleNumbersState(['isActive'], [state], number);
  }

  return (
    <MDBContainer className="mb-3">
      <SectionConfig>
        <MDBCard>
          <MDBCardHeader>Configuración de números</MDBCardHeader>
          <MDBCardBody>
            <MDBCardTitle>Tabla de Configuración</MDBCardTitle>

            <MDBTable align="middle">
              <MDBTableHead>
                <tr>
                  <th scope="col">Numero</th>
                  <th scope="col">Compañía</th>
                  <th scope="col">Ambiente</th>
                  <th scope="col">API Wowabot</th>
                  <th scope="col">Acciones</th>
                </tr>
              </MDBTableHead>

              <MDBTableBody>
                {
                  !isLoading && dataTableNumbers.map((number, i) => {
                    return number.isActive ?
                      <EditableRow {...number} setEditable={handleActiveEdit} setData={handleNumbersState} key={i} />
                      :
                      <VisibleRow {...number} setEditable={handleActiveEdit} key={i} />
                  })
                }

                {isLoading && <tr className='row-load'>
                  <td className='my-5'><Loading /></td>
                </tr>
                }

              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>

      </SectionConfig>
    </MDBContainer>

  )
}

export default Configuration;