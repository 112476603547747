import React from 'react'

const ProfileIcon = (props) => {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_592_22100" style={{ maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="10" width="13" height="6">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 10.0622H12.4623V16H0V10.0622Z" fill="white" />
      </mask>
      <g mask="url(#mask0_592_22100)">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.23197 16C4.69069 16 0 16 0 13.023C0 10.369 3.55697 10.0622 6.23197 10.0622C7.77324 10.0622 12.4624 10.0622 12.4624 13.0391C12.4624 15.6932 8.90618 16 6.23197 16Z" fill="#3D3D3D" />
      </g>
      <mask id="mask1_592_22100" style={{ maskType: "alpha"}} maskUnits="userSpaceOnUse" x="2" y="0" width="9" height="9">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.05371 0H10.4092V8.55046H2.05371V0Z" fill="white" />
      </mask>
      <g mask="url(#mask1_592_22100)">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.23127 8.55041H6.20609C3.90717 8.54317 2.04489 6.62429 2.05276 4.27301C2.05276 1.9177 3.92683 -0.000366211 6.23127 -0.000366211C8.53492 -0.000366211 10.4082 1.9177 10.4082 4.27543C10.4082 6.63315 8.53492 8.55041 6.23127 8.55041Z" fill="#3D3D3D" />
      </g>
    </svg>
  )
}

export default ProfileIcon