import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getHourByTime } from '../../../../utils/Times';
import AudioMessage from './AudioMessage';
import FileMessage from './FileMessage';

const ChatMsg = styled.div`
  display: flex;
  padding: 0 0px 12px;
  position:relative;
  flex-direction: ${props => (props.from === "Contact" || props.from === "System" || props.from === "Bot" || props.from === "Template" || props.from === "Template_massive") ? "row" : "row-reverse"};
`;

const ChatMsgContent = styled.div`
    margin-left: ${props => (props.from === "Contact" || props.from === "System" ||props.from === "Bot" || props.from === "Template" || props.from === "Template_massive") ? "12px" : "0"};
    margin-right: ${props => (props.from === "Contact" || props.from === "System" || props.from === "Bot" || props.from === "Template" || props.from === "Template_massive") ? "0" : "12px"};
    max-width: 80%;
`;

const ChatMsgText = styled.div`
    background-color: ${props => props.from === "Contact" ? "var(--gray-color)" : (props.from === "System" || props.from === "Bot" || props.from === "Template" || props.from === "Template_massive") ? "var(--theme-color)" : "var(--button-bg-color)"};
    color: ${props => props.from === "Contact" ? "var(--dark-color)" : (props.from === "System" || props.from === "Bot" || props.from === "Template" || props.from === "Template_massive") ? "white" : "var(--theme-color)"};
    border-radius: ${props => (props.from === "Contact" || props.from === "System" || props.from === "Bot" || props.from === "Template" || props.from === "Template_massive") ? "20px 20px 20px 0" : "20px 20px 0 20px"};
    padding: 8px;
    line-height: 1.5;
    font-size: 13px;
    /* font-weight: 500; */
    word-wrap: break-word;
  
    span:first-child {
      width: 100%;
      word-wrap: break-word;
    }
    
    .hour {
      font-size: 10px;
      display:block;
      text-align:right;
    }
`
const ChatDataTitle = styled.div`
  span {
    &:nth-child(2) {
      font-size: 10px;
    }
  }
span [data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
span [data-title]:after {
  content: attr(data-title);
  background-color: rgb(51, 51, 51);
  color: rgb(255, 255, 255);
  font-size: 14px;
  position: absolute;
  padding: 3px 20px;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: rgb(34, 34, 34) 1px 1px 3px;
  opacity: 0;
  border: 1px solid rgb(17, 17, 17);
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
  text-align: left;
  
}`

const ChatMsgContainer = styled.div`
  span {
    &:nth-child(2) {
      font-size: 10px;
    }
  }
`

const Message = ({ message, time, from, type,status,caption,error }) => {
  const [content, setContent] = useState(message);
  const [systemIndicator, setSystemIndicator] = useState("");

  useEffect(() => {
    switch (type) {
      case "img": case "pdf": case "mp4":
        setContent(<FileMessage message={message} type={type} />);
        break;
      case "mp3":
        setContent(<AudioMessage url={message} />);
        break;
      default:
        setContent(message);
    }
  }, [message, type]);

  useEffect(() => {
    if(from === "System") {
      setSystemIndicator(type === "mp3" ? "Grabación de llamada." : "Mensaje del sistema.")
    } 
    else if (from === "Bot"){
      setSystemIndicator("Mensaje del bot")
    }
    else if (from === "Template"){
      setSystemIndicator("Mensaje de plantilla")
    }
    else if (from === "Template_massive"){
      setSystemIndicator("Mensaje de envio masivo")
    }
    else {
      setSystemIndicator("");
    }
  }, [from, type]);

  return (
    <ChatMsg className={from} from={from}>
      <ChatMsgContent from={from}>
        <ChatMsgText from={from}>
          <ChatMsgContainer>
            <span>{content}</span>
            {caption && <div>{caption}</div>}
            <span className={systemIndicator !== "" ? 'd-block mt-2' : undefined}>{systemIndicator}</span>
          </ChatMsgContainer>
          <ChatDataTitle>
            <span className='hour' >{getHourByTime(time)}
              {!from?.toLowerCase()?.includes("contact")&&
                <>
                  { (status === "sent") && (
                    <span style={{ marginLeft: '5px' }}>
                      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '14px' }}>
                        <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ></path>
                      </svg>
                    </span>
                  )}
                  { (status === "delivered") && (
                    <span style={{ marginLeft: '5px' }}>
                      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '20px' }}>
                        <path d="M4 12.9L7.14286 16.5L15 7.5" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20 7.5625L11.4283 16.5625L11 16" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </span>
                  )}
                  { (status === "read") && (
                    <span style={{ marginLeft: '5px' }}>
                      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '20px' }}>
                        <path d="M4 12.9L7.14286 16.5L15 7.5" stroke="#08a8a8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20 7.5625L11.4283 16.5625L11 16" stroke="#08a8a8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </span>
                  )}
                  { (status === "failed") && (
                    <span data-title={error??"Error Desconocido"}  style={{ marginLeft: '5px' }} >
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 506.4 506.4" xmlSpace="preserve" fill="#FFFFFF" stroke="#FFFFFF" style={{ width: '14px' }}>
                        <circle cx="253.2" cy="253.2" r="249.2" fill="#DF5C4E"></circle>
                        <path d="M253.2,332.4c-10.8,0-20-8.8-20-19.6v-174c0-10.8,9.2-19.6,20-19.6s20,8.8,20,19.6v174 C273.2,323.6,264,332.4,253.2,332.4z"></path>
                        <path d="M253.2,395.6c-5.2,0-10.4-2-14-5.6s-5.6-8.8-5.6-14s2-10.4,5.6-14s8.8-6,14-6s10.4,2,14,6 c3.6,3.6,6,8.8,6,14s-2,10.4-6,14C263.6,393.6,258.4,395.6,253.2,395.6z"></path>
                        <path d="M253.2,506.4C113.6,506.4,0,392.8,0,253.2S113.6,0,253.2,0s253.2,113.6,253.2,253.2S392.8,506.4,253.2,506.4z M253.2,8 C118,8,8,118,8,253.2s110,245.2,245.2,245.2s245.2-110,245.2-245.2S388.4,8,253.2,8z"></path>
                        <path d="M249.2,336.4c-13.2,0-24-10.8-24-23.6v-174c0-13.2,10.8-23.6,24-23.6s24,10.8,24,23.6v174 C273.2,325.6,262.4,336.4,249.2,336.4z M249.2,122.8c-8.8,0-16,7.2-16,15.6v174c0,8.8,7.2,15.6,16,15.6s16-7.2,16-15.6v-174 C265.2,130,258,122.8,249.2,122.8z"></path>
                        <path d="M249.2,399.6c-6.4,0-12.4-2.4-16.8-6.8c-4.4-4.4-6.8-10.4-6.8-16.8s2.4-12.4,6.8-16.8c4.4-4.4,10.8-6.8,16.8-6.8 c6.4,0,12.4,2.4,16.8,6.8c4.4,4.4,6.8,10.4,6.8,16.8s-2.4,12.4-7.2,16.8C261.6,397.2,255.6,399.6,249.2,399.6z M249.2,360 c-4,0-8.4,1.6-11.2,4.8c-2.8,2.8-4.4,6.8-4.4,11.2c0,4,1.6,8.4,4.8,11.2c2.8,2.8,7.2,4.8,11.2,4.8s8.4-1.6,11.2-4.8 c2.8-2.8,4.8-7.2,4.8-11.2s-1.6-8.4-4.8-11.2C257.2,361.6,253.2,360,249.2,360z"></path>
                      </svg>
                    </span>
                  )}
                </>
              }
            </span>
          </ChatDataTitle>
          {/* {status_confirm[status]||status_confirm["delivered"]} */}
        </ChatMsgText>
      </ChatMsgContent>
    </ChatMsg>
  )
}

export default Message;