import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import { TopbarWrapper } from "./styles";

function Topbar({ handleCloseSession, company, setCompany }) {
  let history = useHistory();
  const [firstname, setFirstname] = useState("");

  const localHandleCloseSession = function () {
    handleCloseSession();
    history.push("/");
  };
  useEffect(() => {
    setFirstname(localStorage.getItem("firstname"));
  }, []);

  return (
    <TopbarWrapper>
      <nav className="navbar navbar-expand navbar-light  topbar static-top shadow">
        <div>
          Hola <strong>{firstname}</strong>
        </div>
        <div className=" ml-auto">
          <MDBBtn color="deep-orange" onClick={localHandleCloseSession}>
            Cerrar Sesión
          </MDBBtn>
        </div>
      </nav>
    </TopbarWrapper>
  );
}
export default Topbar;

