export const removeKeysFromObject = (obj, keys) => {
  try {
    
    for (let key of keys) {
      delete obj[key];
    }

    return obj;
  } catch (error) {
    throw error;
  }
}