import styled from 'styled-components';

export const LoginStyles = styled.div`
  background: #252130;
  height: 100vh;

  & .card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    & .card-title {
      margin-bottom: 2rem;
      font-weight: 300;
      font-size: 1.5rem;
    }

    & .card-body {
      padding: 2rem;
    }
  }

  & .form-signin {
    width: 100%;

    & .btn {
      font-size: 80%;
      border-radius: 5rem;
      letter-spacing: 0.1rem;
      font-weight: bold;
      padding: 1rem;
      transition: all 0.2s;
    }
  }

  & .form-group {
    position: relative;
    margin-bottom: 1rem;
    & input {
      height: auto;
      border-radius: 2rem;
    }
  }

  & .form-label-group {
    & input::-webkit-input-placeholder,
    & input:-ms-input-placeholder,
    & input::-ms-input-placeholder,
    & input::-moz-placeholder,
    & input::placeholder {
      color: transparent;
    }

    & input:not(:placeholder-shown) {
      padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
      padding-bottom: calc(var(--input-padding-y) / 3);
    }
    & input:not(:placeholder-shown) ~ label {
      padding-top: calc(var(--input-padding-y) / 3);
      padding-bottom: calc(var(--input-padding-y) / 3);
      font-size: 12px;
      color: #777;
    }
  }

  & .alert {
    border-radius: 2rem !important;
  }
`;
