import { MDBCard } from 'mdbreact';
import styled from 'styled-components';
import ArrowDown from '../../assets/Images/arrow-down-solid.svg';

export const CardFlowStyles = styled(MDBCard)`
  background-color: var(--background-gray);
  width: 300px;
  margin: 3rem auto 0;

  &:not(:last-child)::after {
    content: '';
    background: url(${ArrowDown}) no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -43px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .md-form textarea.md-textarea {
    resize: none;
    font-size: 12px;
    overflow-y: auto;
  }
  .md-form textarea.md-textarea:focus:not([readonly]) {
    border-bottom: 1px solid var(--primary-color);
    box-shadow: 0 1px 0 0 var(--primary-color);
  }
  .md-form textarea.md-textarea:focus:not([readonly]) + label {
    color: var(--primary-color);
  }
`;

export const SectionFlowMessages = styled.section`
  & .card-body {
    position: relative;
    & .text-sticky {
      padding: 1rem;
      position: sticky;
      top: 56px;
      background-color: white;
      left: 0;
      z-index: 10;
    }
  }
`;

export const SectionConfig = styled.section`
  & tr {
    display: grid;
    grid-template-columns: 200px 350px 400px;
  }
`;
