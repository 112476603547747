import React from 'react'
import styled from 'styled-components';
import ConversationsHomeIcon from '../../../../components/icons/pages/ConversationsHomeIcon';

const Empty = styled.div`
  padding: 56px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmptyChat = () => {
  return (
    <Empty>
      <ConversationsHomeIcon />
      <p className='text-center mt-1'>Selecciona una conversación para ver la información registrada</p>
    </Empty>
  )
}

export default EmptyChat;