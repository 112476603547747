import { useEffect } from "react";
import { useCallback, useRef, useState } from "react";

const initialState = { top: 0, left: 0, x: 0, y: 0 }

const useScrollOnDrag = () => {
  const [position, setPosition] = useState(initialState);
  // const [transform, setTransform] = useState({ x: 0, y: 0 });

  const scrolleableRef = useRef(null);

  const mouseMoveHandler = useCallback((e) => {
    const dx = e.clientX - position.x;
    const dy = e.clientY - position.y;

    // setTransform({
    //   x: (dx - position.left),
    //   y: (dy - position.top)
    // });

    scrolleableRef.current.scrollTop = (position.top - dy);
    scrolleableRef.current.scrollLeft = (position.left - dx);
  }, [position]);

  const mouseUpHandler = useCallback(() => {
    scrolleableRef.current.removeEventListener('mousemove', mouseMoveHandler);
    scrolleableRef.current.removeEventListener('mouseup', mouseUpHandler);

    scrolleableRef.current.style.cursor = 'grab';
    scrolleableRef.current.style.removeProperty('user-select');
  }, [mouseMoveHandler]);

  const mouseDownHandler = (e) => {
    setPosition({
      left: scrolleableRef.current.scrollLeft,
      top: scrolleableRef.current.scrollTop,
      x: e.clientX,
      y: e.clientY
    });

    scrolleableRef.current.style.cursor = 'grabbing';
    scrolleableRef.current.style.userSelect = 'none';
  }

  useEffect(() => {
    if (position !== initialState) {
      scrolleableRef.current.addEventListener('mousemove', mouseMoveHandler);
      scrolleableRef.current.addEventListener('mouseup', mouseUpHandler);
    }
  }, [position, mouseMoveHandler, mouseUpHandler]);

  return { ref: scrolleableRef, mouseDownHandler }
}

export default useScrollOnDrag;