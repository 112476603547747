import React, { useEffect, useState } from 'react';

const VisibleRow = ({ time, text, i, removeRow, updateRow }) => {
  
 
  const [currentText, setCurrentText] = useState()
  const [currentTime, setCurrentTime] = useState()
  

  useEffect(()=>{
    setCurrentText(text)
    setCurrentTime(time)
  },[text,time])

 
  const del = async function(id,e){
    removeRow(i)
  }

  const editText = async function(e){
    setCurrentText(e.target.value)
    updateRow(i, { newText: e.target.value, newTime: Number(currentTime) });
  }

  const editTime = async function (e) {
    setCurrentTime(Number(e.target.value));
    updateRow(i, { newText: currentText, newTime: Number(e.target.value) });
  };


  return (
    <tr id={"del" + i}>
      <td>
        <input
          type="number"
          min="0.5"
          step="any"
          name="mens_automatic_time"
          id="mens_automatic_time"
          value={currentTime}
          onChange={(e) => editTime(e)}
          className="form-control w-75"
        />
      </td>
      <td style={{ minWidth: "200px" }}>
        <textarea
          className="form-control"
          rows="4"
          onChange={(e) => {editText(e)}}
          cols="50"
          value={currentText}
        ></textarea>
      </td>
     
      <td>
        <button
          className="btn btn-sm btn-danger m-auto w-100"
          type="button"
          onClick={(e) => del("del" + i, e)}
        >
          Eliminar
        </button>
      </td>
    </tr>
  );
}

export default VisibleRow;