import React, { useContext, useEffect } from 'react';
import Petition from '../../../utils/Petition';
import Swal from 'sweetalert2';
import useCampaignForm from '../../../hooks/useCampaignForm';
import { GroupsContext } from '../../../Context/Groups';
import SelectGroup from '../../../components/selects/SelectGroup';
import SelectSubgroup from '../../../components/selects/SelectSubgroup';
import { removeKeysFromObject } from '../../../utils/Objects';
import SelectBot from './SelectBot';

const CampaignForm = ({ addCampaign }) => {

  const { isLoading, groupsKeys, subgroupsKeysByGroupKey } = useContext(GroupsContext);
  const { fields, handleChange, clearForm } = useCampaignForm();

  useEffect(() => {
    if (fields['campaign-redirection'] === "bot") {
      handleChange({ target: { name: "campaign-group", value: undefined } });
      handleChange({ target: { name: "campaign-bot", value: '' } });
    } else if (fields['campaign-redirection'] === "group") {

      handleChange({ target: { name: "campaign-bot", value: undefined } });
      handleChange({ target: { name: "campaign-group", value: '' } });
      handleChange({ target: { name: "campaign-subgroup", value: '' } });
    }
  }, [fields['campaign-redirection']]);

  useEffect(() => {
    if (fields['is-campaign-expiration'] === "yes") {
      handleChange({ target: { name: "campaign-expiration", value: '' } });
    } else if (fields['is-campaign-expiration'] === "no") {
      handleChange({ target: { name: "campaign-expiration", value: '' } });
    }
  }, [fields['is-campaign-expiration']]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValidForm()) {
      const requestData = {
        campaign: {
          name: fields['campaign-name'],
          group: (fields['campaign-redirection'] === "bot") ? undefined : fields['campaign-group'],
          subgroup: (fields['campaign-redirection'] === "bot") ? undefined : fields['campaign-subgroup'],
          isExpiration: (fields['is-campaign-expiration'] === "no") ? false : true,
          expiration: fields['is-campaign-expiration'] === "no" ? undefined:  fields['campaign-expiration'],
          to: fields['campaign-redirection'],
          bot: (fields['campaign-redirection'] === "group") ? undefined : fields['campaign-bot']
        },
      };
      const endpoint = '/campaigns/create-campaign';
      Petition.post(endpoint, requestData,
        (data) => {
          Swal.fire({
            title: "La campaña se ha creado con éxito.",
            icon: "success",
            timer: 2000
          });

          addCampaign(data.data);
          Petition.logs({data: requestData, endpoint, type: "Create", register: "Campañas: Crear" });
          clearForm();
        },
        (err) => {
          Swal.fire({
            title: "No ha sido posible crear la campaña.",
            icon: "error",
            text: err?.response?.data?.data?.message,
            timer: 2000
          });
        }
      )
    }
  }

  const isValidForm = () => {

    let copyOfFields = { ...fields };

    if (copyOfFields["campaign-redirection"] === "group") {
      copyOfFields = removeKeysFromObject(copyOfFields, ["campaign-bot"]);

    } else if (copyOfFields["campaign-redirection"] === "bot") {
      copyOfFields = removeKeysFromObject(copyOfFields, ["campaign-group"]);
      copyOfFields = removeKeysFromObject(copyOfFields, ["campaign-subgroup"]);
    }
    
    if (copyOfFields["is-campaign-expiration"] === "no") {
      copyOfFields = removeKeysFromObject(copyOfFields, ["campaign-expiration"]);
    }

    return !Object.values(copyOfFields).includes("");
  }

  return (
    <form
      className='p-4 bg-white mb-4'
      style={{ border: "solid 1px var(--border-color)", borderRadius: "16px" }}
      onSubmit={handleSubmit}
    >

      <h6 className='mb-4 text-center' style={{ fontWeight: 500 }}>Agrega una nueva campaña</h6>

      <div className="form-group w-100">
        <label htmlFor="campaign-name" className='font-weight-normal'>Nombre</label>
        <input
          className='form-control'
          type="text"
          name='campaign-name'
          id='campaign-name'
          placeholder='Ejm: Promoción de febrero'
          onChange={handleChange}
          value={fields["campaign-name"]}
          required
        />
      </div>
      <div className="form-group w-100">
        <label htmlFor="" className='font-weight-normal'>Habilitar vencimiento</label>

        <div>

          <div className="form-check form-check-inline">
            <input
              type="radio"
              value={"yes"}
              name={'is-campaign-expiration'}
              id={'is-campaign-expiration-yes'}
              onChange={handleChange}
              className="form-check-input"
              checked={fields["is-campaign-expiration"] === "yes"}
            />

            <label htmlFor="is-campaign-expiration-yes" className="form-check-label">Sí</label>
          </div>

          <div className="form-check form-check-inline">
            <input
              type="radio"
              value={"no"}
              name={'is-campaign-expiration'}
              id={'is-campaign-expiration-no'}
              onChange={handleChange}
              className="form-check-input"
              checked={fields["is-campaign-expiration"] === "no"}
            />

            <label htmlFor="is-campaign-expiration-yes" className="form-check-label">No</label>
          </div>

        </div>
      </div>
      { fields["is-campaign-expiration"] === "yes" &&
        <div className="form-group w-100">
          <label htmlFor="campaign-expiration" className='font-weight-normal'>Fecha de vencimiento</label>
          <input
            className='form-control'
            type="date"
            name={'campaign-expiration'}
            id={'campaign-expiration'}
            min={new Date().toISOString().substring(0, 10)}
            onChange={handleChange}
            value={fields["campaign-expiration"]}
            required
          />
        </div>
      }

      <div className="form-group w-100">
        <label htmlFor="" className='font-weight-normal'>Redirigir hacia</label>

        <div>

          <div className="form-check form-check-inline">
            <input
              type="radio"
              value={"bot"}
              name={'campaign-redirection'}
              id={'campaign-redirection-bot'}
              onChange={handleChange}
              className="form-check-input"
              checked={fields["campaign-redirection"] === "bot"}
            />

            <label htmlFor="campaign-redirection-bot" className="form-check-label">Bot</label>
          </div>

          <div className="form-check form-check-inline">
            <input
              type="radio"
              value={"group"}
              name={'campaign-redirection'}
              id={'campaign-redirection-group'}
              onChange={handleChange}
              className="form-check-input"
              checked={fields["campaign-redirection"] === "group"}
            />

            <label htmlFor="campaign-redirection-group" className="form-check-label">Grupo/Subgrupo</label>
          </div>

        </div>
      </div>

      {
        fields["campaign-redirection"] === "group" && (
          <>
            <div className="form-group w-100">
              <label htmlFor="campaign-group" className='font-weight-normal'>Grupo</label>
              <SelectGroup
                name='campaign-group'
                field={fields["campaign-group"]}
                handleChange={handleChange}
                groupsKeys={groupsKeys}
                isLoading={isLoading}
                mode={"create"}
              />
            </div>

            <div className="form-group w-100">
              <label htmlFor="campaign-subgroup" className='font-weight-normal'>Subgrupo</label>
              <SelectSubgroup
                name={"campaign-subgroup"}
                field={fields["campaign-subgroup"]}
                group={fields['campaign-group']}
                handleChange={handleChange}
                isLoading={isLoading}
                subgroupsKeysByGroupKey={subgroupsKeysByGroupKey}
                mode={"create"}
              />
            </div>
          </>
        )
      }

      {
        fields["campaign-redirection"] === "bot" && (
          <div className="form-group w-100">
            <label htmlFor="campaign-subgroup" className='font-weight-normal'>Bot</label>
            <SelectBot
              name='campaign-bot'
              id='campaign-bot'
              onChange={handleChange}
              value={fields["campaign-bot"]}
            />
          </div>
        )
      }

      <button
        type='submit'
        className='btn btn-orange mx-0 w-100'
        disabled={!isValidForm()}
      >
        Crear nueva campaña
      </button>

    </form>
  )
}

export default CampaignForm;