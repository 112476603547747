import React, { useEffect, useState } from 'react';
import usePagination from '../../hooks/usePagination';
import useGet from '../../hooks/useGet';
import CallRecordRow from './components/CallRecordRow';
import PaginationTable from "../../components/tables/PaginationTable";
import FragmentsIcon from "../../components/icons/pages/FragmentsIcon";
import styled from "styled-components";
import Utils from '../../utils';
import { getDateByTime } from '../../utils/Times';
import { Description } from '../Conversations';

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;
const CallsQueue = ()=>{

  const WAITING_MINUTES=5;
    
    const { data: callsQueueRecords } = useGet({ route: "/calls-queue" });
    const [showedCallsQueueRecords, setShowedCallsQueueRecords] = useState(callsQueueRecords);
    const { paginationPages, setCurrentPage, showedData } = usePagination({ data: showedCallsQueueRecords, showRows: 20 });
    const [updateData, setUpdateData] = useState(getDateByTime(Date.now()));
    useEffect(()=>{
      setShowedCallsQueueRecords(callsQueueRecords);
    }, [callsQueueRecords]);
    const update = ()=>{
      setShowedCallsQueueRecords(null);
      Utils.Petition.get('/calls-queue', (response)=>{
        setShowedCallsQueueRecords(response.data);
        setUpdateData(getDateByTime(Date.now()));
      })
    }
    return (
        <section className='mx-4 mb-4'>
          <div className="d-flex">
            <FragmentsIcon />
            <Title>Llamadas en espera</Title>
          </div>
          <Description>Por un periodo de {WAITING_MINUTES} minutos, la llamada quedará en espera</Description>
          <div>
            <button onClick={update} className="btn btn-orange d-inline  btn-sm">Actualizar</button>
            (Última actualización: {updateData})
          </div>
          <PaginationTable
            headers={["Teléfono", "Fecha de llamada", "Minutos restantes", "Grupo", "Subgrupo"]}
            withoutRight={false}
            content={showedData}
            pages={paginationPages}
            setPages={setCurrentPage}
            row={CallRecordRow}
          />

        </section>
    )
}
export default CallsQueue;