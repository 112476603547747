import React from 'react'
import CallIcon from '../../../../components/icons/logos/CallIcon';
import MessengerIcon from '../../../../components/icons/logos/MessengerIcon';
import RejectCallIcon from '../../../../components/icons/logos/RejectCallIcon';
import WhatsAppIcon from '../../../../components/icons/logos/WhatsAppIcon';

const ChannelIcon = ({ channel, color }) => {

  switch (channel) {
    case "wa":
    case "whatsapp":
      return <WhatsAppIcon color={color} />

    case "facebook":
    case "fb":
      return <MessengerIcon color={color} />

    case "call":
    case "call-success":
      return <CallIcon color={color} />

    case "call-cancel":
      return <RejectCallIcon color={color} />

    default:
      return <WhatsAppIcon color={color} />
  }

}

export default ChannelIcon;