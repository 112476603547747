import React from 'react'



const RejectedCallIcon = () => {
  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.71404 2.16107C6.00028 2.31882 6.32497 2.57625 6.77193 2.99854L8.72292 4.93162C8.76006 4.96773 8.79419 5.00071 8.82474 5.02997C9.56651 5.74096 9.73083 6.20204 9.58691 6.86333L9.50575 7.16718L9.34065 7.64388L9.28095 7.80831C8.35026 10.3671 8.67602 12.1443 11.4537 14.8055C14.1357 17.3751 15.9569 17.754 18.4814 16.9726L19.3516 16.6895L19.5619 16.6316C20.2903 16.4453 20.77 16.5334 21.4721 17.1538L23.5729 19.0978L23.772 19.2887L24.1084 19.6293C24.3564 19.8924 24.5268 20.1077 24.6439 20.3041C24.9518 20.8202 24.886 21.3714 24.1662 22.2902L23.8846 22.6303C23.6781 22.8679 23.4336 23.1281 23.1474 23.4151L22.5371 24.0053C21.7368 24.7911 20.952 25.1481 19.4135 24.9431C16.7744 24.5913 13.117 22.4108 8.31673 17.8118C3.70977 13.3979 1.42014 9.98232 0.919917 7.46449C0.615079 5.93011 0.968832 5.03791 1.71565 4.30688L2.02469 4.00933L2.06577 3.96015L2.52162 3.53465C2.59421 3.46858 2.66513 3.4049 2.73448 3.34356L3.13202 3.00321C4.39722 1.95963 5.08085 1.81213 5.71404 2.16107Z" fill="#C9CDD2" />
      <path d="M25.5322 0.292893C25.9227 -0.0976312 26.5558 -0.0976312 26.9464 0.292893C27.3014 0.647916 27.3337 1.20347 27.0432 1.59495L26.9464 1.70711L18.9464 9.70711C18.5558 10.0976 17.9227 10.0976 17.5322 9.70711C17.1771 9.35209 17.1449 8.79654 17.4353 8.40506L17.5322 8.2929L25.5322 0.292893Z" fill="#C9CDD2" />
      <path d="M17.5322 0.292893C17.8872 -0.0621289 18.4427 -0.0944036 18.8342 0.196069L18.9464 0.292893L26.9464 8.2929C27.3369 8.68342 27.3369 9.31659 26.9464 9.70711C26.5913 10.0621 26.0358 10.0944 25.6443 9.80394L25.5322 9.70711L17.5322 1.70711C17.1416 1.31658 17.1416 0.683418 17.5322 0.292893Z" fill="#C9CDD2" />
    </svg>
  )
}

export default RejectedCallIcon