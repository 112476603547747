import React from 'react'
import styled, { keyframes } from 'styled-components';

const fillAnim = keyframes`
  0% { fill: white }
  100% { fill: var(--theme-color) }
`

const AnimatedLoader = styled.div`
  width: 600px;
  path {
    animation: ${fillAnim} 2s linear 0.5s infinite alternate;
  }
`

const ChatLoading = () => {
  return (
    <AnimatedLoader>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", background: "rgba(255, 255, 255, 0)", display: "block" }} width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <path d="M78,19H22c-6.6,0-12,5.4-12,12v31c0,6.6,5.4,12,12,12h37.2c0.4,3,1.8,5.6,3.7,7.6c2.4,2.5,5.1,4.1,9.1,4 c-1.4-2.1-2-7.2-2-10.3c0-0.4,0-0.8,0-1.3h8c6.6,0,12-5.4,12-12V31C90,24.4,84.6,19,78,19z" fill="#ff871e"></path>
        <circle cx="30" cy="47" r="5" fill="#ffffff">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1.1764705882352942s" keyTimes="0;0.2;1" values="0;1;1"></animate>
        </circle>
        <circle cx="50" cy="47" r="5" fill="#ffffff">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1.1764705882352942s" keyTimes="0;0.2;0.4;1" values="0;0;1;1"></animate>
        </circle>
        <circle cx="70" cy="47" r="5" fill="#ffffff">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1.1764705882352942s" keyTimes="0;0.4;0.6;1" values="0;0;1;1"></animate>
        </circle>
      </svg>
    </AnimatedLoader>
  )
}

export default ChatLoading;