import React, { useEffect, useState, createRef } from "react";
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { SectionHtmlEditor } from "../styles";
import EmojiPicker from "emoji-picker-react";
const TemplateSection = ({category, parameters, name, getTemplate, setTemplate, props}) => {
    const [text, setText] = useState('');
    const refTextarea = createRef();
    const [openPicker, setOpenPicker] = useState(false);


    const handleFormat = (format, emoji) => {
        if(refTextarea.current){
            let startIndex = refTextarea.current.selectionStart;
            let endIndex = refTextarea.current.selectionEnd;
            
            let beforeText = text.substring(0, startIndex);
            let afterText = text.substring(endIndex);
            let selectedText = text.substring(startIndex, endIndex);
            let formattedText = '';

            switch (format) {
            case 'bold':
                formattedText = `*${selectedText}*`;
                break;
            case 'italic':
                formattedText = `_${selectedText}_`;
                break;
            case 'strikethrough':
                formattedText = `~${selectedText}~`;
                break;
            case 'emoji':
                formattedText = `${selectedText+emoji.emoji}`; // Añade un emoji por ejemplo.
                break;
            default:
                break;
            }

            setTemplate({...getTemplate, text: (beforeText + formattedText + afterText)});
            setText((beforeText + formattedText + afterText));
        }
    };

    const handleEmojiSelected = (emoji)=>{
        handleFormat('emoji', emoji);
    }
    const changeText = (event)=>{
        
        setTemplate({...getTemplate, text:event.target.value});
        setText(event.target.value);
        localStorage.setItem('updated', 'true');
        
    }
    const changeFormat = (event)=>{
        setTemplate({...getTemplate, format: event.target.value});
    }
    const handlePickerButton = ()=>{
        setOpenPicker(!openPicker);
    }
    useEffect(()=>{
        if(parameters){
            const ocurrences = countCoincidences('{', text);
            
            let tempExamples = [...getTemplate.examples];
            if(tempExamples.length > ocurrences){
                tempExamples.splice(ocurrences, tempExamples.length-ocurrences);
            }else{
                if(ocurrences){
                    for(let i = tempExamples.length; i<ocurrences; i++){
                        tempExamples.push('');
                    }
                }
            }
            setTemplate({...getTemplate, examples:tempExamples});
        }
        
    }, [text]);

    const changeExamples = (event, index)=>{
        const tempExamples = [...getTemplate.examples];
        tempExamples[index] = event.target.value;
        setTemplate({...getTemplate, examples:tempExamples});
    }

    const countCoincidences = (wordToFind, wholeText) => {
        const wordToFindRegex = new RegExp(wordToFind, 'gi');
        const occurences = wholeText.match(wordToFindRegex) ?? [];
        return occurences.length;
    }

    return (
        <>
            <div className="form-group">
                <label htmlFor="templateHeader">{name} {getTemplate.type!=='BODY' && '(Opcional)'}</label>
                {
                    getTemplate.type==='HEADER' &&
                    <div className="form-group">
                        <small><label>Formato</label></small>
                        <select className="form-control" onChange={changeFormat} value={getTemplate.format}>
                            <option value="TEXT">Texto</option>
                            <option value="IMAGE">Imagen</option>
                            <option value="DOCUMENT">Documento</option>
                            <option value="VIDEO">Video</option>
                        </select> 
                    </div>
                }
                {
                    getTemplate.type === 'BODY' && category=== 'MARKETING' &&
                    <SectionHtmlEditor>
                        {
                            <>
                                <div className="ql-toolbar ql-snow">

                                    <span className="ql-formats">
                                        <button type="button" className="ql-bold" onClick={() => handleFormat('bold')}>
                                            <svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path className="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg>
                                        </button>
                                        <button type="button" className="ql-italic" onClick={() => handleFormat('italic')}>
                                            <svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line className="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line className="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg>
                                        </button>
                                        <button type="button" className="ql-strike" onClick={() => handleFormat('strikethrough')}>
                                            <svg viewBox="0 0 18 18"> <line className="ql-stroke ql-thin" x1="15.5" x2="2.5" y1="8.5" y2="9.5"></line> <path className="ql-fill" d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path> <path className="ql-fill" d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path> </svg>
                                        </button>
                                    </span>
                                    <span className="ql-formats">
                                        <button type="button" className="ql-emoji" onClick={handlePickerButton}>
                                            {!openPicker && <svg viewBox="0 0 18 18"><circle className="ql-fill" cx="7" cy="7" r="1"></circle><circle className="ql-fill" cx="11" cy="7" r="1"></circle><path className="ql-stroke" d="M7,10a2,2,0,0,0,4,0H7Z"></path><circle className="ql-stroke" cx="9" cy="9" r="6"></circle></svg>}
                                            {
                                                openPicker && 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none">
                                                    <g clipPath="url(#clip0_429_11083)">
                                                        <path d="M7 7.00006L17 17.0001M7 17.0001L17 7.00006" stroke="#292929" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_429_11083">
                                                            <rect width="24" height="24" fill="white"></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            }
                                        </button>
                                    </span>
                                </div>
                                
                                {openPicker && <EmojiPicker onEmojiClick={handleEmojiSelected} />}
                                <textarea ref={refTextarea} {...props} value={getTemplate?.text} required={getTemplate.type==='BODY'} className='form-control' onChange={changeText}/>
                                
                            </>
                            
                        }

                    </SectionHtmlEditor>
                    /*<textarea {...props} value={getTemplate?.text} required={getTemplate.type==='BODY'} className='form-control' onChange={changeText}/>*/
                }
                {
                    (getTemplate.type !== 'BODY' || category !== 'MARKETING') && getTemplate.format==='TEXT' && 
                    
                    <textarea {...props} value={text} required={getTemplate.type==='BODY'} className='form-control' onChange={changeText}/>
                }
            </div>
        
        { getTemplate.examples && getTemplate.examples.length>0 && <small>Datos de muestra (ejemplos):</small>}
        <div className="row">
            {
                getTemplate.examples &&
                getTemplate.examples.map((item, i)=>(
                    <div key={i} className="col-4"><div className="form-group">
                        <input className="form-control" required value={item} placeholder={`{${(i+1)}}`} onChange={(e)=>changeExamples(e,i)} />
                    </div></div>
                ))
            }
        </div>
    </>
    )
}
export default TemplateSection;