import { useState } from 'react';

let queue = [];
let isProcessing = false;

const processQueue = async (handleEvent) => {
  if (isProcessing) return;

  isProcessing = true;

  while (queue.length > 0) {
    const event = queue.shift();
    await handleEvent(event);
  }

  isProcessing = false;
};

export const useEventQueue = (handleEvent) => {
  const [_, forceUpdate] = useState(0);

  const addEventToQueue = (event) => {
    queue.push(event);
    
    processQueue(handleEvent).then(() => {
      forceUpdate((val) => val + 1); // Esto es solo para hacer que el componente React se vuelva a renderizar si es necesario
    });
    
  };

  return {
    addEvent: addEventToQueue,
  };
};