import { MDBContainer } from "mdbreact";
import React, { useEffect, useState } from "react";
import ConversationsIcon from "../../components/icons/pages/ConversationsIcon";
import useGet from "../../hooks/useGet";
import Utils from "../../utils";
import { AlertForm, AlertClose, ICON } from "../../utils/Alert";
import { Description, Title } from "../Conversations";
import Select from 'react-select'

const GroupsAndSubgroups = () => {
  const [groups, setGroups] = useState([]);
  const [newFormGroup, setNewFormGroup] = useState("");
  const { data, isLoading } = useGet({
    route: "/groups/getAll",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [assessors, setAssessors] = useState({'Cargando...':{'Cargando...':''}});
  
  useEffect(() => {
    if (data) {
      Utils.Petition.get(`/clients/users`, (response)=>{
        let asesorData=[]
        console.log(response.data)
        for (const key in response.data) {
          if (Object.hasOwnProperty.call(response.data, key)) {
            const element = response.data[key];
            asesorData.push({value:key,label:key })
          }
        }
        setAssessors(asesorData);
      })
      let tempGroups = [];
      Object.keys(data).map((group) => {
        let groupData = { key: group, data: [], formSubgroup: "" };
        Object.keys(data[group]).map((subgroup) => {
          groupData.data.push({
            key: subgroup,
            data: data[group][subgroup].users,
            formUser: "",
          });
        });
        tempGroups.push(groupData);
      });
      setGroups(tempGroups);
    }
  }, [data]);

  const replaceSpaces = (text) => {
    return text.replace(/\s+/g, "-");
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleKeyPressRegex = (event) => {
    {
      const regex = /^[A-Za-z0-9\-]+$/;

      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  const handleFormGroup = (event) => {
    const newValue = replaceSpaces(event.target.value);
    setNewFormGroup(newValue);
  };
  const handleAddGroup = () => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups.push({ key: newFormGroup, data: [], formSubgroup: "" });
    setGroups(tempGroups);
    setNewFormGroup("");
    localStorage.setItem('updated', 'true');
  };
  const handleFormSubgroup = (event, igroup) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));

    tempGroups[igroup].formSubgroup = replaceSpaces(event.target.value);
    setGroups(tempGroups);
  };
  const handleAddSubgroup = (igroup) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].data.push({
      key: tempGroups[igroup].formSubgroup,
      data: [],
      formUser: "",
    });
    tempGroups[igroup].formSubgroup = "";
    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };
  const handleFormEmail = (event, igroup, isubgroup) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].data[isubgroup].formUser = event.value;
    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };
  const handleAddUser = (igroup, isubgroup) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].data[isubgroup].data.push(
      tempGroups[igroup].data[isubgroup].formUser
    );
    tempGroups[igroup].data[isubgroup].formUser = "";
    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };
  const handleChangeGroup = (event, igroup) => {

    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].key = replaceSpaces(event.target.value);

    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };

  const handleChangeSubgroup = (event, igroup, isubgroup) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].data[isubgroup].key = replaceSpaces(event.target.value);

    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };

  const handleChangeEmail = (event, igroup, isubgroup, iemail) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].data[isubgroup].data[iemail] = event.value;

    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };

  const validForm = () => {
    let isValidForm = true;
    let message = "";
    groups.map((group) => {
      if (group.key === "") {
        isValidForm = false;
        message = "Hay grupos sin nombre";
      }
      group.data.map((subgroup) => {
        if (subgroup.key === "") {
          isValidForm = false;
          message = "Hay subgrupos sin nombre";
        }
        if(subgroup?.data){
          subgroup.data.map((email) => {
            if (!isValidEmail(email)) {
              isValidForm = false;
              message = "Hay emails inválidos";
            }
          });
        }
      });
    });

    groups.map((group, igroup) => {
      groups.map((group2, igroup2) => {
        if (igroup !== igroup2) {
          if (group.key === group2.key) {
            isValidForm = false;
            message = "Hay grupos con el mismo nombre";
          }
        }
        group.data.map((subgroup, isubgroup) => {
          group2.data.map((subgroup2, isubgroup2) => {
            if (igroup === igroup2) {
              if (isubgroup !== isubgroup2) {
                if (subgroup.key === subgroup2.key) {
                  isValidForm = false;
                  message = "Hay subgrupos con el mismo nombre";
                }
              }
            }
            if (igroup === igroup2 && isubgroup === isubgroup2 && subgroup?.data) {
              subgroup.data.map((email, iemail) => {
                subgroup2.data.map((email2, iemail2) => {
                  if (iemail !== iemail2) {
                    if (email === email2) {
                      isValidForm = false;
                      message = `"${email}" está repetido en el subgrupo "${subgroup.key}" del grupo "${group.key}"`;
                    }
                  }
                });
              });
            }
          });
        });
      });
    });

    return { isValidForm, message };
  };

  const formatGroups = () => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    let tempData = {};

    tempGroups.map((group) => {
      group.data.map((subgroup) => {
        if(subgroup?.data){
          subgroup.data.map((email) => {
            if (!tempData[group.key]) {
              tempData[group.key] = {};
            }
            if (!tempData[group.key][subgroup.key]) {
              tempData[group.key][subgroup.key] = { users: [] };
            }
            if (!tempData[group.key][subgroup.key].users.includes(email)) {
              tempData[group.key][subgroup.key].users.push(email);
            }
          });
        }
      });
    });

    return tempData;
  };

  const updateGroups = () => {
    setIsUpdating(true);
    const data = formatGroups();
    const endpoint = "/groups";
    Utils.Petition.patch(
      endpoint,
      data,
      (response) => {
        setIsUpdating(false);
        AlertForm({
          title: "¡Todo salió correctamente!",
          text: `${response.message}`,
          icon: ICON.SUCCESS,
          position: "top-end",
        });
        localStorage.setItem('updated', '');
        Utils.Petition.logs({data, endpoint: endpoint, type: "Update", register: "Gestión de Usuarios: Actualizar Grupos y Subgrupos" });
      },
      (err) => {
        console.warn("Error actualizando grupos..", err.message);
        setIsUpdating(false);
      }
    );
  };

  const saveChanges = () => {
    const { isValidForm, message } = validForm();

    if (!isValidForm) {
      AlertForm({
        title: "¡Espera!",
        text: `${message}. Por favor verifica los campos.`,
        icon: ICON.ERROR,
        position: "top-end",
      });
      return;
    }

    AlertClose();
    updateGroups();
  };

  const handleDeleteGroup = (igroup) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups.splice(igroup, 1);
    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };

  const handleDeleteSubgroup = (igroup, isubgroup) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].data.splice(isubgroup, 1);
    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
   // Utils.Petition.logs({data: deleteGroup, endpoint: "N/A", type: "Delete", register: "Gestión de Usuarios: Eliminar Grupo (Temporal)" });
  };

  const handleDeleteEmail = (igroup, isubgroup, iemail) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].data[isubgroup].data.splice(iemail, 1);
    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };
  const handleSelectChange = (selectedOption, igroup, isubgroup, iemail) => {
    let tempGroups = JSON.parse(JSON.stringify(groups));
    tempGroups[igroup].data[isubgroup].data[iemail] = selectedOption.value;
    setGroups(tempGroups);
    localStorage.setItem('updated', 'true');
  };
  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <MDBContainer className="mb-3">
      <section className="mx-4">
        <div className="d-flex">
          <ConversationsIcon />
          <Title>Grupos y subgrupos</Title>
        </div>
        <Description>
          Estructura para permitir reasignar conversaciones
        </Description>

        <div
          className="p-4 bg-white mb-4"
          style={{
            border: "solid 1px var(--border-color)",
            borderRadius: "16px",
          }}
        >
          {
            /* Grupos */
            groups.map((group, igroup) => (
              <div
                key={igroup}
                className="form-group p-2 rounded"
                style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
              >
                <div className="form-inline mb-3">
                  Grupo:{" "}
                  <input
                    type="text"
                    value={group.key}
                    className={`form-control ${group.key === "" ? "is-invalid" : ""
                      }`}
                    onKeyDown={handleKeyPressRegex}
                    onChange={(event) => handleChangeGroup(event, igroup)}
                    readOnly={true}
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => handleDeleteGroup(igroup)}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                  {group.key === "" && (
                    <p
                      style={{
                        color: "red",
                        marginTop: 20,
                        marginLeft: 10,
                      }}
                    >
                      {" "}
                      &larr; Campo obligatorio
                    </p>
                  )}
                </div>
                {
                  /* Subgrupos */
                  group.data.map((subgroup, isubgroup) => (
                    <div
                      key={isubgroup}
                      className="ml-4 form-group p-2 rounded"
                      style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
                    >
                      <div className="form-inline mb-3">
                        Subgrupo:{" "}
                        <input
                          type="text"
                          className={`form-control ${subgroup.key === "" ? "is-invalid" : ""
                            }`}
                          value={subgroup.key}
                          onKeyDown={handleKeyPressRegex}
                          onChange={(event) =>
                            handleChangeSubgroup(event, igroup, isubgroup)
                          }
                          readOnly={true}
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          onClick={() =>
                            handleDeleteSubgroup(igroup, isubgroup)
                          }
                        >
                          <i className="fa fa-times"></i>
                        </button>
                        { subgroup.key === "" && (
                          <p
                            style={{
                              color: "red",
                              marginTop: 20,
                              marginLeft: 10,
                            }}
                          >
                            {" "}
                            &larr; Campo obligatorio
                          </p>
                        )}
                      </div>
                      {
                        /* Usuarios */
                        subgroup?.data &&
                        subgroup.data.map((email, iemail) => (
                          <div
                            key={iemail}
                            className="ml-4 form-group p-2 rounded"
                            style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
                          >
                            <div className="form-inline">
                              Usuario:{" "}
                              <div style={{width: '300px'}}>

                                <Select
                                  menuPlacement="auto"
                                  menuPosition="fixed"
                                  value={{key:email??"",label:email??""}}
                                  onChange={(selectedOption) =>{handleSelectChange(selectedOption, igroup, isubgroup, iemail)} }
                                  options={assessors}
                                  className={`${!isValidEmail(email) ? "is-invalid" : ""
                                  }`}
                                />
                              </div>
                              {/* <input
                                type="email"
                                className={`form-control ${!isValidEmail(email) ? "is-invalid" : ""
                                  }`}
                                value={email}
                                onKeyDown={handleKeyPress}
                                onChange={(event) =>
                                  handleChangeEmail(
                                    event,
                                    igroup,
                                    isubgroup,
                                    iemail
                                  )
                                }
                                required
                              /> */}
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-danger"
                                onClick={() =>
                                  handleDeleteEmail(igroup, isubgroup, iemail)
                                }
                              >
                                <i className="fa fa-times"></i>
                              </button>
                              {!isValidEmail(email) && (
                                <p
                                  style={{
                                    color: "red",
                                    marginTop: 20,
                                    marginLeft: 10,
                                  }}
                                >
                                  {" "}
                                  &larr; Formato de correo inválido
                                </p>
                              )}
                            </div>
                          </div>
                        ))
                      }
                      <div className="ml-4 form-group">
                        <div className="form-inline align-center">
                          Usuario:{" "}
                          <div style={{width: '300px'}}>
                            <Select
                              menuPlacement="auto"
                              menuPosition="fixed"
                              onChange={(selectedOption) =>{ handleFormEmail(selectedOption, igroup, isubgroup)} }
                              options={assessors}
                              placeholder="Seleccione un usuario"
                              onKeyDown={handleKeyPress}
                              value={{value:subgroup.formUser,label:subgroup.formUser}}
                            />
                          </div>
                          {/* <input
                            type="text"
                            className={`form-control ${!isValidEmail(subgroup.formUser) &&
                                subgroup.formUser !== ""
                                ? "is-invalid"
                                : ""
                              }`}
                            placeholder="Nuevo usuario"
                            onKeyDown={handleKeyPress}
                            onChange={(event) =>
                              handleFormEmail(event, igroup, isubgroup)
                            }
                            value={subgroup.formUser}
                          /> */}
                          <button
                            className="btn btn-sm btn-orange"
                            onClick={() => handleAddUser(igroup, isubgroup)}
                            disabled={
                              subgroup.formUser === "" ||
                              !isValidEmail(subgroup.formUser)
                            }
                          >
                            Agregar
                          </button>
                          {!isValidEmail(subgroup.formUser) &&
                            subgroup.formUser !== "" && (
                              <p
                                style={{
                                  color: "red",
                                  marginTop: 20,
                                  marginLeft: 10,
                                }}
                              >
                                {" "}
                                &larr; Formato de correo inválido
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  ))
                }
                <div className="ml-4 form-group">
                  <div className="form-inline">
                    Subgrupo:{" "}
                    <input
                      type="text"
                      placeholder="Nuevo subgrupo"
                      onKeyDown={handleKeyPressRegex}
                      className="form-control"
                      value={group.formSubgroup}
                      onChange={(event) => handleFormSubgroup(event, igroup)}
                    />
                    <button
                      className="btn btn-sm btn-orange"
                      onClick={() => handleAddSubgroup(igroup)}
                      disabled={group.formSubgroup === ""}
                    >
                      Agregar
                    </button>
                  </div>
                </div>
              </div>
            ))
          }
          <div className="form-group">
            <div className="form-inline">
              Grupo:{" "}
              <input
                type="text"
                placeholder="Nuevo grupo"
                onKeyDown={handleKeyPressRegex}
                className="form-control"
                value={newFormGroup}
                onChange={handleFormGroup}
              />
              <button
                className="btn btn-sm btn-orange"
                onClick={handleAddGroup}
                disabled={newFormGroup === ""}
              >
                Agregar
              </button>
            </div>
          </div>

          <div>
            <button
              disabled={isUpdating}
              onClick={saveChanges}
              className="btn btn-sm btn-orange"
            >
              {!isUpdating ? "Guardar cambios" : "Guardando..."}
            </button>
          </div>
        </div>
      </section>
    </MDBContainer>
  );
};

export default GroupsAndSubgroups;

