import styled from "styled-components";

export const ContainerInput = styled.div`
    padding-top: 12px;
`;

export const ReasignContext = styled.div`
  padding-top: 5px;
  font-size: 14px;
  color: #8c8d8f;
`;

export const OptionsReasign = styled.option`
  font-family: 'Noto Sans JP'
`