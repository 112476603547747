import React from 'react';
import { FooterWraper } from './styles';

export function Footer() {
  return (
    <FooterWraper>
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          <span> Triario {new Date().getFullYear()} &copy; Todos los derechos reservados</span>
        </div>
      </div>
    </FooterWraper>
  );
}
