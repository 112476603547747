import React from 'react';
const MessageRecordRow = ({error , count}) => {
    
    return (
        <tr>
            <td>{error}</td>
            <td>{count}</td>
           
        </tr>
    )
}
export default MessageRecordRow;