import axios from 'axios';
import { API_URL } from '../config';

const Petition = {
  publicGet: (url, callback, fallback, _finally, absolute) => {
    let headers = {'Content-Type': 'application/json;charset=UTF-8'};
    if(!absolute) headers.push({'app':'api-admin'});
    axios
      .get((absolute?'':API_URL) + url, { responseType: 'json', headers })
      .then((result) => {
        callback(result.data);
      })
      .catch((err) => {
        //Add more code all fallbacks

        if (fallback) fallback(err);
      })
      .finally(() => {
        if (_finally) _finally();
      });
  },
  publicPost: (url, data, callback, fallback, _finally) => {
    axios
      .post(API_URL + url, data, { responseType: 'json', headers:{'Content-Type': 'application/json;charset=UTF-8','app':'api-admin'} })
      .then((result) => {
        callback(result.data);
      })
      .catch((err) => {
        //Add more code all fallbacks

        if (fallback) fallback(err);
      })
      .finally(() => {
        if (_finally) _finally();
      });
  },
  get: (url, callback, fallback, _finally, controller = null) => {
    axios
      .get(API_URL + url, {
        responseType: 'json',
        signal: (controller) ? controller.signal : undefined,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token_user'),
          'app':'api-admin'
        },
      })
      .then((result) => {
        callback(result.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Solicitud cancelada',err);
        } else {
          // Manejo de otros errores
          if (fallback) fallback(err);
        }
      })
      .finally(() => {
        if (_finally) _finally();
      });
  },
  delete: (url, callback, fallback, _finally) => {
    axios
      .delete(API_URL + url, {
        responseType: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token_user'),
          'app':'api-admin'
        },
      })
      .then((result) => {
        callback(result.data);
      })
      .catch((err) => {
        //Add more code all fallbacks

        if (fallback) fallback(err);
      })
      .finally(() => {
        if (_finally) _finally();
      });
  },
  post: (url, data, callback, fallback, _finally) => {
    axios
      .post(API_URL + url, data, {
        responseType: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token_user'),
          'app':'api-admin'
        },
      })
      .then((result) => {
        callback(result.data);
      })
      .catch((err) => {
        //Add more code all fallbacks

        if (fallback) fallback(err);
      })
      .finally(() => {
        if (_finally) _finally();
      });
  },
  postFile: (url, data, callback, fallback, _finally) => {
    let _data = new FormData();
    for (let i in data) {
      _data.append(i, data[i]);
    }

    axios
      .post(API_URL + url, _data, {
        responseType: 'json',
        headers: {
          'Content-Type': 'multipart/form-data;',
          Authorization: 'Bearer ' + localStorage.getItem('token_user'),
          'app':'api-admin'
        },
      })
      .then((result) => {
        callback(result.data);
      })
      .catch((err) => {
        //Add more code all fallbacks

        if (fallback) fallback(err);
      })
      .finally(() => {
        if (_finally) _finally();
      });
  },
  patch: (url, data, callback, fallback, _finally) => {
    axios
      .patch(API_URL + url, data, {
        responseType: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token_user'),
          'app':'api-admin'
        },
      })
      .then((result) => {
        callback(result.data);
      })
      .catch((err) => {
        //Add more code all fallbacks

        if (fallback) fallback(err);
      })
      .finally(() => {
        if (_finally) _finally();
      });
  },
  logs: async ({data = {}, endpoint = "", type = "Create", register = "", url = "" }) => {
    let info = {
      data : {
          payload: data,
          endpoint: endpoint
      },
      type: type,
      register: register,
      app: "Admin Talks Pro",
      url: url || window.location.href
  }
    try {
      await axios
      .post(API_URL + "/logs/create", {logs: info}, {
        responseType: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token_user'),
          'app':'api-admin'
        },
      })
    } catch (error) {
      console.warn(error.response);
    }
  },
};
export default Petition;
