import React from 'react'

const CanceledCallIcon = () => {
  return (
    <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.6288 25.7622C28.3003 25.8573 27.8695 25.9071 27.2262 25.9253L24.352 25.9386C24.2978 25.9394 24.2482 25.9402 24.2039 25.9412C23.1288 25.9639 22.666 25.7443 22.2832 25.1485L22.1184 24.8636L21.8878 24.3886L21.8103 24.2228C20.6055 21.6405 19.0493 20.5665 15.0245 20.6526C11.1383 20.7358 9.51022 21.8032 8.22027 24.2495L7.7858 25.103L7.67308 25.3015C7.27189 25.9783 6.85174 26.2682 5.87313 26.3286L2.87995 26.4446L2.5913 26.4506L2.09032 26.4476C1.71207 26.4364 1.42666 26.4032 1.19468 26.3445C0.584958 26.1904 0.225712 25.7339 0.0784288 24.5213L0.0351834 24.0612C0.0121381 23.7325 0.000578572 23.3591 0 22.9349L0.0148322 22.0465C0.025618 20.8728 0.342096 20.0279 1.63234 19.0411C3.84559 17.3484 8.16569 16.2555 15.1212 16.1066C21.7967 15.9638 26.0186 16.7969 28.2519 18.29C29.613 19.1999 30.0114 20.1219 29.9998 21.2155L29.9912 21.6644L29.9972 21.7312L29.9748 22.3834C29.97 22.486 29.9646 22.5856 29.9587 22.6823L29.9164 23.2283C29.7523 24.9368 29.3556 25.5519 28.6288 25.7622Z" fill="#C9CDD2" />
      <path className="not-fill" d="M12.7077 3.73761L5.97998 3.73761M5.97998 3.73761L5.97998 10.4653M5.97998 3.73761L13.8931 11.6507C14.4769 12.2345 15.4235 12.2345 16.0074 11.6507L22.4254 5.23265" stroke="#C9CDD2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CanceledCallIcon;