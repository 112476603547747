import { MDBContainer } from "mdbreact";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import PeopleIcon from "../../components/icons/pages/PeopleIcon";
import PaginationTable from "../../components/tables/PaginationTable";
import usePagination from "../../hooks/usePagination";
import useGet from "../../hooks/useGet";
import AssessorRow from "./components/AssessorRow";
import Utils from "../../utils";
import Conventions from "../../components/ConventionBox/Conventions";


export const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
`;

export const Description = styled.p`
  color: #fa7820;
  font-weight: 500;
`;

const SHOW_ROWS_QUANTITY = 20;

const MILLISECONDS_TO_EXECUTE = 20000;

 const conventionsData = [
    {
      name: "insideGroupAndSubgroup",
      color: "#eafee6",
      description: "Asesor que está dentro del grupo y subgrupo seleccionado. ",
    },
    {
      name: "lastAssigned",
      color: "#fff87d",
      description:"Asesor que tiene la última asignación en el grupo y subgrupo seleccionado",
    },
  ];


const AssessorsStatus = () => {

  let {
    data: assessors,
    error: errorAssessors,
    isLoading,
    setData: setAssessors,
  } = useGet({ route: "/assessors-status" });

  const [allFilteredAssessors, setAllFilteredAssesors] = useState(assessors);
  const [sort, setSort] = useState("status");
  const [orderSort, setOrderSort] = useState("desc");
  const [groups, setGroups] = useState([]);


  const setClickElements = useCallback(
    (assessors) =>
      Array.isArray(assessors) &&
      assessors?.map((assessor) => ({
        assessor,
      })),
    []
  );

  const {
    paginationPages,
    setCurrentPage,
    showedData: showedAssessors,
  } = usePagination({
    data: allFilteredAssessors,
    showRows: SHOW_ROWS_QUANTITY,
    transformShowedData: setClickElements,
  });
  const searchAsesor = async () => {
    let search=document.getElementById("search").value
    if(assessors){
      let _assessors=[]
      _assessors=assessors.filter((e)=>e.email.includes(search))
      setAllFilteredAssesors(_assessors)
    }
  };

  const wantToSortByAssignation = (orderBy) => orderBy !== "name" && orderBy !== "openConv" && orderBy !== "status" && orderBy!=="lastConnection" && orderBy!=="email";
  
  const sortAsesor = async (e) => {
    
    let val=e?.target?.value||sort
    setSort(val);
   
    if (wantToSortByAssignation(val)) {
        let index;
        if (e?.target?.value) 
          index = e.target.selectedIndex - 5;
        else 
          index = groups.findIndex((data) => sort === `${data.group}/${data.subgroup}`);
        
        const groupData = groups[index];
        const usersOfSelectedGroup = groupData.users;
        const usersOfSelectedGroupSet = new Set(groupData.users);
        let matchedAssesors = [];
        let remainingData = [];

        let assesorsCopy = JSON.parse(JSON.stringify(assessors));
        assesorsCopy.forEach((assesor) => {
          if (usersOfSelectedGroupSet.has(assesor["email"])) {
            assesor.rowColor = "#eafee6";
            if (groupData.last_assigned === assesor["email"]) assesor.rowColor = "#fff87d";
            matchedAssesors.push(assesor);
          } else {
            remainingData.push(assesor);
          }
        });
        matchedAssesors.sort((a, b) => {
          return (
            usersOfSelectedGroup.indexOf(a["email"]) -
            usersOfSelectedGroup.indexOf(b["email"])
          );
        });

        setAllFilteredAssesors([...matchedAssesors, ...remainingData]);
      } else if (assessors) {
       
        if(val==="lastConnection"){
          const onlineAssesors = assessors.filter(
            (assesor) => assesor.status === "online"
          );
          const offlineAssesors = assessors.filter(
            (assesor) => assesor.status !== "online"
          );
         
          offlineAssesors.sort((a,b)=>{
            let lastConnectionA = orderSort == "asc" ? b[val] : a[val]; // ignore upper and lowercase
            let lastConnectionB = orderSort == "asc" ? a[val] : b[val];

            if (!lastConnectionA) lastConnectionA = 0;
            if (!lastConnectionB) lastConnectionB = 0;

            if (lastConnectionA < lastConnectionB) {
              return -1;
            }
            if (lastConnectionA > lastConnectionB) {
              return 1;
            }
            return 0;
          })
          if(orderSort==="asc") setAllFilteredAssesors([...onlineAssesors, ...offlineAssesors])
          else setAllFilteredAssesors([...offlineAssesors, ...onlineAssesors])
          return;
        }else{
          assessors.sort((a, b) => {
           

            let nameA = orderSort == "desc" ? b[val] : a[val]; // ignore upper and lowercase
            let nameB = orderSort == "desc" ? a[val] : b[val]; // ignore upper and lowercase

           
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
        }

        

        setAllFilteredAssesors(assessors);
        searchAsesor();
      }
      
  };
  useEffect(() => {
    sortAsesor()
   
    const getGroups = async ()=>{
      Utils.Petition.get("/assessors-status/getSubgroups", (response)=>{
        
        setGroups(response);
      }, (err)=> console.log(err));
    }
    getGroups();
    const getAssessors = async () => {
      Utils.Petition.get(
        "/assessors-status",
        (response) => {
          let res = response.data;
          
          setAssessors(res);
        },
        (err) => console.log(err)
      );
    };
    
    const intervalId = setInterval(async() => {
     
      getAssessors();
    }, MILLISECONDS_TO_EXECUTE);

    return () => {
      clearInterval(intervalId);
    };
  }, [setCurrentPage, assessors, orderSort]);

  return (
    <MDBContainer>
      <section className="mx-4">
        <div className="d-flex">
          <PeopleIcon color={"orange"} width="48px" height="40px" />
          <Title>Estado de asesores</Title>
        </div>
        <Description>Aquí puedes ver el estado de los asesores</Description>
        <div className="row form-group">
          <div className="col-4">
            <label className="form-inline">
              Buscar:
              <input
                type="search"
                className="form-control m-1"
                placeholder="usuario@ejemplo.com"
                id="search"
                onKeyUp={searchAsesor}
              />
            </label>
          </div>
          <div className="col-7">
            <div className="form-inline">
              Ordenar:
              <select
                onChange={sortAsesor}
                value={sort}
                className="form-control m-1"
              >
                <option value="name">Usuario</option>
                <option value="openConv">Conversaciones Abiertas</option>
                <option value="status">Estado</option>
                <option value="email">Correo</option>
                <option value="lastConnection">Ultima Conexión</option>
                {groups &&
                  groups.map((groupData) => {
                    return (
                      <option
                        key={`${groupData.group}-${groupData.subgroup}`}
                        value={`${groupData.group}/${groupData.subgroup}`}
                      >
                        Asignación: {groupData.group}/{groupData.subgroup}
                      </option>
                    );
                  })}
              </select>
              {!wantToSortByAssignation(sort) ? (
                <button
                  className="btn btn-sm"
                  onClick={(e) => {
                    setOrderSort(orderSort === "desc" ? "asc" : "desc");
                  }}
                >
                  {(orderSort === "desc" && "↑ Desc") || "↓ Asc"}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {wantToSortByAssignation(sort) ? (
          <Conventions conventionsData={conventionsData} />
        ) : null}
        <div>
          <PaginationTable
            headers={[
              "Usuario",
              "Correo",
              "Conv. Abiertas",
              "Estado",
              "Lineas Manejadas",
              "Ultima Conexión",
            ]}
            withoutRight={true}
            content={showedAssessors}
            pages={paginationPages}
            setPages={setCurrentPage}
            row={AssessorRow}
          />
        </div>
      </section>
    </MDBContainer>
  );
};

export default AssessorsStatus;

