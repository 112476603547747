import React, { useContext, useEffect, useState } from "react";
import { GroupsContext } from "../../../Context/Groups";
import SelectGroup from "../../../components/selects/SelectGroup";
import SelectSubgroup from "../../../components/selects/SelectSubgroup";
import Petition from "../../../utils/Petition";
import Swal from "sweetalert2";
import { FragmentFormContainer } from "../styles";
import TrashIcon from "../../../assets/Images/trashIcon.svg";

const initialValues = {
  "fragment-name": "",
  "fragment-body": "",
  "fragment-url": "",
  groups: [],
};

const FragmentForm = ({
  fragment: editedFragment,
  setEditedFragment,
  isOpen,
  setOpen,
  reloadData,
}) => {
  const { groupsKeys, isLoading, subgroupsKeysByGroupKey } =
    useContext(GroupsContext);
  const [fields, setFields] = useState(initialValues);
  const validUrl = () => {
    if(!fields["fragment-url"]) return true;
    return(/^https:\/\/[^\s\/$.?#].[^\s]*\.(jpg|jpeg|png|pdf|mp4)$/i).test(fields["fragment-url"]);
  }
  const isValidForm = () => {
    const groupSet = new Set();
    const hasDuplicateGroups = fields.groups.some((g) => {
      if (groupSet.has(g.group)) {
        return true;
      } else {
        groupSet.add(g.group);
        return false;
      }
    });
    return !(
      fields["fragment-name"] === "" ||
      fields["fragment-body"] === "" ||
      !validUrl("fragment-body") ||
      fields.groups.some((g) => g.group === "" || g.subgroups.length === 0) ||
      hasDuplicateGroups
    );
  };

  const isEditing = () => editedFragment && editedFragment._id;

  const addGroup = () => {
    setFields({
      ...fields,
      groups: [...fields.groups, { group: "", subgroups: [] }],
    });
  };

  const removeGroup = (index) => {
    const newGroups = [...fields.groups];
    newGroups.splice(index, 1);
    setFields({
      ...fields,
      groups: newGroups,
    });
  };

  const updateGroup = (index, field, value) => {
    const newGroups = [...fields.groups];
    newGroups[index][field] = value;
    setFields({
      ...fields,
      groups: newGroups,
    });
  };

  const handleSuccessTransaction = (requestData, endpoint) => {
    setFields(initialValues);
    setOpen(false);
    reloadData();
    setEditedFragment(null);

    Swal.fire({
      title: `El fragmento se ha ${
        isEditing() ? "editado" : "creado"
      } con éxito.`,
      icon: "success",
      timer: 2000,
    });
    Petition.logs({data: requestData, endpoint, type: isEditing() ? "Update" : "Create", register: `Fragmentos: ${isEditing() ? "Actualizar" : "Crear"}` })
    document.getElementsByTagName('html')[0].scrollTop=0;
  };

  const handleFailTransaction = () => {
    Swal.fire({
      title: `Ha ocurrido un error al  ${
        isEditing() ? "editar" : "crear"
      } el fragmento, intente nuevamente.`,
      icon: "error",
      timer: 2000,
    });
  };

  const updateFragment = (_id, fragment) => {
    const requestData = {
      fragment: fragment,
      id: _id,
    };
    const endpoint = "/fragments/update";
    Petition.patch(endpoint, requestData,
      () => handleSuccessTransaction(requestData, endpoint),
      handleFailTransaction
    );
  };

  const createFragment = (fragment) => {
    const requestData = {
      fragment: fragment,
    };
    const endpoint = "/fragments/create";
    Petition.post(endpoint, requestData,
      () => handleSuccessTransaction(requestData, endpoint),
      handleFailTransaction
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValidForm()) {
      let fragment = {
        name: fields["fragment-name"],
        body: fields["fragment-body"],
        url: fields["fragment-url"],
        groups: fields.groups,
      };

      if (isEditing()) {
        updateFragment(editedFragment._id, fragment);
      } else {
        createFragment(fragment);
      }
    } else {
      Swal.fire({
        title: "Todos los campos son obligatorios.",
        text: "Debes completar todos los campos para poder realizar esta acción.",
        icon: "error",
        timer: 4000,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value,
    });
  };

  const handleCancel = () => {
    setOpen(false);
    setEditedFragment(null);
    setFields(initialValues);
  };

  const handleClickOutside = (e) => {
    if (
      !e.target.matches("#fragment-form") &&
      !e.target.matches("#fragment-form *")
    ) {
      handleCancel();
    }
  };

  useEffect(() => {
    setFields({
      "fragment-body": editedFragment ? editedFragment.body : "",
      "fragment-name": editedFragment ? editedFragment.name : "",
      "fragment-url": editedFragment ? editedFragment.url : "",
      groups: editedFragment ? editedFragment.groups : [],
    });
  }, [editedFragment]);

  return (
    <FragmentFormContainer isOpen={isOpen} onClick={handleClickOutside}>
      <form onSubmit={handleSubmit} id="fragment-form">
        <h3 className="font-weight-bolder mb-4">
          {isEditing() ? "Editar" : "Crear"} fragmento
        </h3>

        <div className="form-group">
          <label className="font-weight-normal" htmlFor="fragment-name">
            Nombre del fragmento:
          </label>
          <input
            className="form-control"
            placeholder="Ejm: Saludo inicial"
            type="text"
            name="fragment-name"
            id="fragment-name"
            value={fields["fragment-name"] ?? ""}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
        <label className="font-weight-normal" htmlFor="fragment-url">
            Enlace público multimedia (opcional):
        </label>
        <input
            className="form-control"
            placeholder="Ejm: https://example.com/files/image.png"
            type="text"
            name="fragment-url"
            id="fragment-url"
            value={fields["fragment-url"] ?? ""}
            onChange={handleChange}
          />
          <small>Extensiones válidas: .jpg, .png, .pdf, .mp4</small>
        </div>

        <div className="form-group">
          <label className="font-weight-normal" htmlFor="fragment-body">
            Contenido del fragmento:
          </label>

          <textarea
            className="form-control"
            placeholder="Ejm: Buen día señor {{nombre}} es un placer contactarme con usted el día de hoy."
            name="fragment-body"
            id="fragment-body"
            rows="5"
            value={fields["fragment-body"] ?? ""}
            onChange={handleChange}
          ></textarea>
          <small>
            Puedes agregar variables a tus fragmentos añadiendo el nombre entre
            llaves ({"{}"}). Ejm: {"{{nombre}}"}. Asi tus agentes sabrán que
            texto deben cambiar.
          </small>
        </div>

        {fields.groups.map((groupItem, index) => (
          <div key={index}>
            <div className="form-group border-top mt-4 mb-2">
              <label className="font-weight-normal mt-2">Grupo</label>
              <SelectGroup
                name={`group-${index}`}
                handleChange={(e) =>
                  updateGroup(index, "group", e.target.value)
                }
                field={groupItem.group}
                groupsKeys={groupsKeys}
                isLoading={isLoading}
              />
            </div>

            <div className="form-group">
              <label className="font-weight-normal">Subgrupo</label>
              <SelectSubgroup
                name={`subgroup-${index}`}
                field={groupItem.subgroups}
                group={groupItem.group}
                handleChange={(e) => {
                  if (e.target.type === "checkbox") {
                    const valueArray = [...groupItem.subgroups];
                    if (e.target.checked) {
                      valueArray.push(e.target.value);
                    } else {
                      const index = valueArray.indexOf(e.target.value);
                      if (index > -1) {
                        valueArray.splice(index, 1);
                      }
                    }
                    updateGroup(index, "subgroups", valueArray);
                  } else {
                    updateGroup(
                      index,
                      "subgroups",
                      Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      )
                    );
                  }
                }}
                isLoading={isLoading}
                multiple={true}
                subgroupsKeysByGroupKey={subgroupsKeysByGroupKey}
              />
            </div>
            <div className="fragment-actions">
              <button onClick={() => removeGroup(index)}>
                <img src={TrashIcon} alt="Eliminar" />
              </button>
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-end mb-2">
          <button className="orange-button" onClick={addGroup}>
            Agregar Grupo
          </button>
        </div>

        <div className="d-flex">
          <button
            disabled={!isValidForm()}
            type="submit"
            className="btn btn-orange"
          >
            {isEditing() ? "Editar" : "Crear"} fragmento
          </button>

          <button type="reset" onClick={() => handleCancel()} className="btn">
            Cancelar
          </button>
        </div>
      </form>
    </FragmentFormContainer>
  );
};

export default FragmentForm;

