import React from 'react';
import styled from 'styled-components';
import ArrowDownLeftIcon from '../../../../components/icons/arrows/ArrowDownLeftIcon';
import ArrowUpRightIcon from '../../../../components/icons/arrows/ArrowUpRightIcon';
import { getDateByTime } from '../../../../utils/Times';

export const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color:#FA7820;
  border-radius: 100px;
  display: block;
  margin-left: 4px;
`;

const State = ({ state }) => {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      {
        (state==='bot'?'Con bot':
          (state==='close'?'Cerrada':
            (state==='open'?'Abierta':
              (state==='cola'?'En cola':
              state
              )
            )
          )
        )
      }
    </div>
  )
}

export default State;