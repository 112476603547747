import { MDBBtn } from 'mdbreact';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Utils from '../../../utils';
import { AlertForm, ICON } from '../../../utils/Alert';

const EditableRow = ({ name, flow, phone, company, environment, api_wowabot, setEditable, setData }) => {

  const [companyField, setCompanyField] = useState('');
  const [environmentField, setEnvironmentField] = useState('');
  const [wowabotField, setWowabotField] = useState('');

  useEffect(() => {
    setCompanyField(company);
    setEnvironmentField(environment);
    setWowabotField(api_wowabot);
  }, [company, environment, api_wowabot]);

  const isEmpty = (text) => text === "";

  const updateConfig = () => {
    let body = { name, flow, phone, company: companyField, environment: environmentField, api_wowabot: wowabotField, isActive: true }
    if (!isEmpty(companyField) && !isEmpty(environmentField) && !isEmpty(wowabotField)) {
      Utils.Petition.post(
        '/bots/createupdate',
        body,
        (response) => {
          AlertForm({ title: response.message, icon: ICON.SUCCESS });
          setData(Object.keys(body), Object.values(body), phone);
          setEditable(false, phone);
        },
        (err) => {
          console.warn('Error guardar config de teléfonos', err.message)
          AlertForm({ title: err.message, icon: ICON.ERROR });
        }
      );
    } else {
      AlertForm({ title: "Debes completar todos los campos", icon: ICON.ERROR });
    }
  }

  return (
    <tr>
      <td>{phone}</td>
      <td>
        <input
          type="text"
          placeholder="Compañía"
          value={companyField}
          className='form-control'
          onChange={(e) => setCompanyField(e.target.value)}
        />
      </td>

      <td>
        <select
          className='form-control'
          value={environmentField}
          onChange={(e) => setEnvironmentField(e.target.value)}
        >
          <option></option>
          <option value="pruebas">Pruebas</option>
          <option value="produccion">Producción</option>
        </select>
      </td>

      <td>
        <input
          type="text"
          placeholder="API wowabot"
          value={wowabotField}
          className='form-control'
          onChange={(e) => setWowabotField(e.target.value)}
        />
      </td>

      <td>
        <MDBBtn
          size="sm"
          outline
          className="mx-2"
          color="success"
          onClick={() => updateConfig()}
        >
          Guardar
        </MDBBtn>

        <MDBBtn
          size="sm"
          outline
          className="mx-2"
          color="danger"
          onClick={() => setEditable(false, phone)}
        >
          Cancelar
        </MDBBtn>
      </td>
    </tr>
  )
}

export default EditableRow;