import React, { useState } from "react";
import useGet from "../../hooks/useGet";
import { useEffect } from "react";
import axios from "axios";
import Utils from "../../utils";
import useRole from "../../hooks/useRole";
import { EmailNotificationCard } from "./styles";
import ConversationsIcon from "../../components/icons/pages/ConversationsIcon";
import { Title } from "../Conversations";
import { AlertForm, ICON } from "../../utils/Alert";

const UserRoles = ["admin", "user"];
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const Notifications = () => {
  const [clientEmails, setClientEmails] = useState([]);
  const [talksproEmails, setTalkproEmails] = useState([]);
  const { role } = useRole();
  useEffect(() => {
    const getNotifications = async () => {
      Utils.Petition.get("/chat/", (responseBody) => {
        setTalkproEmails(responseBody.data.emailTalkspro || []);
        setClientEmails(responseBody.data.emailClient || []);
      });
    };
    getNotifications();
  }, []);

  const handleChangeEmail = (index, email, type) => {
    let updatedEmails;
    
    switch (type) {
      case "client":
        updatedEmails = [...clientEmails];
        updatedEmails[index] = email.trim();
        setClientEmails(updatedEmails);
        break;
      case "talkspro":
        updatedEmails = [...talksproEmails];
        updatedEmails[index] = email.trim();
        setTalkproEmails(updatedEmails);
        break;
    }
  };

  const handleAddEmail = (type) => {
    let updatedEmails;
    switch (type) {
      case "client":
        updatedEmails = [...clientEmails];
        updatedEmails.push("");
        setClientEmails(updatedEmails);
        break;
      case "talkspro":
        updatedEmails = [...talksproEmails];
        updatedEmails.push("");
        setTalkproEmails(updatedEmails);
        break;
    }
  };

  const handleDeleteEmail = (type, index) => {
    let updatedEmails;
    switch (type) {
      case "client":
        updatedEmails = [...clientEmails];
        updatedEmails.splice(index, 1);
        setClientEmails(updatedEmails);
        break;
      case "talkspro":
        updatedEmails = [...talksproEmails];
        updatedEmails.splice(index, 1);
        setTalkproEmails(updatedEmails);
        break;
    }
  };

  const isValidEmail=(email)=>{
    return emailRegex.test(email.trim())
  }
  const areValidAllEmails = ()=>{

    let areValid = true
    
    if(clientEmails.length){
      clientEmails.forEach((email)=>{
        if(!emailRegex.test(email)) areValid = false
      })
    }
    if(talksproEmails.length){
      talksproEmails.forEach((email)=>{
        if(!emailRegex.test(email)) areValid = false
      })
    }
    return areValid
  }
  const handleSaveChanges = () => {
    if(!areValidAllEmails()){
      AlertForm({
        title: "Correo(s) inválido(s)",
        text: `Por favor intente de nuevo usando correos válidos.`,
        icon: ICON.ERROR,
        position: "top-end",
      })
      return
    }
    let requestData = { emailClient: clientEmails, emailTalkspro: talksproEmails };
    const endpoint =  "/chat/update-emails";
    Utils.Petition.post(
      endpoint,
      requestData,
      (response) => {
        AlertForm({
          title: "¡Todo salió correctamente!",
          text: `${response.message}`,
          icon: ICON.SUCCESS,
          position: "top-end",
      })
        Utils.Petition.logs({data: requestData, endpoint, type: "Update", register: "Notificacion del Plan: Actualizar Correos" })
      },
      (error) => {
        AlertForm({
          title: "Ocurrió un error inesperado!",
          text: `Por favor intente más tarde.`,
          icon: ICON.ERROR,
          position: "top-end",
        })
      }
    );
  };

  return (
    <div className="container mx-3">
      <div className="d-flex">
        <ConversationsIcon/>
        <Title>Notificaciones</Title>
      </div>
      
      
        <EmailNotificationCard className="container bg-white m-2">
          <h3 style={{ fontWeight: 500 }}>Correos del cliente</h3>
          <p>Para recibir notificaciones sobre el consumo de la aplicación, ingrese las direcciones de correo electrónico a las cuales enviaremos dichas notificaciones.</p>
          {clientEmails &&
            clientEmails.map((email, index) => (
              <div key={index}>
                <input
                  className="form-control w-50 m-1 d-inline"
                  type="email"
                  value={email}
                  required
                  onChange={(e) =>
                    handleChangeEmail(index, e.target.value.trim(), "client")
                  }
                  style={{borderColor : isValidEmail(email)? 'gray':'red' }}
                />
                <button
                  className="btn btn-danger d-inline btn-sm"
                  onClick={(e) => {
                    handleDeleteEmail("client", index);
                  }}
                >
                  Eliminar
                </button>
              </div>
            ))}

          <button
            className="btn btn-orange"
            onClick={(e) => {
              handleAddEmail("client");
            }}
          >
            Añadir
          </button>
          {role && role === "admin" && (
            <>
              <h3 style={{ fontWeight: 500 }}>Correos de TalksPro</h3>
              {talksproEmails &&
                talksproEmails.map((email, index) => (
                  <div key={index}>
                    <input
                      className="form-control w-50 m-1 d-inline"
                      type="email"
                      value={email}
                      required
                      onChange={(e) =>
                        handleChangeEmail(index, e.target.value.trim(), "talkspro")
                      }
                    />
                    <button
                      className="btn btn-danger d-inline btn-sm"
                      onClick={(e) => {
                        handleDeleteEmail("talkspro", index);
                      }}
                    >
                      Eliminar
                    </button>
                  </div>
                ))}
              <button
                className="btn btn-orange"
                onClick={(e) => {
                  handleAddEmail("talkspro");
                }}
              >
                Añadir
              </button>
            </>
          )}
        </EmailNotificationCard>
      

      <button
        className="btn btn-orange d-inline btn-sm"
        onClick={handleSaveChanges}
      >
        Guardar cambios
      </button>
    </div>
  );
};

export default Notifications;
