import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import XSLX from 'sheetjs-style';

const ExcelExport = ({excelData, fileName, formatData, asyncData, handleData}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [loading, setLoading] = useState(false);

    const exportToExcel = async () => {
        if(asyncData){
            setLoading(true);
            handleData((data)=>{
                processData(data);
                setLoading(false);
            })
        }else{
            processData(excelData);
        }
        
    }
    const processData = (_excelData)=>{
        let formatedData = JSON.parse(JSON.stringify(_excelData));
        if(formatData) formatedData = formatData(formatedData);
        const ws = XSLX.utils.json_to_sheet(formatedData);
        const wb = { Sheets: {'data': ws}, SheetNames: ['data']}
        const excelBuffer = XSLX.write(wb, {bookType: 'xlsx', type: 'array'});

        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        <button disabled={loading} className="btn btn-orange d-inline  btn-sm" onClick={(e)=> exportToExcel(fileName)}>
            {loading?'Cargando...':'Exportar'}
        </button>
    )

}
export default ExcelExport;