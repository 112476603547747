import  { useEffect, useState } from 'react'

const useRole = () => {
    const [role, setRole] = useState(undefined)
    useEffect(() => {
        const user = (setRole(localStorage.getItem('rol')))
    },[])
    return {role}
}

export default useRole