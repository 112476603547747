import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import Utils from '../../../utils';
import { AlertForm, ICON } from '../../../utils/Alert';
import { ContainerInput, BoldText } from '../styles';

const ChatForm = ()=>{
    

    const [phoneProperty, setPhoneProperty] = useState({ value: 'phone', label: 'Número de teléfono' });
    const [automaticStatus, setAutomaticStatus] = useState();
    const [webhookCheck, setWebhookCheck] = useState(true);
    const [webhook, setWebhook] = useState();
    const [apikey, setApikey] = useState();
    const [enableAsesorname, setEnableAsesorname] = useState();
    const [phoneProperties] = useState([
        { value: 'phone', label: 'Número de teléfono (phone)' },
        { value: 'mobilephone', label: 'Teléfono móvil (mobilephone)' },
        { value: 'hs_whatsapp_phone_number', label: 'Teléfono de Whatsapp (hs_whatsapp_phone_number)' }
    ]);

    useEffect(()=>{
        Utils.Petition.get('/chat', (result)=>{
            if(result.data){
                setPhoneProperty(phoneProperties.find((item)=> item.value===result.data.phone));
                setAutomaticStatus(result.data.automaticStatus);
                setEnableAsesorname(result.data.enableAsesorname);
                setWebhookCheck(result?.data?.webhook?true:false);
                setWebhook(result?.data?.webhook);
                setApikey(result?.data?.apikey);
            }
        });
    }, []);
    const handleSubmit = (event)=>{
        event.preventDefault();
        let webhook=webhookCheck?document.getElementById("webhook").value:null;
        let apikey=webhookCheck?document.getElementById("apikey").value:null;
        const requestData = {
            phone: phoneProperty.value,
            automaticStatus,
            enableAsesorname,
            webhook,
            apikey
        };
        const endpoint = '/chat/create-update';
        Utils.Petition.post(endpoint, requestData, (response)=>{
            AlertForm({
                title: "¡Todo salió correctamente!",
                text: `${response.message}`,
                icon: ICON.SUCCESS,
                position: "top-end",
            });
            Utils.Petition.logs({data: requestData, endpoint, type: "Update", register: "Chat: Actualizar Configuración" })
        }, (err)=>{
            AlertForm({
                title: "Ocurrió un error inesperado!",
                text: `Por favor intente más tarde.`,
                icon: ICON.ERROR,
                position: "top-end",
              });
        })
    }
    const checks = async function (event) {
        console.log(event.target);
        switch(event.target.name){
            case 'enableAsesorname': setEnableAsesorname(event.target.checked);
            break;
            case 'automaticStatus': setAutomaticStatus(event.target.checked);
            break;
            case 'webhookCheck': setWebhookCheck(event.target.checked);
            break;
        }
    }
    const checkWebhook = async function () {
        let webhook=document.getElementById("webhook").value
        if(webhook?.toLowerCase().includes("http://") || webhook?.toLowerCase().includes("https://") || !webhook) document.getElementById("saveData").disabled = false
        else document.getElementById("saveData").disabled = true
    }
    return (
        <form
        className='p-4 bg-white mb-4'
        style={{ border: "solid 1px var(--border-color)", borderRadius: "16px" }}
        onSubmit={handleSubmit}
        >
            <div className="row">
                <div className='col-6'>
                    <label>
                        <BoldText>
                            Propiedad de Hubspot donde se almacenará el número de teléfono
                        </BoldText>
                        <br />
                        <small>Los contactos existentes, usarán esta propiedad para iniciar una conversación y deberá estar en el siguiente formato: Código del país y número de teléfono, ejemplo: +573001234567</small>
                    </label>
                    <Select options={phoneProperties} value={phoneProperty} onChange={(data)=>setPhoneProperty(data)} />
                </div>
            </div>
            <div className="row" style={{marginTop:"30px"}}>
                <div className='col-6'>
                    <label>
                        <BoldText>
                            Nombre de agentes
                        </BoldText>
                        <br />
                        <input type="checkbox" checked={enableAsesorname} id='enableAsesorname' name='enableAsesorname' onClick={checks}/> <small>Mostrar nombre de los agentes cuando envian mensajes desde talkspro , ejemplo:[Triario]: Buen dia.</small>
                        <br />
                    </label>
                    
                </div>
            </div>
            <div className="row" style={{marginTop:"30px"}}>
                <div className='col-6'>
                    <label>
                        <BoldText>
                           Estado de conexion automatico
                        </BoldText>
                        <br />
                       <input type="checkbox" checked={automaticStatus} id='automaticStatus' name='automaticStatus' onClick={checks}/>  <small>Permitir que los agentes puedan cambiar su estado de conexión (si está deshabilitado los agentes no podran cambiar su estado de conexión).</small>
                        <br />
                    </label>
                    
                </div>
            </div>
            <div className="row" style={{marginTop:"30px"}}>
                <div className='col-6'>
                    <BoldText>
                        Webhook
                    </BoldText>
                    <br />
                    <br />
                    <label>
                        
                        <input type="checkbox" checked={webhookCheck} id='webhookCheck' name='webhookCheck' onClick={checks}/>   <small>Permitir el envio de mensajes a una direccion. tener en cuenta que debe ser una direccion HTTP/HTTPS</small>
                    </label>
                    <input type="text"  id='webhook' name='webhook' defaultValue={webhook} disabled={webhookCheck?false:true} className='form-control' onChange={checkWebhook}/> 
                    
                </div>
            </div>
            <div className="row" style={{marginTop:"30px"}}>
                <div className='col-6'>
                    <label>
                        <small>Si se necesita agregar un apikey debera ser por medio de un header de la siguiente forma: <code>{'{apikey:"valor"}'}.</code> si no es necesario, dejar el campo vacio</small>
                    </label>
                    <input type="text"  id='apikey' name='apikey' defaultValue={apikey} disabled={webhookCheck?false:true} className='form-control' onChange={checkWebhook}/> 
                    
                </div>
            </div>
            <ContainerInput>
                <button className="btn btn-sm btn-orange" id='saveData'>Guardar cambios</button>
            </ContainerInput>
            
        </form>
    )
}
export default ChatForm;