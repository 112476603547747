import { MDBBtn } from 'mdbreact';
import React from 'react';

const VisibleRow = ({ company, environment, api_wowabot, flow, phone, setEditable }) => {

  const getTDText = (instance) => (instance) ? instance : 'No hay un dato asociado';

  return (
    <tr>
      <td>{phone}</td>
      <td>{getTDText(company)}</td>
      <td>{getTDText(environment)}</td>
      <td>{getTDText(api_wowabot)}</td>
      <td>
        <MDBBtn
          size="sm"
          outline
          className="mx-2"
          color="dark"
          onClick={() => setEditable(true, phone)}
          disabled={flow === null}
        >
          Editar
        </MDBBtn>
      </td>
    </tr>
  )
}

export default VisibleRow;