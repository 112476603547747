import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../components/icons/CloseIcon";
import CallIcon from "../../../../components/icons/logos/CallIcon";
import MessengerIcon from "../../../../components/icons/logos/MessengerIcon";
import WhatsAppIcon from "../../../../components/icons/logos/WhatsAppIcon";
import ContentLoading from "../../../../components/Loading/ContentLoading";
import useGet from "../../../../hooks/useGet";
import { formatAgents } from "../../../../utils/Format";
import Filter from "./Filter";
import Tag from "./Tag";
import TextFilter from "../../../../components/filters/TextFilter";
import DateFilter from "./DateFilter";

const baseFilters = {
  agent: [],

  state: [
    {
      label: "Abiertas",
      value: "open",
      selected: false,
    },
    // {
    //   label: "Cerradas",
    //   value: "close",
    //   selected: false
    // },
    {
      label: "Con Bot",
      value: "bot",
      selected: false,
    },
    {
      label: "En cola",
      value: "cola",
      selected: false,
    },
  ],
  group: [],
  subgroup:[],
  channel: [
    {
      label: (
        <>
          <MessengerIcon color="orange" width="24" height="24" />{" "}
          <span className="ml-1">Facebook</span>
        </>
      ),
      value: "facebook",
      selected: false,
    },
    {
      label: (
        <>
          <WhatsAppIcon color="orange" width="24" height="24" />{" "}
          <span className="ml-1">WhatsApp</span>
        </>
      ),
      value: "whatsapp",
      selected: false,
    },
    {
      label: (
        <>
          <CallIcon color="orange" width="24" height="24" />{" "}
          <span className="ml-1">Con llamadas</span>
        </>
      ),
      value: "call",
      selected: false,
    },
    /*
    {
      
      label: (
        <>
          <RejectCallIcon color="orange" width="24" height="24" />{" "}
          <span className="ml-1">Llamadas canceladas / rechazadas</span>
        </>
      ),
      value: "call-cancel",
      selected: false,
    },
    {
      label: (
        <>
          <CallIcon color="orange" width="24" height="24" />{" "}
          <span className="ml-1">Llamadas éxitosas</span>
        </>
      ),
      value: "call-success",
      selected: false,
      
    },
    
    {
      label: (
        <>
          <MessengerIcon color="orange" width="24" height="24" />{" "}
          <span className="ml-1">Facebook (Antigüas)</span>
        </>
      ),
      value: "fb",
      selected: false,
    },
    {
      label: (
        <>
          <WhatsAppIcon color="orange" width="24" height="24" />{" "}
          <span className="ml-1">WhatsApp (Antigüas)</span>
        </>
      ),
      value: "wa",
      selected: false,
    },
    */
  ],
};

const Filters = ({ setFilters, showStateFilter = true, groupsAndSubgroups, currentView }) => {
  
  const [fields] = useState({
    state: ["state"],
    agent: ["agent"],
    channel: ["channel", "lastChannel"],
    text: ["email", "identificatorCode", "name", "linePhone"],
    start: ["start"],
    conversation:["conversation"],
    group: ["group"],
    subgroup:["subgroup"]

  });

  useEffect(()=>{
    const NameOfAllGroups = (Object.keys(groupsAndSubgroups).sort())
    const groupsObjects = NameOfAllGroups.map(group => {return {label:group, selected:false, value:group}})
    baseFilters["group"] = (groupsObjects)

  },[groupsAndSubgroups])
  const {
    data: agents,
    error: agentsError,
    isLoading: agentsLoading,
  } = useGet({
    route: "/agents/get-agents",
    format: formatAgents,
  });

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filtersElements, setFiltersElements] = useState({});
  const [onlyShowNoAnswered, setOnlyShowNoAnswered] = useState(false)
  const [subgroupList, setSubgroupList] = useState([])
  //const [groups, setGroups] = useEffect()

  const since = useRef();
  const until = useRef();

  const handleSelected = (field, value, selected, exclude = false) => {
    let copyOfElements = Object.assign({}, filtersElements);
    if(field==="group"){
      
      if(!selected){
        setSubgroupList([])
      }else{
        const list = (groupsAndSubgroups[value]).map((subgroup)=>{
        return { label: subgroup.value, selected: false, value: subgroup.value };
      })
        //baseFilters["subgroup"] = list
        setSubgroupList(list)
      }
     
      copyOfElements["subgroup"] = copyOfElements["subgroup"].map((subgroup)=>{
        subgroup.selected = false
        return subgroup
      })

    }

    if(field==="conversation"){
      setOnlyShowNoAnswered(false);
    } 
    
    if(field==="subgroup") copyOfElements[field] = subgroupList
    
    let filterOptionIndex = copyOfElements[field].findIndex(
      (element) => element.value === value
    );

    if (filterOptionIndex > -1) {
      if (exclude)
        copyOfElements[field].forEach((element) => (element.selected = false));
      copyOfElements[field][filterOptionIndex].selected = selected;
      copyOfElements.agent.sort((a, b) => {
        let option_a = a.label.toLowerCase();
        let option_b = b.label.toLowerCase();
        return option_a < option_b ? -1 : option_a > option_b ? 1 : 0;
      });
      setFiltersElements(copyOfElements);
    }
  };
  //console.log(filtersElements)
  const handleDatesFilter = (dates) => {
    if (dates.since && dates.until) {
      let copyOfSelectedFilters = JSON.parse(JSON.stringify(selectedFilters));

      let datesFilterData = {
        field: "start",
        value: dates,
        selected: true,
      };

      let indexPreviousFilter = copyOfSelectedFilters
        .map((e) => {
          return e.field;
        })
        .indexOf("start");

      if (indexPreviousFilter > -1) {
        copyOfSelectedFilters[indexPreviousFilter] = datesFilterData;
      } else {
        copyOfSelectedFilters.push(datesFilterData);
      }
      let copyOfElements = Object.assign({}, filtersElements);
      let aux = [];
      aux.push(datesFilterData);
      copyOfElements["start"] = aux;
      setFiltersElements(copyOfElements);
    } else {
      let copyOfSelectedFilters = selectedFilters.filter(
        (filter) => filter.field !== "start"
      );
      setSelectedFilters(copyOfSelectedFilters);
      let copyOfElements = Object.assign({}, filtersElements);
      delete copyOfElements["start"];
      setFiltersElements(copyOfElements);

    }
  };

  const handleSearchNoAnswered = (e) =>{
    setOnlyShowNoAnswered(e.target.checked);
       let copyOfSelectedFilters = JSON.parse(JSON.stringify(selectedFilters));
       let copyOfElements = Object.assign({}, filtersElements);
    if(e.target.checked){
       let noAnsweredData = {
         field: "conversation",
         value: true,
         selected: true,
       };

       let indexPreviousFilter = copyOfSelectedFilters
         .map((e) => {
           return e.field;
         })
         .indexOf("conversation");

      if (indexPreviousFilter > -1) {
        copyOfSelectedFilters[indexPreviousFilter] = noAnsweredData;
      } else {
        copyOfSelectedFilters.push(noAnsweredData);
      }
      
      let aux = [];
      aux.push(noAnsweredData);
      copyOfElements["conversation"] = aux;
    
     
   
    }else{
      copyOfSelectedFilters = copyOfSelectedFilters.filter((e) => {
        return e.field !== "conversation";
      });
        delete copyOfElements["conversation"];
        
    }
    setFiltersElements(copyOfElements);
    setSelectedFilters(copyOfSelectedFilters);
  }

  const handleTextFilter = (valueToFind) => {
    let copyOfSelectedFilters = JSON.parse(JSON.stringify(selectedFilters));
    let textFilterData = {
      field: "text",
      value: valueToFind,
      selected: true,
    };

    let indexPreviousFilter = copyOfSelectedFilters
      .map((e) => {
        return e.field;
      })
      .indexOf("text");


    if (indexPreviousFilter > -1) {
      if (valueToFind === "") {
        copyOfSelectedFilters = copyOfSelectedFilters.filter((e) => {
          return e.field !== "text";
        });
      } else {
        copyOfSelectedFilters[indexPreviousFilter] = textFilterData;
      }
    } else {
      if (valueToFind !== "") copyOfSelectedFilters.push(textFilterData);
    }

    let copyOfElements = Object.assign({}, filtersElements);
    let aux = [];
    aux.push(textFilterData);
    copyOfElements["text"] = aux;
   
    setFiltersElements(copyOfElements);
    
  };

  useEffect(() => {
    baseFilters.agent = agents ?? [];
    baseFilters.agent.sort((a, b) => {
      let option_a = a.label.toLowerCase();
      let option_b = b.label.toLowerCase();
      return option_a < option_b ? -1 : option_a > option_b ? 1 : 0;
    });
    setFiltersElements(baseFilters);

    return () => setFiltersElements({});
  }, [agents]);

  useEffect(() => {
    const onlyFiltered = {};
    const filteredToTags = [];
    for (const key in filtersElements) {
      if (Object.hasOwnProperty.call(filtersElements, key)) {
        
        const selectedForCategory = filtersElements[key].filter(
          (option) => option.selected === true
        );
        
        if (selectedForCategory.length > 0) {
          onlyFiltered[key] = {
            values: selectedForCategory.map((option) => option.value),
            fields: fields[key],
          };
          filteredToTags.push(
            ...selectedForCategory.map((option) => ({
              field: key,
              value: option.value,
            }))
          );
        }
      }
    }
    
    setSelectedFilters(filteredToTags);
    setFilters(onlyFiltered);

    return () => setSelectedFilters([]);
  }, [filtersElements, fields, setFilters, showStateFilter]);

  useEffect(() => {
    
    if (currentView==="calls"){
      let closed = { label: "Cerradas", value: "close", selected: false}
      filtersElements.state.push(closed)
      let channels = filtersElements.channel.filter((channel)=>{
        return channel.value !== "call"
      })
      filtersElements.channel = channels
  
    }
    if (!showStateFilter) {
      let element = selectedFilters.find(
        (elementSelected) =>
          elementSelected.value === "open" ||
          elementSelected.value === "bot" ||
          elementSelected.value === "cola" ||
          elementSelected.value === "close"
      );

      if (element) {
        handleSelected("state", element.value, false);
      }
    }
  }, [showStateFilter, currentView]);

  return (
    <div>
      <div className="d-flex">
        {agentsLoading && (
          <div className="mt-3 d-flex">
            <ContentLoading aproxWidth={"100px"} className="mr-1" />
            <ContentLoading aproxWidth={"100px"} className="mr-1" />
            <ContentLoading aproxWidth={"100px"} className="mr-1" />
          </div>
        )}

        {agents && !agentsError && (
          <>
            {/*showStateFilter && (
              <Filter
                label={"Estado"}
                field="state"
                options={filtersElements.state}
                setSelected={handleSelected}
                exclude={true}
              />
            )*/}

            <Filter
              label={"Agente"}
              field="agent"
              options={filtersElements.agent}
              setSelected={handleSelected}
            />
            <Filter
              label={"Grupo"}
              field="group"
              exclude={true}
              options={filtersElements.group}
              setSelected={handleSelected}
            />
            <Filter
              label={"Subgrupo"}
              field="subgroup"
              options={subgroupList}
              setSelected={handleSelected}
            />

            <Filter
              field="channel"
              label={"Canal"}
              setSelected={handleSelected}
              options={filtersElements.channel}
            />
          </>
        )}
        <TextFilter setTextFilter={handleTextFilter} />

        {agentsError && <div>Error al cargar los filtros.</div>}
      </div>
      <div className="d-block my-2 ">
        <DateFilter
          setDateFilter={handleDatesFilter}
          since={since}
          until={until}
        />
      </div>
      <div className="mx-2">
        <input
          id="searchNoAnswered"
          type="checkbox"
          className="form-check-input"
          onChange={handleSearchNoAnswered}
          checked={onlyShowNoAnswered}
        />
        <label htmlFor="searchNoAnswered">
          Mostrar sólo conversaciones que no han sido contestadas
        </label>
      </div>
      <div className="mb-2 d-flex flex-wrap" style={{ gap: "8px 4px" }}>
        {selectedFilters.map((select, i) => {
          let element = filtersElements[select.field].find(
            (elementSelected) => elementSelected.value === select.value
          );

          if (element) {
            let sinceDate = "";
            let sinceHour = "";
            let untilDate = "";
            let untilHour = "";
            if (select.field === "start") {
              sinceDate = new Date(element.value.since).toLocaleDateString();
              untilDate = new Date(element.value.until).toLocaleDateString();
              let sinceMinutes = new Date(element.value.since)
                .getMinutes()
                .toString()
                .padStart(2, "0");
              let sinceHours = new Date(element.value.since)
                .getHours()
                .toString()
                .padStart(2, "0");

              let untilHours = new Date(element.value.until)
                .getHours()
                .toString()
                .padStart(2, "0");
              let untilMinutes = new Date(element.value.until)
                .getMinutes()
                .toString()
                .padStart(2, "0");
              sinceHour = `${sinceHours}:${sinceMinutes}`;
              untilHour = `${untilHours}:${untilMinutes}`;
            }
            return (
              <Tag key={i}>
                {select.field === "text"
                  ? "Busqueda: " + element.value
                  : select.field === "start"
                  ? `Desde ${sinceDate} ${sinceHour} Hasta ${untilDate} ${untilHour}`
                  : select.field === "conversation"
                  ? `No contestadas`
                  : element.label}{" "}
                <CloseIcon
                  color="orange"
                  width="12"
                  height="12"
                  onClick={() =>
                    handleSelected(select.field, select.value, false)
                  }
                />
              </Tag>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Filters;

