import { useState } from "react";

const emptyValues = {
  "campaign-name": '',
  "campaign-group": '',
  "campaign-subgroup": '',
  "campaign-bot": '',
  "is-campaign-expiration": '',
  "campaign-expiration": '',
  "campaign-redirection": ''
}

const useCampaignForm = (name = "", isExpiration = "", expiration = "", redirection = "", to = {}) => {
  const [fields, setFields] = useState({
    "campaign-name": name,
    "campaign-group": to?.group,
    "campaign-subgroup": to?.subgroup,
    "campaign-bot": to?.bot,
    "is-campaign-expiration": isExpiration?"yes":"no",
    "campaign-expiration": expiration,
    "campaign-redirection": redirection
  });

  const handleChange = (e) => {
   
    setFields({ ...fields, [e.target.name]: e.target.value });
  }
  const handleChangeIsCampaignExpiration = (e) => {
    setFields({ ...fields, 'is-campaign-expiration': e.target.checked===true?'yes':'no' });
  }

  const clearForm = () => {
    setFields(emptyValues);
  }

  return { fields, handleChange, handleChangeIsCampaignExpiration, clearForm };
}

export default useCampaignForm;