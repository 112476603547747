import React from 'react'
import { useColor } from '../../../hooks/useColor';

const RejectCallIcon = ({ full, color, ...props }) => {

  const colorCode = useColor(color);

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 29C22.7319 29 29 22.7319 29 15C29 7.26785 22.7319 1 15 1C7.26806 1 1 7.26785 1 15C1 22.7319 7.26807 29 15 29Z" stroke={colorCode} strokeWidth="2" />
      <path d="M22.2197 7.21956C22.5126 6.92667 22.9874 6.92667 23.2803 7.21956C23.5466 7.48583 23.5708 7.90249 23.3529 8.1961L23.2803 8.28022L17.2803 14.2802C16.9874 14.5731 16.5126 14.5731 16.2197 14.2802C15.9534 14.014 15.9292 13.5973 16.1471 13.3037L16.2197 13.2196L22.2197 7.21956Z" fill={colorCode} />
      <path d="M16.2197 7.21956C16.4859 6.9533 16.9026 6.92909 17.1962 7.14694L17.2803 7.21956L23.2803 13.2196C23.5732 13.5125 23.5732 13.9873 23.2803 14.2802C23.0141 14.5465 22.5974 14.5707 22.3038 14.3528L22.2197 14.2802L16.2197 8.28022C15.9268 7.98733 15.9268 7.51246 16.2197 7.21956Z" fill={colorCode} />
      <path d="M9.31778 7.38555C9.52643 7.50553 9.76309 7.70133 10.0889 8.02252L11.5109 9.4928C11.538 9.52026 11.5629 9.54534 11.5852 9.5676C12.1258 10.1084 12.2456 10.4591 12.1407 10.962L12.0815 11.1931L11.9612 11.5557L11.9177 11.6808C11.2393 13.627 11.4768 14.9786 13.5014 17.0028C15.4563 18.9571 16.7838 19.2453 18.6239 18.651L19.2582 18.4357L19.4114 18.3916C19.9424 18.25 20.292 18.3169 20.8037 18.7888L22.335 20.2674L22.4801 20.4126L22.7254 20.6716C22.9061 20.8718 23.0303 21.0355 23.1157 21.1849C23.3401 21.5774 23.2922 21.9967 22.7675 22.6955L22.5622 22.9542C22.4117 23.1349 22.2335 23.3328 22.0248 23.5511L21.58 24C20.9967 24.5976 20.4247 24.8692 19.3033 24.7132C17.3796 24.4457 14.7137 22.7872 11.2149 19.2893C7.85689 15.9321 6.18798 13.3343 5.82337 11.4193C5.60118 10.2522 5.85903 9.57364 6.40338 9.01762L6.62864 8.79132L6.65858 8.75391L6.99085 8.43028C7.04375 8.38003 7.09545 8.33159 7.146 8.28494L7.43576 8.02607C8.35796 7.23234 8.85626 7.12015 9.31778 7.38555Z" fill={colorCode} />
    </svg>
  )
}

export default RejectCallIcon;