import React from "react";

const ArrowDownIcon = (props) => {

 
  const fillColor = !props.dark ? "#3D3D3D" : "#FFFFFF";
  

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.97945 5.81313C3.15697 5.63562 3.43474 5.61949 3.63048 5.76472L3.68656 5.81313L7.99968 10.126L12.3128 5.81313C12.4903 5.63562 12.7681 5.61949 12.9638 5.76472L13.0199 5.81313C13.1974 5.99064 13.2135 6.26842 13.0683 6.46416L13.0199 6.52024L8.35323 11.1869C8.17572 11.3644 7.89794 11.3806 7.7022 11.2353L7.64612 11.1869L2.97945 6.52024C2.78419 6.32498 2.78419 6.0084 2.97945 5.81313Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowDownIcon;
