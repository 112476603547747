import React, { useEffect, useState } from 'react';

const DateFilter = ({ setDateFilter, since, until }) => {
  const [sinceDate, setSinceDate] = useState(since);
  const [untilDate, setUntilDate] = useState(until);

  useEffect(() => {
    if (sinceDate || untilDate) {
      const sinceTime = new Date(sinceDate).getTime();
      const untilTime = new Date(untilDate).getTime();
      setDateFilter({ since: sinceTime, until: untilTime });
    } 
  }, [sinceDate, untilDate]); 

  const handleChange = (e) => {
    
    if (e.target.id === "since") {
      setSinceDate(e.target.value);
    } else if (e.target.id === "until") {
      setUntilDate(e.target.value);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className="since">
        <label htmlFor="since">Desde: </label>
        <input
          type="datetime-local"
          className="form-control p-2"
          id="since"
          value={sinceDate}
          onChange={handleChange}
        />
      </div>
      <div className="until ml-2">
        <label htmlFor="until">Hasta: </label>
        <input
          type="datetime-local"
          className="form-control p-2"
          id="until"
          value={untilDate}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default DateFilter;
