import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
  MDBContainer,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact';
import { SectionConfig, SectionFlowMessages } from './styles';
import Utils from '../../utils';
import { AlertForm, ICON } from '../../utils/Alert';
import CardMessageFlow from '../../components/cards/CardMessageFlow';

export const Bots = () => {
  const [dataTableBots, setDataTableBots] = useState([]);
  const [dataFlows, setDataFlows] = useState([]);
  const [messageFlows, setMessageFlows] = useState([]);
  const [nameFlow, setNameFlow] = useState('');
  useEffect(() => {
    Utils.Petition.get(
      '/bots/getnumbers',
      (response) => {
        /* setLogin(true);
        setLoginAut(false);
         */
        let dataTable = response.data.map((el) => {
          return {
            ...el,
            isActive: false,
            flowInitial: el.flow,
          };
        });
        setDataTableBots(dataTable);
      },
      (err) => console.warn('Error obtener Numeros', err.message),
      () => {
        /* setLoading(false);
        setLoad(true); */
      }
    );
    Utils.Petition.get(
      '/bots/getflows',
      (response) => {
        let dataFlows = response.data.flows.map((el) => {
          return {
            value: el.sid,
            label: el.friendly_name,
          };
        });
        setDataFlows(dataFlows);
      },
      (err) => console.warn('Error obtener Flujos', err.message)
    );
  }, []);

  const editConfig = (phone) => {
    let dataTable = [...dataTableBots];
    for (const item of dataTable) {
      if (item.phone === phone) {
        item.isActive = true;
        break;
      }
    }
    setDataTableBots(dataTable);
  };
  const saveConfig = (phone, flow, label) => {
    //TODO Peticion de guardar
    Utils.Petition.post(
      '/bots/createupdate',
      {
        phone,
        flow,
        isActive: true,
        name: label
      },
      (response) => {
        AlertForm({ title: response.message, icon: ICON.SUCCESS });
      },
      (err) => console.warn('Error guardar config Flujos', err.message)
    );
    let dataTable = [...dataTableBots];
    for (const item of dataTable) {
      if (item.phone === phone) {
        item.isActive = false;
        break;
      }
    }
    setDataTableBots(dataTable);
  };
  const cancelConfig = (phone) => {
    let dataTable = [...dataTableBots];
    for (const item of dataTable) {
      if (item.phone === phone) {
        item.isActive = false;
        item.flow = item.flowInitial;
        break;
      }
    }
    setDataTableBots(dataTable);
  };
  const selectFlow = (flow, phone) => {
    let dataTable = [...dataTableBots];
    for (const item of dataTable) {
      if (item.phone === phone) {
        item.flow = flow;
        break;
      }
    }
    setDataTableBots(dataTable);
  };
  const getFlow = (flow) => {
    setNameFlow(flow);
    Utils.Petition.get(
      `/bots/getflowdefinition/${flow}`,
      (response) => {
        let data = response.data.filter((el) => el.body);
        setMessageFlows(data);
      },
      (err) => console.warn('Error obtener mensajes del Flujo', err.message)
    );
    //setMessageFlows
  };

  const findFlowLabel = (sid) => {
    try {
      let flow = dataFlows.find(flow => flow.value === sid);
      if (flow) return flow.label; else throw new Error()
    } catch (err) {
      console.warn('Error obtener el nombre del flujo', err.message)
    }
  }

  const getFlowText = (flow) => (flow) ? findFlowLabel(flow) : 'no hay flujo asociado';

  return (
    <MDBContainer className="mb-3">
      <SectionConfig>
        <MDBCard>
          <MDBCardHeader>Administrador de Bots</MDBCardHeader>
          <MDBCardBody>
            <MDBCardTitle>Tabla de Configuración</MDBCardTitle>

            <MDBTable align="middle">
              <MDBTableHead>
                <tr>
                  <th scope="col">Numero</th>
                  <th scope="col">Bot</th>
                  <th scope="col">Acciones</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {dataTableBots.map((el) => {
                  return (
                    <tr key={el.phone}>
                      <td>
                        <div className="ms-3">
                          <p className="fw-bold mb-1">{el.phone}</p>
                        </div>
                      </td>
                      <td>
                        {el.isActive && (
                          <MDBDropdown group className="shadow-0">
                            <MDBDropdownToggle color="light" size="sm" style={{ width: '280px' }}>
                              {getFlowText(el.flow)}
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                              {dataFlows.map((flow) => {
                                return (
                                  <MDBDropdownItem
                                    key={flow.value}
                                    link={true}
                                    onClick={() => selectFlow(flow.value, el.phone)}
                                  >
                                    {flow.label}
                                  </MDBDropdownItem>
                                );
                              })}
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        )}
                        {!el.isActive && <p className="fw-bold mb-1">{getFlowText(el.flow)}</p>}
                      </td>

                      <td>
                        {!el.isActive && (
                          <MDBBtn size="sm" outline className="mx-2" color="dark" onClick={() => editConfig(el.phone)}>
                            Editar
                          </MDBBtn>
                        )}
                        {el.isActive && (
                          <>
                            <MDBBtn size="sm" outline className="mx-2" color="success" onClick={() => saveConfig(el.phone, el.flow, getFlowText(el.flow))}>
                              Guardar
                            </MDBBtn>
                            <MDBBtn
                              size="sm"
                              outline
                              className="mx-2"
                              color="danger"
                              onClick={() => cancelConfig(el.phone)}
                            >
                              Cancelar
                            </MDBBtn>
                          </>
                        )}
                        {el.flow && (
                          <MDBBtn size="sm" outline className="mx-2" color="secondary" onClick={() => getFlow(el.flow)}>
                            Ver Flujo
                          </MDBBtn>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
      </SectionConfig>

      <SectionFlowMessages>
        <MDBCard className="mt-5">
          <MDBCardHeader>Flujo del Bot</MDBCardHeader>
          <MDBCardBody className="card-body">
            {nameFlow && <MDBCardTitle className="text-sticky">Incoming Message {findFlowLabel(nameFlow)}</MDBCardTitle>}
            {!nameFlow && <p>Seleccione Ver flujo, para visualizar los mensajes</p>}
            {messageFlows.map((el, i) => {
              return (
                <CardMessageFlow {...el} key={i} id={i} />
                // <CardFlowStyles>
                //   <MDBCardBody>
                //     <MDBInput type="textarea" label="Mensaje" valueDefault={el.body} rows="4" readonly="true" />
                //     <div className="d-flex">
                //       <MDBBtn color='link' size='sm ml-auto mr-0 my-0'>Editar</MDBBtn>
                //       <MDBBtn size="sm" outline color="success">Guardar</MDBBtn>
                //       <MDBBtn size="sm" outline color="danger"> Cancelar </MDBBtn>
                //     </div>
                //   </MDBCardBody>
                // </CardFlowStyles>
              );
            })}
          </MDBCardBody>
        </MDBCard>
      </SectionFlowMessages>
    </MDBContainer>
  );
};
