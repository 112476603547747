import React from 'react'
import styled from 'styled-components';
import ChannelIcon from '../chats/ChannelIcon';

const Card = styled.div`
  display: flex;
  .channel-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }

  .contact-name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .contact-email {
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
`;

const ContactCard = ({ channel, name, number, email }) => {

  return (
    <Card>
      <div className="channel-icon">
        <ChannelIcon channel={channel} color="orange" />
      </div>
      <div>
        <h5 className="contact-name">{name}</h5>
        <span>{number}</span>
        {email ? (
          <>
            <br />
            <p className="contact-email">{email}</p>
          </>
        ): <></>}
      </div>
    </Card>
  );
}

export default ContactCard;