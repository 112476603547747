import styled from 'styled-components';

const Tag = styled.div`
  background-color: var(--seda-color);
  border-radius: 40px;
  color: var(--primary-color);
  width: max-content;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    margin-left: 8px;
    cursor: pointer;
  }
`;

export default Tag;