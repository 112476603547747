import React from 'react'
import { useColor } from '../../../hooks/useColor'

const ConversationsIcon = ({ color = "orange", ...props }) => {
  
  const colorCode = useColor(color);
  return (
    <svg width="49" height="40" viewBox="0 0 49 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.01543 7.69485C1.48033 7.6956 0.967284 7.90889 0.589169 8.28775C0.211053 8.6666 -0.000742622 9.18045 1.96261e-06 9.71553V28.9554C-0.000743768 29.4909 0.211053 30.0043 0.589169 30.3832C0.967275 30.7624 1.48037 30.9757 2.01543 30.9761H4.15392L4.67063 37.9C4.73336 38.7405 5.74401 39.1312 6.35577 38.5514L14.3483 30.9761H29.5197C30.0555 30.9757 30.5694 30.7628 30.9482 30.3839C31.3271 30.0051 31.54 29.4913 31.5404 28.9554V25.9562H18.5923C17.3479 25.9548 16.1551 25.4596 15.2754 24.5799C14.3954 23.6999 13.9006 22.507 13.8987 21.2627V7.69502L2.01543 7.69485Z" fill={colorCode} />
      <path d="M33.7617 23.2825L41.7553 30.8591C42.3669 31.4389 43.3775 31.0483 43.4404 30.2079L43.9583 23.2825H46.0923C46.6278 23.2825 47.1416 23.0695 47.5205 22.6911C47.8989 22.3122 48.1119 21.7987 48.1119 21.2629V2.01919C48.1119 1.48372 47.8989 0.969898 47.5205 0.591401C47.1416 0.212541 46.6278 -0.000371804 46.0923 4.88889e-07H18.5914C18.0559 -0.000372376 17.5421 0.212551 17.1632 0.591401C16.7847 0.969879 16.5718 1.48373 16.5718 2.01919V21.2629C16.5718 21.7987 16.7847 22.3122 17.1632 22.6911C17.542 23.0695 18.0559 23.2825 18.5914 23.2825H33.7617Z" fill={colorCode} />
    </svg>
  )
}

export default ConversationsIcon