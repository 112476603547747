import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBCardBody, MDBInput } from 'mdbreact';
import { CardFlowStyles } from '../../Views/Bots/styles';

const CardMessageFlow = ({ body, id }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [text, setText] = useState('');
  const [ref, setRef] = useState();

  useEffect(() => {
    isEditable ? ref.focus() : setText(body);
  }, [isEditable, ref, body]);

  return (
    <CardFlowStyles>
      <MDBCardBody>
        <MDBInput value={text} type="textarea" label="Mensaje" valueDefault={body} rows="4" readOnly={!isEditable} onChange={(e) => setText(e.target.value)} inputRef={ref => setRef(ref)} />

        <div className="d-flex justify-content-end">
          {!isEditable ?
            <MDBBtn color='link' size='sm ml-auto mr-0 my-0' onClick={() => setIsEditable(true)}>Editar</MDBBtn>
            :
            <>
              <MDBBtn size="sm" outline color="success">Guardar</MDBBtn>
              <MDBBtn size="sm" outline color="danger" onClick={() => setIsEditable(false)}>Cancelar</MDBBtn>
            </>
          }
        </div>
      </MDBCardBody>
    </CardFlowStyles>
  )
}

export default CardMessageFlow;