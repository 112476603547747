import React from "react";
import styled from "styled-components";
import ShowMessagesIcon from "./../../../../components/icons/messages/ShowMessagesIcon";
import Interaction from "../contacts/Interaction";
import State from "../contacts/State";
import ArrowDownIcon from "../../../../components/icons/arrows/ArrowDownIcon";
const Header = styled.div`
  width: 100%;
  padding: 16px;
  background-color: var(--header-conversation-color);
  color: white;
  font-weight: 500;
  font-size: 12px;
  border: none;
  
  .w-6 {
    width: 60%;
  }
`;

const SubHeader = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #f8f9fc;
  color: black;
  font-size: 12px;
  border: none;
  position: relative;
  //z-index:999;

  .w-6 {
    width: 60%;
  }
`;

const ResulutionTime = styled.div`
  font-size: 12px;
  text-align: right;
  width: 45%;
  p {
    margin: 0;
  }
`;

const ShowHideSystemMessages = styled.div`
  margin-left: 4px;
  cursor: pointer;
`;

const ShowHideUserInfo = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const ChatHeader = ({
  conversation,
  resolutionTime,
  showSystemMessages = true,
  setShowSystemMessages,
  setShowUserInfo,
  showUserInfo = false,
  phoneLines
}) => {
  const getPhoneLine = (line)=>{
    if(line && phoneLines){
      let phoneLine = phoneLines.find((item)=>item._id==line);
      return (phoneLine)? phoneLine.phone + ', '+phoneLine.name:line;
    }
    return "Sin línea"
  }
  return (
    <div>
      <Header>
        <div className="d-flex justify-content-space-between align-items-center w-100">
          <span className="">
            Conversación con {conversation?.name || "No name"}
          </span>

          <ResulutionTime>
            {resolutionTime !== "En curso" && <p>Tiempo de resolución</p>}
            <span>{resolutionTime}</span>
          </ResulutionTime>
          <ShowHideSystemMessages>
            <ShowMessagesIcon
              enabled={showSystemMessages}
              onClick={setShowSystemMessages}
            />
          </ShowHideSystemMessages>
          <ShowHideUserInfo>
                <ArrowDownIcon
                  className={showUserInfo ? "fa-rotate-180" : ""}
                  onClick={setShowUserInfo}
                  dark="true"
                />
              </ShowHideUserInfo>
        </div>
      </Header>
      {showUserInfo && (
        <SubHeader>
          <div className="">
            <span className="">
              Email: <strong>{conversation?.email || "No email"}</strong>
            </span>
            <br />
            <span className="">
              Tel.:{" "}
              <strong>
                {conversation?.identificatorCode || "No identificatorCode"}
              </strong>
            </span>
            <br />
            <span className="">
              Agente: <strong>{conversation?.owner || "Sin asignar"}</strong>
            </span>
            <br />
            <span className="">
              Grupo / Subgrupo: <strong>{conversation?.group || "Sin grupo"} / {conversation?.subgroup || "Sin subgrupo"}</strong>
            </span>
            <br />
            <span className="">
              Línea: <strong>{getPhoneLine(conversation.line)}</strong>
            </span>
            <br />
            {
              conversation.properties && Object.keys(
                conversation.properties).map((property)=>(
                  <span>{property}: <strong>{conversation.properties[property]}</strong></span>
                )
              )
            }

            <div className="d-flex flex-row justify-content-between align-items-center mt-3">
              <strong>
                <State state={conversation?.state} />
              </strong>

              <Interaction
                direction={conversation?.direction}
                state={conversation?.state}
                date={conversation?.lastInteraction}
              />
            </div>
          </div>
        </SubHeader>
      )}
    </div>
  );
};

export default ChatHeader;

