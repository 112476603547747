import styled from "styled-components";

export const ConversationTable = styled.table`
  border-radius: ${props => props.withoutRight ? "8px 0px 0px 8px" : "8px" };
  border-right: ${props => props.withoutRight ? "0px none !important" : "8px" } ;
  background-color: white;
  font-size: 14px !important;
  width: 100%;
  border: solid 1px var(--border-color);
  overflow: hidden;
  max-height: 100%;

  tr {
    border-bottom: solid 1px var(--border-color);
    width: max-content;
  }

  thead {
    color: white;
    border-radius: 8px 0px 0px 0px;
    width: max-content;
    tr {
      background-color: var(--header-conversation-color);
      th {
        padding: 8px 16px;
        white-space: nowrap;
      }
    }
  }

  tbody {
    border-radius: 0px 0px 0px 8px;
    width: max-content;
    tr {
      td {
        padding: 12px;
      }
    }
  }

  tfoot {
    border-radius: 0px 0px 0px 8px;
  }

  @media only screen and (max-width: 1250px) {
    td, th {
      white-space: nowrap;
    }
  }
  
`;

export const TextElipsis = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const MessagesContainer = styled.div`
  border: solid 1px var(--border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 60vh;
  max-height: 600px;
  height: 100%;
  border-radius: 0 8px 8px 0;
  overflow: hidden;
  position: sticky;
  top: 56px;
`;

export const FilterVisible = styled.span`
  border-radius: 8px;
  transition: 0.5s;
  color: ${props => props.active ? "var(--primary-color)" : "inherit" };
  font-weight: ${props => props.active ? "400" : "300" };

  svg {
    transition: 0.5s;
    transform: ${props => props.active ? "rotate(180deg)" : "rotate(0deg)" };
  }
`;

export const FilterOptions = styled.div`
  display: ${props => props.active ? "block" : "none"};
  background: white;
  border: solid 1px var(--border-color);
  position: absolute;
  z-index: 2;
  border-radius: 8px;
  margin-top: 8px;
  max-height: 300px;
  overflow-y: auto;
`;

export const FilterOption = styled.div`
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  padding: 16px;
  cursor: pointer;
  span {
    font-weight: ${props => props.description ? 500 : 300 } 
  }

  p {
    font-size: 12px;
    margin: 0;
  }
`;

export const NavBar  = styled.ul`
  margin-bottom:15px;
  li {
    color: black;
    font-weight: 500;    
  }

  li a.nav-link{
    color: grey;
    text-decoration: none !important;
    font-weight: 400;
  }

  li a.nav-link.active{
    color: #fa7820;
    text-decoration: none !important;
    font-weight: 500;
    border-bottom:1px solid #dee2e6;
    border-radius:5px;
  }

  li.active {
    color: black;
    font-weight: 500;
  }

`;

export const TableContainer = styled.div`
  border: 1px solid #dee2e6;
  border-radius: 5px;
`;