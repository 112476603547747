import { useEffect, useState } from "react";
import Utils from "../utils";

const controller = new AbortController();

const useGet = ({ route, attribute, format }) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadNumber, setReloadNumber] = useState(0);

  const reload = () => {
    setReloadNumber(reloadNumber + 1);
  }

  useEffect(() => {
    Utils.Petition.get(
      route,
      (response) => {
        let res = (attribute) ? response.data[attribute] : response.data;
        if (format) res = format(res);
        setData(res);
      },
      (err) => setError(err),
      () => setIsLoading(false),
    );

    return () => {
      setData(null);
      setError(null);
      setIsLoading(true);
      controller.abort();
    }

  }, [route, attribute, format, reloadNumber]);

  return { data, error, isLoading, setData, reload };

}

export default useGet;