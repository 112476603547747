import styled from "styled-components";

export const SectionConfig = styled.section`
    & tr {
        display: grid;
        grid-template-columns: 13fr 20fr 20fr 20fr 27fr;

        &.row-load {
            grid-template-columns: 1fr;
            border: none;
        } 
    }
`