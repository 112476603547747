import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getMinutesBetweenDates } from "../../../../utils/Times";
import Call from "./Call";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import ChatSeparator from "./ChatSeparator";
import Message from "./Message";

const ChatArea = styled.div`
  width: 100%;
  height: 100%;
`;

const MessagesArea = styled.div`
  position: relative;
  max-height: calc(100% - ${props => (props.showUserInfo ? '250px':'110px' )});
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 8px 16px 16px;
`;

const Chat = ({
  conversation,
  oldConversation,
  setDataRedirect,
  subGroupProperty,
  groupProperty,
  usersGroupProperty,
  toggle,
  updateScroll,
  setUpdateScroll,
  phoneLines
}) => {
  const areaRef = useRef("");
  const [showSystemMessages, setShowSystemMessages] = useState(true);
  const [showUserInfo, setShowUserInfo] = useState(false);

  const toggleShowSystemMessages = () => {
    setShowSystemMessages(!showSystemMessages);
  };

  const toggleShowUserInfo = () => {
    setShowUserInfo(!showUserInfo);
  };
  //const [conversation, setConversationData] = useState(null);

  useEffect(() => {
    //Sólo actualiza scroll si no se ha desplazado el usuario más de 100px o si no se ha desplazado.
    if(conversation){
      if(areaRef?.current){
        if (
          areaRef.current.scrollTop + 100 >
          areaRef.current.scrollHeight - areaRef.current.clientHeight
        ) {
          areaRef.current.scrollTop =
            areaRef.current.scrollHeight - areaRef.current.clientHeight;
        }
      }
    }
  }, [conversation]);

  useEffect(() => {
    //UpdateScroll, es una variable que sólo se se hace click en un item
    if (updateScroll) {
      setTimeout(() => {
        if(areaRef?.current){
          areaRef.current.scrollTop =
            areaRef.current.scrollHeight - areaRef.current.clientHeight;
          setUpdateScroll(false);
        }
      }, 100);
    }
  }, [updateScroll]);

  const isNewDate = (messages, i) => {
    if (i === 0) return true;
    if (!messages[i].time) return false;
    let a = new Date(parseInt(messages[i - 1].time));
    let b = new Date(parseInt(messages[i].time));
    return a.toLocaleDateString() !== b.toLocaleDateString();
  };
  const getResolutionTime = () => {
    if (conversation?.state === "open") {
      return "En curso";
    } else if (conversation?.dateUpdate && conversation?.date) {
      const [days, hours, minutes] = getMinutesBetweenDates(
        new Date(conversation.dateUpdate),
        new Date(conversation.date)
      );
      let daysStr = days > 0 ? `${days} días` : "";
      let hrsStr = hours > 0 ? `${hours} hrs` : "";
      let minsStr = minutes > 0 ? `${minutes} mins` : "";
      return `${daysStr} ${hrsStr} ${minsStr}`;
    } else {
      return "";
    }
  };

  return (
    conversation && (
      <ChatArea>
        <ChatHeader
          conversation={conversation}
          resolutionTime={getResolutionTime()}
          state={conversation?.state}
          conversationsId={conversation?.key}
          setShowSystemMessages={toggleShowSystemMessages}
          showSystemMessages={showSystemMessages}
          setShowUserInfo={toggleShowUserInfo}
          showUserInfo={showUserInfo}
          phoneLines={phoneLines}
        />
        
        <MessagesArea showUserInfo={showUserInfo} ref={areaRef}>

          {conversation.conversation.map((messages, i) => {
            return (
              <div key={i}>
              
                { messages.messages.map((message, i) => {
                  if (showSystemMessages) {
                    return (
                      <>
                        {isNewDate(messages?.messages, i) ? (
                          
                          <ChatSeparator
                            channel={messages.channel}
                            date={message.time}
                          />
                        ) : (
                          <></>
                        )}
                        <div key={i}>
                          {message?.from.toLowerCase().includes("call")  ? (
                            <Call {...message} key={i} />
                          ) : (
                            <Message {...message} status={message.status} caption={message.caption} key={i} />
                          )}
                        </div>
                      </>
                    );
                  } else if (!showSystemMessages && message.from !== "System" && message.from !=="Template" && message.from !=="Bot") {
                    return (
                      <>
                        {isNewDate(messages?.messages, i) ? (
                          <ChatSeparator
                            channel={messages.channel}
                            date={message.time}
                          />
                        ) : (
                          <></>
                        )}
                        <div key={i}>
                          {message?.from.toLowerCase() === "call" ? (
                            <Call {...message} key={i} />
                          ) : (
                            <Message {...message} status={message.status} caption={message.caption} key={i} />
                          )}
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            );
          })}
        </MessagesArea>
        {
          <ChatFooter
            fkey={conversation?.key}
            sid={conversation?.conversation[0].sid ?? null}
            assesor={conversation?.agent}
            emailContact={conversation?.email}
            identificatorCode={conversation?.identificatorCode}
            idLastConversation={
              conversation?.conversation[conversation.conversation.length - 1]
                .id
            }
            state={conversation?.state}
            conversationsId={conversation?.key}
            subgroup={subGroupProperty}
            group={groupProperty}
            newAsesor={usersGroupProperty}
            toggle={toggle}
            setDataRedirect={setDataRedirect}
            line={conversation.line}
          />
        }
      </ChatArea>
    )
  );
};

export default Chat;

